<template>
  <div
    class="flex flex-col justify-center min-h-screen py-20 px-40 text-center"
  >
    <p class="text-2xl sm:text-3xl md:text-5xl lg:text-6xl font-bold">
      {{ $t('404.title') }}
    </p>
    <MicroscopeImage
      class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 self-center my-40"
    />
    <p class="text-lg md:text-2xl">{{ $t('404.description') }}</p>
    <HMButton
      size="large"
      textSize="large"
      fontWeight="normal"
      class="w-200 md:w-300 self-center mt-20 sm:mt-40"
      :to="{ name: 'home' }"
      >{{ $t('404.back_to_home') }}</HMButton
    >
  </div>
</template>

<script>
import MicroscopeImage from '../../assets/svgs/MicroscopeImage.svg'
export default {
  components: { MicroscopeImage },
}
</script>

<style></style>
