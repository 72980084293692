var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"py-20"},[_c('nav',{staticClass:"container",attrs:{"role":"navigation"}},[_c('div',{staticClass:"flex items-center justify-center"},[_c('div',{staticClass:"mr-10 text-sm font-semibold",class:{ 'text-white': _vm.isLight, 'text-grey-500': !_vm.isLight }},[_vm._v(" "+_vm._s(_vm.$t('common.powered_by') + ` ${String.fromCharCode(0x24b8)} ${new Date().getFullYear()}`)+" ")]),_c('div',{staticClass:"ml-10",class:{ 'text-white': _vm.isLight }},[_c('ul',{staticClass:"flex items-center"},[_c('li',{staticClass:"mx-10"},[_c('router-link',{staticClass:"block text-sm font-semibold",attrs:{"to":{
                name: 'public-privacy-document',
                params: { title: _vm.$t('common.terms_link') },
              },"title":_vm.$t('common.terms'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.terms')))])],1),_c('li',{staticClass:"mx-10"},[_c('router-link',{staticClass:"block text-sm font-semibold",attrs:{"to":{
                name: 'public-privacy-document',
                params: { title: _vm.$t('common.privacy_link') },
              },"title":_vm.$t('common.privacy'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.privacy')))])],1),(_vm.hasNonDiscrimination)?_c('li',{staticClass:"mx-10"},[_c('a',{staticClass:"block text-sm font-semibold",attrs:{"href":`${_vm.$settings.S3_ROOT}/non-discrimination.pdf`}},[_vm._v(" "+_vm._s(_vm.$t('common.non_discrimination'))+" ")])]):_vm._e(),_c('li',[_c('button',{staticClass:"block text-sm font-semibold",on:{"click":_vm.openSupportModal}},[_vm._v(" "+_vm._s(_vm.$t('common.contact_support'))+" ")])]),_vm._l((_vm.disclaimers),function(disclaimer){return _c('li',{key:disclaimer.id,staticClass:"mx-10"},[_c('router-link',{staticClass:"block text-sm font-semibold",attrs:{"to":{
                name: 'disclaimer',
                params: { id: disclaimer.id },
              },"title":disclaimer.name}},[_vm._v(" "+_vm._s(disclaimer.name)+" ")])],1)})],2)])])]),(_vm.hasCommonDisclaimer || _vm.footerDisclaimer)?_c('div',{staticClass:"max-w-3xl mx-auto mt-20 text-xs text-center text-grey-500"},[(_vm.hasCommonDisclaimer)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('common.disclaimer', [_vm.$settings.NAME]))+" ")]):_vm._e(),(_vm.footerDisclaimer)?_c('p',{staticClass:"mt-5",domProps:{"innerHTML":_vm._s(_vm.footerDisclaimer)}}):_vm._e()]):_vm._e(),_c('Modal',{attrs:{"active":_vm.supportModalOpen,"closeOnBackDrop":""},on:{"close":_vm.closeSupportModal}},[_c('ContactSupportModal',{on:{"close":_vm.closeSupportModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }