import * as learnService from '@platform-shared/services/learn'
import * as memberService from '@platform-shared/services/member'
import types from './learn.types'
import _isEmpty from 'lodash/isEmpty'
import { ACTIVITY_TYPES } from '../../constants.js'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  invalidateLearn({ commit }) {
    commit(types.INVALIDATE)
  },
  initLearn({ commit, getters }) {
    if (!getters.shouldLoad()) return

    commit(types.INIT_REQUEST)

    return new Promise((resolve, reject) => {
      learnService
        .initLearn()
        .then((res) => {
          commit(types.INIT_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },
  searchArticles({ commit, getters }) {
    if (_isEmpty(getters.searchKeywords.trim())) return

    commit(types.LEARN_SEARCH_REQUEST)
    return new Promise((resolve, reject) => {
      const keyword = encodeURIComponent(getters.searchKeywords)
      learnService
        .searchArticles(keyword)
        .then((res) => {
          commit(types.LEARN_SEARCH_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.LEARN_SEARCH_FAILURE)
          reject(error)
        })
    })
  },

  articleLoading({ commit }) {
    commit(types.LEARN_ARTICLE_REQUEST)
  },

  articleLoaded({ commit }) {
    commit(types.LEARN_ARTICLE_SUCCESS)
  },

  articleLoadedMarkAsRead({ commit }, contentId) {
    commit(types.LEARN_ARTICLE_SUCCESS)

    //mark the article as read.
    return new Promise((resolve, reject) => {
      learnService
        .markArticleAsRead(contentId)
        .then((res) => {
          commit(types.LEARN_ARTICLE_MARK_AS_READ, contentId)
          resolve(res)
        })
        .catch((error) => {
          commit(types.LEARN_SEARCH_FAILURE)
          reject(error)
        })
    })
  },

  brainExerciseLoading({ commit }) {
    commit(types.LEARN_BRAIN_EXERCISE_REQUEST)
  },

  brainExerciseLoaded({ commit }) {
    commit(types.LEARN_BRAIN_EXERCISE_SUCCESS)
  },

  brainExerciseLoadedMarkAsPlayed({ commit }, { exerciseCd }) {
    commit(types.LEARN_BRAIN_EXERCISE_SUCCESS)

    return new Promise((resolve, reject) => {
      memberService
        .postMemberActivity({
          activityType: ACTIVITY_TYPES.PLAYED_BRAIN_EXERCISE,
          activityPayload: {
            source: 'brain_exercise',
            contentId: exerciseCd,
          },
        })
        .then((res) => {
          commit(types.LEARN_BRAIN_EXERCISE_MARK_AS_PLAYED, exerciseCd)
          resolve(res)
        })
        .catch((error) => {
          commit(types.LEARN_BRAIN_EXERCISE_FAILURE)
          reject(error)
        })
    })
  },

  updateKeywords({ commit }, value) {
    commit(types.LEARN_SEARCH_UPDATE, value)
  },

  startSearch({ commit }) {
    commit(types.LEARN_SEARCH_START)
  },

  closeSearch({ commit }) {
    commit(types.LEARN_SEARCH_END)
  },
}
