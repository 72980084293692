import { isBefore, isAfter, parseISO, endOfDay } from 'date-fns'

const isValidDateObj = (date) => date instanceof Date && !isNaN(date)

export const isWithinDateInterval = (givenDate, intervalDates) => {
  const start = parseISO(intervalDates.start),
    end = endOfDay(parseISO(intervalDates.end))

  const dateToCheck = isValidDateObj(parseISO(givenDate))
    ? parseISO(givenDate)
    : new Date(givenDate)

  if (
    isValidDateObj(dateToCheck) &&
    isValidDateObj(start) &&
    isValidDateObj(end)
  ) {
    return !isAfter(dateToCheck, end) && !isBefore(dateToCheck, start)
  }
  return false
}
