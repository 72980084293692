import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import mapValues from 'lodash/fp/mapValues'
import filter from 'lodash/fp/filter'
import pickBy from 'lodash/fp/pickBy'
import omitBy from 'lodash/fp/omitBy'
import getOr from 'lodash/fp/getOr'
import sortBy from 'lodash/fp/sortBy'
import head from 'lodash/fp/head'
import _intersection from 'lodash/intersection'
import _get from 'lodash/get'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import {
  SHOULD_LOAD_THRESHOLD,
  QUESTIONNAIRE_TIMING,
  QUESTIONNAIRE_STATUS,
} from '@platform-shared/constants'

export default {
  shouldLoad: (state, getters) => (now = new Date()) => {
    return (
      (!state.meta.questionnaire.loadedOn ||
        differenceInMinutes(now, state.meta.questionnaire.loadedOn) >
          SHOULD_LOAD_THRESHOLD) &&
      !getters.isLoading
    )
  },
  hasLoaded: (state) => !!state.meta.questionnaire.loadedOn,
  isLoading: (state) => state.meta.questionnaire.isLoading,
  error: (state) => state.meta.questionnaire.error,

  //ANSWERS
  isLoadingAnswers: (state) => state.meta.answer.isLoading,
  answersHasError: (state) => state.meta.answer.error,

  startUpQuestionnaire: (state) =>
    flow(
      map((item) => state.itemsById[item]),
      filter(
        (item) =>
          item.settings.meta.type === QUESTIONNAIRE_TIMING.START_UP &&
          item.status !== QUESTIONNAIRE_STATUS.COMPLETED &&
          item.status !== QUESTIONNAIRE_STATUS.REFUSED
      ),
      sortBy('settings.meta.priority'),
      head
    )(state.items),

  questionnaireById: (state) => (id) => state.itemsById[id],

  questionnaireByType: (state) => (type) => find({ type }, state.itemsById),

  resultsQuestionnaireByType: (state, getters, rootState, rootGetters) => (
    type
  ) => {
    const questionnaire = getters.questionnaireByType(type)
    if (!questionnaire) return undefined

    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']

    const filteredQuestions = flow(
      getOr([], 'questions'),
      mapValues((question) => {
        const requirements = flow(
          getOr('{}', 'otherSelectors'),
          JSON.parse,
          getOr([], 'requiredAnswers')
        )(questionnaire.questions[question])
        return { ...question, requirements }
      }),
      pickBy(
        ({ minAge, maxAge, gender }) =>
          (minAge == null || minAge <= age) &&
          (maxAge == null || maxAge >= age) &&
          (gender == null ||
            memberGender ===
              gender
                .trim()
                .charAt(0)
                .toUpperCase())
      )
    )(questionnaire)

    return {
      allCompletions: _get(questionnaire, 'allCompletions', []),
      questions: filteredQuestions,
      orderedQuestions: _intersection(
        _get(questionnaire, 'orderedQuestions', []),
        Object.keys(filteredQuestions)
      ),
    }
  },

  groupsForQuestionnaire: (state, getters) => (id) =>
    getters.questionnaireById(id).groups,

  groupIdsForQuestionnaire: (state, getters) => (id) =>
    getters.questionnaireById(id).orderedGroups,

  answersForQuestionnaire: (state, getters) => (id) =>
    getters.questionnaireById(id).answers,

  questionsForQuestionnaire: (state, getters) => (id) =>
    getters.questionnaireById(id).questions,

  optionalOrConditionalQuestions: (state, getters, rootState, rootGetters) => (
    id
  ) => {
    const questionnaire = getters.questionnaireById(id)
    if (!questionnaire) return undefined

    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']

    return flow(
      getOr([], 'questions'),
      mapValues((question) => {
        const requirements = flow(
          getOr('{}', 'otherSelectors'),
          JSON.parse,
          getOr([], 'requiredAnswers')
        )(questionnaire.questions[question])
        return { ...question, requirements }
      }),
      omitBy(
        ({ minAge, maxAge, gender, optional }) =>
          optional === false &&
          (minAge == null || minAge <= age) &&
          (maxAge == null || maxAge >= age) &&
          (gender == null ||
            memberGender ===
              gender
                .trim()
                .charAt(0)
                .toUpperCase())
      )
    )(questionnaire)
  },
}
