import types from './tracking.types'
import initialState from './tracking.state'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.INIT_REQUEST]: (state) => {
    state.meta.isLoading.init = true
  },
  [types.INIT_SUCCESS]: (state, { settings, summary, humanApiToken }) => {
    state.meta.isLoading.init = false
    state.meta.error.init = false
    state.meta.loadedOn = new Date()
    state.settings = settings
    state.summary = summary
    state.humanApiToken = humanApiToken
  },
  [types.INIT_FAILURE]: (state) => {
    state.meta.isLoading.init = false
    state.meta.error.init = true
  },
  [types.UPDATE_TRACKER_REQUEST]: (state) => {
    state.meta.isLoading.update = true
  },
  [types.UPDATE_TRACKER_SUCCESS]: (state, summary) => {
    state.meta.isLoading.update = false
    state.meta.error.update = false
    state.summary = summary
  },
  [types.UPDATE_TRACKER_FAILURE]: (state) => {
    state.meta.isLoading.update = false
    state.meta.error.update = true
  },

  [types.TRACKER_HISTORY_REQUEST]: (state) => {
    state.meta.isLoading.history = true
  },
  [types.TRACKER_HISTORY_SUCCESS]: (state, { tracker, history }) => {
    state.history[tracker] = history
    state.meta.isLoading.history = false
    state.meta.error.history = false
  },
  [types.TRACKER_HISTORY_FAILURE]: (state) => {
    state.meta.isLoading.history = false
    state.meta.error.history = true
  },
  [types.SUMMARY_REQUEST]: (state) => {
    state.meta.isLoading.summary = true
  },
  [types.SUMMARY_SUCCESS]: (state, summary) => {
    state.meta.isLoading.summary = false
    state.meta.error.summary = false
    state.summary = summary
  },
  [types.SUMMARY_FAILURE]: (state) => {
    state.meta.isLoading.summary = false
    state.meta.error.summary = true
  },
  [types.SET_HUMAN_API_PUBLIC_TOKEN_REQUEST]: (state) => {
    state.meta.isLoading.humanApiToken = true
  },
  [types.SET_HUMAN_API_PUBLIC_TOKEN_SUCCESS]: (state, publicToken) => {
    state.meta.isLoading.humanApiToken = false
    state.meta.error.humanApiToken = false
    state.humanApiToken = {
      ...state.humanApiToken,
      publicToken,
    }
  },
  [types.SET_HUMAN_API_PUBLIC_TOKEN_FAILURE]: (state) => {
    state.meta.isLoading.humanApiToken = false
    state.meta.error.humanApiToken = true
  },
  [types.UPDATE_TRACKER_HISTORY_BULK_REQUEST]: (state) => {
    state.meta.isLoading.bulkHistory = true
    state.meta.error.bulkHistory = false
  },
  [types.UPDATE_TRACKER_HISTORY_BULK_SUCCESS]: (state) => {
    state.meta.isLoading.bulkHistory = false
  },
  [types.UPDATE_TRACKER_HISTORY_BULK_FAILURE]: (state) => {
    state.meta.isLoading.bulkHistory = false
    state.meta.error.bulkHistory = true
  },
}
