import { TRACKER_TYPES } from '@platform-shared/constants'
import _mapValues from 'lodash/mapValues'

export default () => ({
  meta: {
    loadedOn: null,
    error: {
      init: false,
      summary: false,
      humanApiToken: false,
      update: false,
      history: false,
      bulkHistory: false,
    },
    isLoading: {
      init: false,
      summary: false,
      humanApiToken: false,
      update: false,
      history: false,
      bulkHistory: false,
    },
  },
  summary: {},
  settings: [],
  humanApiToken: {},
  history: _mapValues(TRACKER_TYPES, () => []),
})
