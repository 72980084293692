<template>
  <div v-if="message" class="absolute top-0 right-0 mt-20 mr-20">
    <div :class="messageClasses">
      <span>{{ message.text }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MESSAGE_TYPES } from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('app', ['message']),
    messageClasses() {
      return {
        'bg-white p-10 shadow text-white': true,
        'bg-red-500': this.message.type === MESSAGE_TYPES.ERROR,
        'bg-green-500': this.message.type === MESSAGE_TYPES.SUCCESS,
        'bg-tan-500': this.message.type === MESSAGE_TYPES.WARNING,
      }
    },
  },
}
</script>
