<template>
  <div class="flex justify-between items-center bg-black text-white p-8">
    <div>
      {{ $t('common.impersonation', [fullName]) }}
    </div>
    <div>{{ decodeURIComponent(impersonationEmail) }}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: mapGetters('member', ['fullName', 'impersonationEmail']),
}
</script>
<style></style>
