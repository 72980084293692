import get from 'lodash/get'
import { FEATURES } from '@platform-shared/constants'

/* eslint-disable prettier/prettier */
const ForgotUsername = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/ForgotUsername');
const AccountFound = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/AccountFound');
const ForgotPassword = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/ForgotPassword');
const EmailSent = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/EmailSent');
const ResetPassword = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/ResetPassword');
const ResetPasswordSuccess = () => import(/* webpackChunkName: "account-recovery" */ './components/views/account-recovery/ResetPasswordSuccess');

const Questionnaire = () => import(/* webpackChunkName: "questionnaire" */ './components/views/questionnaire');

const Learn = () => import(/* webpackChunkName: "learn" */ './components/views/learn');
const LearnHome = () => import(/* webpackChunkName: "learn" */ './components/views/learn/LearnHome');
const Articles = () => import(/* webpackChunkName: "learn" */ './components/views/learn/Articles');
const ArticlesAll = () => import(/* webpackChunkName: "learn" */ './components/views/learn/ArticlesAll');
const ArticleSearch = () => import(/* webpackChunkName: "learn" */ './components/views/learn/ArticleSearch');
const ArticleCategory = () => import(/* webpackChunkName: "learn" */ './components/views/learn/ArticleCategory');
const ArticleDetail = () => import(/* webpackChunkName: "learn" */ './components/views/learn/ArticleDetail');
const BrainExercises = () => import(/* webpackChunkName: "learn" */ './components/views/learn/BrainExercises')
const BrainExerciseDetail = () => import(/* webpackChunkName: "learn" */ './components/views/learn/BrainExerciseDetail');

const Rewards = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards');
const RewardsHome = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/RewardsHome');
const Redeem = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/Redeem');
const RewardConfirmation = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/RewardConfirmation');
const RewardBox = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/reward-boxes');
const RewardBoxSelection = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/reward-boxes/RewardBoxSelection');
const RewardBoxConfirmation = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/reward-boxes/RewardBoxConfirmation');
const AutoRewardsSelection = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/AutoRewardsSelection');
const AutoRewardsSplash = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/AutoRewardsSplash');
const AutoRewardsConfirm = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/AutoRewardsConfirm');
const OrderSuccess = () => import(/* webpackChunkName: "rewards" */ './components/views/rewards/OrderSuccess');

const HelpCenter = () => import(/* webpackChunkName: "help-center" */ './components/views/help-center');
const HelpCenterLanding = () => import(/* webpackChunkName: "help-center" */ './components/views/help-center/HelpCenterLanding');
const FaqCategory = () => import(/* webpackChunkName: "help-center" */ './components/views/help-center/FaqCategory');
const FaqItem = () => import(/* webpackChunkName: "help-center" */ './components/views/help-center/FaqItem');
const ViewDocument = () => import(/* webpackChunkName: "help-center" */ './components/views/help-center/ViewDocument');

const Tracking = () => import(/* webpackChunkName: "tracking" */ './components/views/tracking');
const TrackingHome = () => import(/* webpackChunkName: "tracking" */ './components/views/tracking/TrackingHome');
const TrackingDetail = () => import(/* webpackChunkName: "tracking" */ './components/views/tracking/TrackingDetail');

const PrescriptionRecord = () => import(/* webpackChunkName: "prescription-record" */ './components/views/PrescriptionRecord')

const Account = () => import(/* webpackChunkName: "account" */ './components/views/account/Account');
const ProfileUpdate = () => import(/* webpackChunkName: "account" */ './components/views/home/health-action-detail/member-activity-submission/ProfileUpdate.vue')
const EditPassword = () => import(/* webpackChunkName: "account" */ './components/views/account/EditPassword');
const EditContact = () => import(/* webpackChunkName: "account" */ './components/views/account/EditContact');
const EditEthnography = () => import(/* webpackChunkName: "account" */ './components/views/account/EditEthnography');
const EditAddress = () => import(/* webpackChunkName: "account" */ './components/views/account/EditAddress')

const NotificationSettings = () => import(/* webpackChunkName: "account" */ './components/views/notification-settings/NotificationSettings');

const Inbox = () => import(/* webpackChunkName: "inbox" */ './components/views/inbox');
const InboxHome = () => import(/* webpackChunkName: "inbox" */ './components/views/inbox/InboxHome');
const MessageDetails = () => import(/* webpackChunkName: "inbox" */ './components/views/inbox/MessageDetails');
const Archive = () => import(/* webpackChunkName: "inbox" */ './components/views/inbox/Archive');

const Login = () => import(/* webpackChunkName: "auth" */ './components/views/login');
const Register = () => import(/* webpackChunkName: "auth" */ './components/views/register/Register');
const RegisterSSO = () => import(/* webpackChunkName: "auth" */ './components/views/register/RegisterSSO');

const Home = () => import(/* webpackChunkName: "home" */ './components/views/home');
const HealthActionDetailContainer = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail');
const HealthActionDetails = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/HealthActionDetail');
const HealthActionHistory = () => import(/* webpackChunkName: "home" */ './components/views/home/HealthActionHistory');
const HealthAction = () => import(/* webpackChunkName: "home" */ './components/views/home/HealthAction');
const HraResults = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/hra/HraResults');
const HraSummary = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/hra/HraSummary');
const HraSummaryRisk = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/hra/HraSummaryRisk');
const HealthPlanFeedbackResults = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/health-plan-feedback/HealthPlanFeedbackResults');
const LearnAndEarn = () => import(/* webpackChunkName: "home" */ './components/views/home/health-action-detail/member-activity-submission/LearnAndEarn.vue');

const DocumentPage = () => import(/* webpackChunkName: "document" */ './components/views/DocumentPage');
const Disclaimer = () => import(/* webpackChunkName: "disclaimer" */ './components/views/home/Disclaimer');
const DisclaimerPage = () => import(/* webpackChunkName: "disclaimer" */ './components/views/DisclaimerPage');

const Unsubscribe = () => import(/* webpackChunkName: "unsubscribe" */ './components/views/Unsubscribe');
const Survey = () => import(/* webpackChunkName: "survey" */ './components/views/Survey');
/* eslint-enable prettier/prettier */

import Frame from './components/views/Frame'
import DefaultRoute from './components/views/404.vue'

const siteName = get(window, 'HM_CONFIG.TITLE', 'Healthmine')

const createTitle = (front, seperator = '|') =>
  `${front} ${seperator} ${siteName}`

/******************
 * Authentication Guards
 * isAuth: true,  -- a route can only be accessed by logged in users
 * isGuest: true,  -- a route can only be accessed by not logged in users
 *******************/

const routes = [
  {
    path: '/',
    component: Frame,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          isAuth: true,
          title: siteName,
          featureGuard: {
            feature: FEATURES.HOME,
            redirect: '/rewards',
          },
        },
      },
      {
        path: '/disclaimers',
        name: 'disclaimers',
        component: Disclaimer,
        meta: {
          isAuth: true,
          title: createTitle('Disclaimer'),
        },
        children: [
          {
            path: ':id',
            name: 'disclaimer',
            component: DisclaimerPage,
            props: (route) => ({
              disclaimerId: route.params.id,
            }),
            meta: {
              isAuth: false,
            },
          },
        ],
      },
      {
        path: '/health-actions',
        name: 'health-actions',
        component: HealthAction,
        meta: {
          isAuth: true,
          title: createTitle('Health Actions'),
        },
        children: [
          {
            path: 'hra/results',
            name: 'hra-results',
            component: HraResults,
            meta: {
              isAuth: true,
              title: createTitle('HRA Results'),
            },
          },
          {
            path: ':id/results',
            name: 'health-plan-feedback-results',
            component: HealthPlanFeedbackResults,
            meta: {
              isAuth: true,
              title: createTitle('Feedback Results'),
            },
          },
          {
            path: 'hra/summary',
            name: 'hra-summary',
            component: HraSummary,
            meta: {
              isAuth: true,
              title: createTitle('HRA Summary'),
              featureGuard: {
                feature: FEATURES.HRA_RISK,
                redirect: '/health-actions/hra',
              },
            },
            children: [],
          },
          {
            path: ':id',
            component: HealthActionDetailContainer,
            meta: {
              isAuth: true,
            },
            children: [
              {
                path: '/',
                name: 'health-actions-details',
                component: HealthActionDetails,
              },
              {
                path: 'history',
                name: 'health-actions-history',
                component: HealthActionHistory,
              },
            ],
          },
        ],
      },
      {
        path: '/hra/summary/:risk',
        name: 'hra-summary-risk',
        component: HraSummaryRisk,
        meta: {
          isAuth: true,
          title: createTitle('HRA Summary Risk'),
          featureGuard: {
            feature: FEATURES.HRA_RISK,
            redirect: '/health-actions/hra',
          },
        },
      },
      {
        path: '/rewards',
        component: Rewards,
        meta: {
          isAuth: true,
          title: createTitle('Rewards'),
          featureGuard: {
            feature: FEATURES.REWARDS,
            redirect: '/',
          },
        },
        children: [
          {
            path: '/',
            name: 'rewards',
            component: RewardsHome,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'redeem',
            name: 'rewards-redeem',
            component: Redeem,
            props: (route) => {
              const dependentId = !isNaN(route.query.dependentId)
                ? Number(route.query.dependentId)
                : undefined
              return {
                dependentId,
              }
            },
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'confirmation',
            name: 'rewards-confirmation',
            component: RewardConfirmation,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'auto/setup',
            name: 'auto-rewards-setup',
            component: AutoRewardsSelection,
            meta: {
              isAuth: true,
              featureGuard: {
                feature: FEATURES.AUTO_REWARDS,
                redirect: '/rewards',
              },
            },
          },
          {
            path: 'auto/confirm',
            name: 'auto-rewards-confirm',
            component: AutoRewardsConfirm,
            meta: {
              isAuth: true,
              featureGuard: {
                feature: FEATURES.AUTO_REWARDS,
                redirect: '/rewards',
              },
            },
          },
          {
            path: 'success',
            name: 'rewards-success',
            component: OrderSuccess,
            props: true,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'reward-boxes',
            component: RewardBox,
            meta: {
              isAuth: true,
            },
            children: [
              {
                path: '/',
                name: 'reward-boxes-selection',
                component: RewardBoxSelection,
                meta: {
                  isAuth: true,
                },
              },
              {
                path: 'confirmation',
                name: 'reward-boxes-confirmation',
                component: RewardBoxConfirmation,
                meta: {
                  isAuth: true,
                },
              },
              {
                path: 'success',
                name: 'reward-boxes-success',
                component: OrderSuccess,
                meta: {
                  isAuth: true,
                },
                props: {
                  isRewardBox: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/learn',
        component: Learn,
        meta: {
          isAuth: true,
          title: createTitle('Learn'),
          featureGuard: {
            feature: FEATURES.EDUCATION,
            redirect: '/',
          },
        },
        children: [
          {
            path: '/',
            name: 'learn',
            component: LearnHome,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'articles',
            name: 'learn-articles',
            component: Articles,
            meta: {
              isAuth: true,
            },
            props: {
              type: 'articles',
            },
          },
          {
            path: 'articles/history',
            name: 'learn-articles-history',
            component: Articles,
            meta: {
              isAuth: true,
            },
            props: {
              type: 'history',
            },
          },
          {
            path: 'articles/search',
            name: 'search',
            component: ArticleSearch,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'articles/all',
            name: 'all-articles',
            component: ArticlesAll,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'articles/:category',
            name: 'category',
            component: ArticleCategory,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'article/detail/:contentId/',
            name: 'article-detail',
            component: ArticleDetail,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'brain-exercises',
            name: 'learn-brain-exercises',
            component: BrainExercises,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'brain-exercise/detail/:exerciseCd/',
            name: 'brain-exercise-detail',
            component: BrainExerciseDetail,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
      {
        path: '/tracking',
        component: Tracking,
        meta: {
          isAuth: true,
          title: createTitle('Tracking'),
          featureGuard: {
            feature: FEATURES.TRACKING,
            redirect: '/',
          },
        },
        children: [
          {
            path: '/',
            name: 'tracking',
            component: TrackingHome,
            meta: {
              isAuth: true,
            },
          },
          {
            path: ':name',
            name: 'tracking-detail',
            component: TrackingDetail,
            props: (route) => ({
              type: route.params.name,
              backTo: route.params.backTo,
            }),
            meta: {
              isAuth: true,
            },
          },
        ],
      },
      {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
          isAuth: true,
          title: createTitle('Account'),
        },
      },
      {
        path: '/prescription-record',
        name: 'prescription-record',
        component: PrescriptionRecord,
        meta: {
          isAuth: true,
          title: createTitle('Prescription Record'),
        },
      },
      {
        path: '/help-center',
        alias: '/faq',
        component: HelpCenter,
        meta: {
          isAuth: true,
          title: createTitle('FAQ'),
        },
        children: [
          {
            path: '/',
            name: 'help-center',
            component: HelpCenterLanding,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'view-document',
            name: 'view-document',
            component: ViewDocument,
            props: (route) => ({
              document: route.query.document,
            }),
            meta: {
              isAuth: true,
              title: createTitle('View Document'),
            },
          },
          {
            path: 'faq/category/:id',
            name: 'faq-category',
            component: FaqCategory,
            meta: {
              isAuth: true,
            },
            props: (route) => ({
              id: parseInt(route.params.id),
            }),
          },
          {
            path: 'faq/:id', // in the future we should use a slug
            name: 'faq-item',
            component: FaqItem,
            meta: {
              isAuth: true,
            },
            props: (route) => ({
              id: parseInt(route.params.id),
              popular: !!route.query.popular,
            }),
          },
        ],
      },
      {
        path: '/inbox',
        component: Inbox,
        meta: {
          isAuth: true,
          title: createTitle('Inbox'),
        },
        children: [
          {
            path: '/',
            name: 'inbox',
            component: InboxHome,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'archive',
            name: 'archive',
            component: Archive,
            meta: {
              isAuth: true,
            },
          },
          {
            path: ':messageId',
            name: 'message-details',
            component: MessageDetails,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/questionnaire/:type',
    name: 'questionnaire',
    component: Questionnaire,
    meta: {
      isAuth: true,
      title: createTitle('Questionnaire'),
    },
    props: true,
  },
  {
    path: '/rewards/auto/splash',
    name: 'auto-rewards-splash',
    component: AutoRewardsSplash,
    meta: {
      isAuth: true,
      featureGuard: {
        feature: FEATURES.AUTO_REWARDS,
        redirect: '/rewards',
      },
    },
  },
  {
    path: '/login',
    name: 'login',
    alias: '/signin',
    component: Login,
    meta: {
      isGuest: true,
      title: createTitle('Login'),
    },
    props: true,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: createTitle('Register'),
    },
  },
  {
    path: '/register-sso',
    name: 'register-sso',
    component: RegisterSSO,
    meta: {
      isAuth: true,
      title: createTitle('Register'),
    },
  },
  {
    path: '/forgot-username',
    name: 'forgot-username',
    alias: '/forgot-email',
    component: ForgotUsername,
    meta: {
      title: createTitle('Forgot Username'),
    },
  },
  {
    path: '/account-found',
    name: 'account-found',
    component: AccountFound,
    props: true,
    meta: {
      title: createTitle('Account Found'),
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: createTitle('Forgot Password'),
    },
  },
  {
    path: '/email-sent',
    name: 'email-sent',
    component: EmailSent,
    props: true,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: createTitle('Reset Password'),
    },
  },
  {
    path: '/reset-password-success',
    name: 'reset-password-success',
    component: ResetPasswordSuccess,
    meta: {
      title: createTitle('Reset Password'),
    },
  },
  {
    path: '/subscriptions',
    name: 'unsubscribe',
    component: Unsubscribe,
    props: (route) => ({
      memberId: route.query.m,
      emailAddress: route.query.e,
      token: route.query.t,
      outreachType: route.query.utm_source,
      outreachId: route.query.utm_campaign,
    }),
    meta: {
      title: createTitle('Unsubscribe'),
    },
  },
  {
    path: '/public/privacy-document/:title',
    name: 'public-privacy-document',
    component: DocumentPage,
    props: true,
    meta: {
      isAuth: false,
      title: createTitle('Privacy Document'),
    },
  },
  {
    path: '/health-actions/:id/profile-update',
    name: 'profile-update',
    component: ProfileUpdate,
    meta: {
      isAuth: true,
      title: createTitle('Profile Update'),
      featureGuard: {
        blockingFeature: FEATURES.LEGACY_ACCOUNT_PAGE,
        redirect: '/account',
      },
    },
  },
  {
    path: '/account/edit-contact',
    name: 'edit-contact',
    component: EditContact,
    meta: {
      isAuth: true,
      title: createTitle('Edit Contact Information'),
    },
  },
  {
    path: '/account/edit-password',
    name: 'edit-password',
    component: EditPassword,
    meta: {
      isAuth: true,
      title: createTitle('Edit Password'),
    },
  },
  {
    path: '/account/edit-ethnography',
    name: 'edit-ethnography',
    component: EditEthnography,
    meta: {
      isAuth: true,
      title: createTitle('Edit Personal'),
      featureGuard: {
        blockingFeature: FEATURES.LEGACY_ACCOUNT_PAGE,
        redirect: '/account',
      },
    },
  },
  {
    path: '/account/edit-address',
    name: 'edit-address',
    component: EditAddress,
    meta: {
      isAuth: true,
      title: createTitle('Edit Address'),
      featureGuard: {
        blockingFeature: FEATURES.LEGACY_ACCOUNT_PAGE,
        redirect: '/account',
      },
    },
  },
  {
    path: '/notification-settings',
    name: 'notification-settings',
    component: NotificationSettings,
    meta: {
      isAuth: true,
      title: createTitle('Notification Settings'),
    },
  },
  {
    path: '/health-actions/:id/learn-and-earn/article/:contentId',
    name: 'learn-and-earn-core',
    component: LearnAndEarn,
    meta: {
      isAuth: true,
      title: createTitle('Learn and Earn'),
    },
    children: [],
  },
  {
    path: '/survey',
    name: 'survey',
    component: Survey,
    props: (route) => ({
      memberId: route.query.m,
      surveyId: route.query.sid,
      responseId: route.query.rid,
      signature: route.query.sig,
    }),
    meta: {
      title: createTitle('Survey'),
    },
  },
  {
    path: '/*',
    name: '404',
    component: DefaultRoute,
  },
]

export default routes
