import { mapGetters, mapActions } from 'vuex'
import _find from 'lodash/find'
import { AGREEMENT_TAG_TYPES } from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('member', ['pendingPrivacyAgreements']),
    showPrivacy() {
      return !!_find(this.pendingPrivacyAgreements, {
        tag: AGREEMENT_TAG_TYPES.PRIVACY_POLICY,
      })
    },
    showTerms() {
      return !!_find(this.pendingPrivacyAgreements, {
        tag: AGREEMENT_TAG_TYPES.TERMS_OF_USE,
      })
    },
  },
  methods: {
    ...mapActions('member', ['createAgreement']),
    agree() {
      this.pendingPrivacyAgreements.forEach((agreement) =>
        this.createAgreement(agreement.tag)
      )
    },
  },
}
