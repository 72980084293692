import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import vClickOutside from 'v-click-outside'
import IdleVue from 'idle-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import EN from '@/lang/en.json'
import ES from '@/lang/es.json'
import AR from '@/lang/ar.json'
import FA from '@/lang/fa.json'
import KM from '@/lang/km.json'
import KO from '@/lang/ko.json'
import RU from '@/lang/ru.json'
import TL from '@/lang/tl.json'
import VI from '@/lang/vi.json'
import HY from '@/lang/hy.json'
import ZH from '@/lang/zh.json'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'
import CustomFormGroup from './components/common/forms/FormGroup'
import globalComponents from './globalComponents'
import VCalendar from 'v-calendar-ie11'
import { truncate } from '@platform-shared/utils/truncate'
import VueMask from 'v-mask'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { getEnvironment } from './utils/appConfig'
import format from 'date-fns/format'
import { FocusTrap } from 'focus-trap-vue'
import 'core-js'

const appEnv = getEnvironment()
const date = new Date()

if (appEnv && appEnv !== 'stage') {
  Bugsnag.start({
    apiKey: '99ba5095b6eeb288c644148b43d07e97',
    appVersion: process.env.PACKAGE_VERSION,
    metadata: {
      app: {
        // eslint-disable-next-line camelcase
        hm_client_id: window.HM_CONFIG.ID,
        // eslint-disable-next-line camelcase
        hm_client_schema: window.HM_CONFIG.SCHEMA,
      },
    },
    releaseStage: appEnv,
    plugins: [new BugsnagPluginVue(Vue)],
  })
}

/*
This hash replace is a stopgap measure to handle the url #/reset-password
which is generated in risk-manger as the url used in the password reset email.
https://github.com/healthmine/OpportunityManager/blob/release-rm/client/risk-manager/src/components/member/member-details/web-security/index.js#L44
Once RM is fixed up to generate these urls dynamically we can remove this block
*/
if (location.hash !== '') {
  location.replace(location.href.replace('#/', ''))
}

Vue.use(VTooltip, { popover: { defaultPlacement: 'top' } })
Vue.use(VueMask)
Vue.config.productionTip = false
Vue.use(VCalendar)
Vue.use(Vuelidate)
Vue.use(VueI18n)
globalComponents.forEach((component) =>
  Vue.component(component.name, component)
)
Vue.component('FocusTrap', FocusTrap)

export const i18n = new VueI18n({
  locale: 'EN', // initial locale
  fallbackLocale: 'EN',
  messages: { EN, ES, AR, FA, KM, KO, RU, TL, VI, HY, ZH },
})

Vue.use(vuelidateErrorExtractor, {
  template: CustomFormGroup,
  i18n: 'error_messages',
  i18nAttributes: {
    activityCompletionDate: `01/01/${date.getFullYear()}`,
  },
})

Vue.filter('T', truncate)
Vue.filter('FD', (date, dateFormat = 'MM/dd/yyyy') => {
  return date ? format(date, dateFormat) : ''
})
Vue.use(vClickOutside)

globalComponents.forEach((component) =>
  Vue.component(component.name, component)
)

Vue.prototype.$settings = window.HM_CONFIG

const idleVueOptions = {
  store,
  idleTime: 60000 * 15,
  moduleName: 'activity',
  startAtIdle: false,
}
Vue.use(IdleVue, idleVueOptions)

new Vue({
  i18n,
  router,
  store,
  created() {
    this.setupFavicon()
  },
  methods: {
    setupFavicon() {
      const link = document.createElement('link')
      link.rel = 'shortcut icon'
      link.href = `${this.$settings.S3_ROOT}/favicon.png`
      document.head.appendChild(link)
    },
  },
  render: (h) => h(App),
}).$mount('#app')
