var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-40 text-center w-435"},[_c('h5',[_vm._v(_vm._s(_vm.$t('agreements.updated_agreements')))]),_c('p',{staticClass:"mt-20"},[_vm._v(_vm._s(_vm.$t('agreements.about')))]),_c('p',[(_vm.showPrivacy)?_c('router-link',{staticClass:"text-blue-500",attrs:{"to":{
        name: 'public-privacy-document',
        params: { title: _vm.$t('common.privacy_link') },
      },"target":"_blank"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.privacy'))+" ")]):_vm._e(),(_vm.showPrivacy && _vm.showTerms)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('agreements.and'))+" ")]):_vm._e(),(_vm.showTerms)?_c('router-link',{staticClass:"text-blue-500",attrs:{"to":{
        name: 'public-privacy-document',
        params: { title: _vm.$t('common.terms_link') },
      },"target":"_blank"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.terms'))+" ")]):_vm._e()],1),_c('p',{staticClass:"mt-20"},[_vm._v(_vm._s(_vm.$t('agreements.click_links')))]),_c('p',[_vm._v(_vm._s(_vm.$t('agreements.click_understand')))]),_c('HMButton',{staticClass:"w-full mt-20",attrs:{"rounded":"full"},on:{"click":_vm.agree}},[_vm._v(" "+_vm._s(_vm.$t('agreements.understand'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }