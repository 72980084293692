import _includes from 'lodash/includes'
import _map from 'lodash/map'

export const supportedLocale = (locale, clientLocales) => {
  const loc = locale.toUpperCase()
  const shortLoc = loc.substring(0, 2)
  const locales = _map(clientLocales, ({ code }) => code)

  /*
  first try to return a direct locale match from the client supported locales
  if no direct match, check if client supports the language without region specifics (i.e client supporst "en" but not "en-gb")
  default to 'en'
  */
  return _includes(locales, loc)
    ? loc
    : _includes(locales, shortLoc)
    ? shortLoc
    : 'EN'
}
