export default () => ({
  meta: {
    loadedOn: null,
    error: false,
    isLoading: {
      myHealthRisks: false,
      getPrescriptionRecord: false,
    },
  },
  overallRisk: {},
  healthSummary: [],
  prescriptions: [],
})
