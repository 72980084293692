import _filter from 'lodash/filter'
import _size from 'lodash/size'
import _get from 'lodash/get'
import _reject from 'lodash/reject'
import _take from 'lodash/take'
import _concat from 'lodash/concat'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import shuffle from 'lodash/fp/shuffle'
import values from 'lodash/fp/values'
import groupBy from 'lodash/fp/groupBy'
import reduce from 'lodash/fp/reduce'
import keyBy from 'lodash/fp/keyBy'
import orderBy from 'lodash/fp/orderBy'
import take from 'lodash/fp/take'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { SHOULD_LOAD_THRESHOLD } from '@platform-shared/constants'

export default {
  //module-wide
  shouldLoad: (state) => (now = new Date()) => {
    return (
      !state.meta.isLoading.init &&
      (!state.meta.loadedOn ||
        differenceInMinutes(now, state.meta.loadedOn) > SHOULD_LOAD_THRESHOLD)
    )
  },
  hasLoaded: (state) => !!state.meta.loadedOn,
  learnInitLoading: (state) => state.meta.isLoading.init,
  learnInitError: (state) => state.meta.error.init,

  //article Landing
  userArticles: (state) =>
    state.articles.map((contentId) => state.articlesById[contentId]),
  unreadUserArticles: (state, getters) =>
    _reject(getters.userArticles, 'completionDate'),
  nUnreadUserArticles: (state, getters) => (count = 3) =>
    _take(getters.unreadUserArticles, count),
  historicalArticles: (state, getters) =>
    _filter(getters.userArticles, 'completionDate'),
  historicalArticleCount: (state, getters) => _size(getters.historicalArticles),
  nHistoricalArticles: (state, getters) => (count = 3) =>
    flow(
      orderBy(['completionDate'], ['desc']),
      take(count)
    )(getters.historicalArticles),
  userArticleById: (state, getters) => (contentId) =>
    _get(state.articlesById, contentId, null) ||
    _get(getters.suggestedArticles, `articles.${contentId}`, null) ||
    _get(getters.flatSearchResults, contentId, null),

  //article detail
  isArticleLoading: (state) => state.meta.isLoading.article,

  //article search
  isSearchLoading: (state) => state.meta.isLoading.search,
  isSearchShowing: (state) => state.searchShowing,
  searchHasError: (state) => state.meta.error.search,
  searchKeywords: (state) => state.searchKeywords,
  searchedKeywords: (state) => state.searchedKeywords,
  searchResults: (state) => state.searchResults,
  flatSearchResults: (state, getters) =>
    flow(
      reduce(
        (articleList, category) =>
          _concat(articleList, _get(category, 'articles', [])),
        []
      ),
      keyBy('contentId')
    )(getters.searchResults),

  //suggested articles
  suggestedArticles: (state) => state.suggestedArticles,
  isSuggestedLoading: (state) => state.meta.isLoading.suggested,
  suggestedHasError: (state) => state.meta.error.suggested,
  articlesByCategory: (state) => (category) => {
    const items = _get(state, 'suggestedArticles.articles')
    const categories = _get(state, 'suggestedArticles.categories')
    return flow(
      find({ id: category }),
      getOr([], 'items'),
      map((item) => items[item]),
      groupBy('typeLabel')
    )(categories)
  },
  topArticlesByCategory: (state) => (category) => {
    const items = _get(state, 'suggestedArticles.articles')
    const categories = _get(state, 'suggestedArticles.categories')
    return flow(
      find({ id: category }),
      getOr([], 'items'),
      map((item) => items[item]),
      take(3)
    )(categories)
  },
  randomArticles: (state) => (count = 3) => {
    const items = _get(state, 'suggestedArticles.articles')
    return flow(values, shuffle, take(count))(items)
  },
  hasMoreArticles: (state) => (category) => {
    const items = _get(state, 'suggestedArticles.articles')
    const categories = _get(state, 'suggestedArticles.categories')
    return (
      flow(
        find({ id: category }),
        getOr([], 'items'),
        map((item) => items[item]),
        _size
      )(categories) > 3
    )
  },
  //brain exercise Landing
  brainExercises: (state) => state.brainExercises,
  nBrainExercises: (state, getters) => (count = 3) =>
    _take(getters.brainExercises, count),
  brainExerciseByCode: (state) => (exerciseCd) =>
    flow(find({ exerciseCd: exerciseCd }))(state.brainExercises) || null,
}
