<template>
  <div
    class="font-sans-normal text-xs rounded py-5 px-10 mr-5 mt-5"
    v-bind="attrs"
  >
    <Icon
      v-if="!!icon"
      class="fas text-xs mr-5"
      :title="$t(`common.icons.screen_reader_names.${icon}`)"
      :iconClass="`fa-${icon}`"
      v-bind="attrs"
    />
    <span>{{ text }}</span>
  </div>
</template>

<script>
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  name: 'Tag',
  mixins: [bindAll],
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
}
</script>
