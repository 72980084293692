export default {
  RESET_STATE: 'health_actions/reset_state',

  INIT_REQUEST: 'health_actions/init/request',
  INIT_SUCCESS: 'health_actions/init/success',
  INIT_FAILURE: 'health_actions/init/failure',

  CREATE_ATTESTATION_REQUEST: 'member/create_attestation/request',
  CREATE_ATTESTATION_SUCCESS: 'member/create_attestation/success',
  CREATE_ATTESTATION_FAILURE: 'member/create_attestation/failure',

  SUBMIT_PROFILE_UPDATE_REQUEST: 'member/submit_profile_update/request',
  SUBMIT_PROFILE_UPDATE_SUCCESS: 'member/submit_profile_update/success',
  SUBMIT_PROFILE_UPDATE_FAILURE: 'member/submit_profile_update/failure',

  MARK_ARTICLE_AS_READ_REQUEST: 'member/mark_article_as_read/request',
  MARK_ARTICLE_AS_READ_SUCCESS: 'member/mark_article_as_read/success',
  MARK_ARTICLE_AS_READ_FAILURE: 'member/mark_article_as_read/failure',
}
