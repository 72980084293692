export default () => ({
  meta: {
    isLoading: false,
    error: null,
  },
  currentGroupId: null,
  groups: [],
  id: null,
  myId: null,
  type: null,
  inProgress: false,
  processing: false,
  runningAnswers: {},
})
