import Super from './components/common/Typography/Super'
import Title from './components/common/Typography/Title'
import H4 from './components/common/Typography/H4'
import H3 from './components/common/Typography/H3'
import H2 from './components/common/Typography/H2'
import H1 from './components/common/Typography/H1'
import Body from './components/common/Typography/Body'
import Button from './components/common/forms/Button'
import Icon from './components/common/Icon'
import Tag from './components/common/Typography/Tag'
import Back from './components/common/Back'

export default [Button, Super, Title, H4, H3, H2, H1, Body, Icon, Tag, Back]
