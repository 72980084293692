<template>
  <div class="flex flex-col min-h-screen overflow-x-hidden">
    <div class="p-4">
      <a href="#main" class="skip-link">{{
        $t('common.header.skip_to_main')
      }}</a>
    </div>
    <Header />
    <main id="main">
      <div class="container flex-grow flex flex-col items-center p-12">
        <RouterView></RouterView>
      </div>
    </main>
    <Footer authenticated />
    <Agreements />
  </div>
</template>

<script>
import Header from '../common/Header'
import Footer from '../common/Footer'
import Agreements from './Agreements'

export default {
  components: { Header, Footer, Agreements },
}
</script>

<style lang="postcss" scoped>
.skip-link {
  &:not(:focus) {
    @apply sr-only;
  }
  &:focus {
    @apply outline-none shadow-outline;
  }
}
</style>
