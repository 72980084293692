<template>
  <div>
    <Modal :active="showPrivacyAgreements">
      <Privacy />
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Privacy from './Privacy'
import Modal from '@components/common/Modal'

export default {
  components: { Modal, Privacy },
  computed: {
    ...mapGetters('member', ['pendingPrivacyAgreements']),
    showPrivacyAgreements() {
      return this.pendingPrivacyAgreements.length > 0
    },
  },
}
</script>
