import _once from 'lodash/once'
import store from '../store'
import { getNormalizedEnvironment } from './appConfig'

export default {
  init: _once(() => {
    if (window.heap && window.heap.addUserProperties) {
      window.heap.addUserProperties({
        /* eslint-disable camelcase */
        hm_env: getNormalizedEnvironment(),
        hm_tenant_id: window.HM_CONFIG.ID,
        hm_tenant_abbr: window.HM_CONFIG.SCHEMA,
        hm_program_name: window.HM_CONFIG.NAME,
        hm_default_med_plan_type:
          store.getters['client/defaultMedicalPlanTypeCode'],
        hm_lob_type: store.getters['client/lineOfBusinessTypeCode'],
        /* eslint-enable camelcase */
      })
    }
  }),

  setMemberIdentity(memberInfo) {
    if (window.heap && window.heap.identify && memberInfo.id) {
      window.heap.identify(
        `${getNormalizedEnvironment()}:${window.HM_CONFIG.ID}:${memberInfo.id}`
      )
    }

    if (window.heap && window.heap.addUserProperties) {
      window.heap.addUserProperties({
        /* eslint-disable camelcase */
        hm_user_id: memberInfo.id,
        hm_user_name: memberInfo.username,
        /* eslint-enable camelcase */
      })
    }
  },
}
