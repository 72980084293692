import types from './faq.types'
import initialState from './faq.state'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.INIT_REQUEST]: (state) => {
    state.meta.isLoading = true
  },
  [types.INIT_SUCCESS]: (state, faq) => {
    state.meta.loadedOn = new Date()
    state.meta.isLoading = false
    state.meta.error = false
    state.items = faq.items
    state.categories = faq.categories
  },
  [types.INIT_FAILURE]: (state) => {
    state.meta.isLoading = false
    state.meta.error = true
  },
}
