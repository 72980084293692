export default {
  RESET_STATE: 'tracking/reset_state',

  INIT_FAILURE: 'tracking/init/failure',
  INIT_REQUEST: 'tracking/init/request',
  INIT_SUCCESS: 'tracking/init/success',

  UPDATE_TRACKER_FAILURE: 'tracking/update_tracker/failure',
  UPDATE_TRACKER_REQUEST: 'tracking/update_tracker/request',
  UPDATE_TRACKER_SUCCESS: 'tracking/update_tracker/success',

  UPDATE_TRACKER_HISTORY_BULK_REQUEST:
    'tracking/update_tracker_history_bulk/request',
  UPDATE_TRACKER_HISTORY_BULK_SUCCESS:
    'tracking/update_tracker_history_bulk/success',
  UPDATE_TRACKER_HISTORY_BULK_FAILURE:
    'tracking/update_tracker_history_bulk/failure',

  TRACKER_HISTORY_FAILURE: 'tracking/tracker_history/failure',
  TRACKER_HISTORY_REQUEST: 'tracking/tracker_history/request',
  TRACKER_HISTORY_SUCCESS: 'tracking/tracker_history/success',

  SUMMARY_FAILURE: 'tracking/summary/failure',
  SUMMARY_REQUEST: 'tracking/summary/request',
  SUMMARY_SUCCESS: 'tracking/summary/success',

  SET_HUMAN_API_PUBLIC_TOKEN_FAILURE:
    'tracking/set_human_api_public_token/failure',
  SET_HUMAN_API_PUBLIC_TOKEN_REQUEST:
    'tracking/set_human_api_public_token/request',
  SET_HUMAN_API_PUBLIC_TOKEN_SUCCESS:
    'tracking/set_human_api_public_token/success',
}
