import { CURRENCY_TYPES } from '@platform-shared/constants'

export default () => ({
  meta: {
    loadedOn: null,
    error: false,
    isLoading: false,
  },
  features: [],
  support: {},
  lingo: {},
  loginMessage: {},
  importantDocuments: [],
  contracts: [],
  languages: [],
  termPeriodDayCount: 0,
  defaultMedicalPlanTypeCode: 'UNK',
  lineOfBusinessTypeCode: 'UNK',
  currency: CURRENCY_TYPES.DOLLARS,
  races: [],
  ethnicities: [],
  preferredLanguages: [],
})
