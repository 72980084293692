import api from './api'

export const getMyHealthRisks = () => {
  const myHealthRisksUrl = `/v1/${process.env.VUE_APP_DEVICE}/health-profile/health-risks`
  return api()
    .url(myHealthRisksUrl)
    .get()
}

export const getMyPrescriptions = () => {
  const myPrescriptionsUrl = `/v1/${process.env.VUE_APP_DEVICE}/health-profile/prescriptions`
  return api()
    .url(myPrescriptionsUrl)
    .get()
}
