import types from './inbox.types'
import * as inboxService from '@platform-shared/services/inbox'
import { MESSAGE_STATUS } from '@platform-shared/constants'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },

  getMessages({ commit, dispatch }) {
    commit(types.GET_MESSAGES_REQUEST)
    inboxService
      .getMessages()
      .then(({ data }) => dispatch('saveMessages', data))
      .catch(() => commit(types.GET_MESSAGES_FAILURE))
  },

  saveMessages({ commit }, messages) {
    commit(types.INIT_SUCCESS, messages)
  },

  updateMessageStatus({ commit }, { messageId, status }) {
    inboxService.updateMessageStatus(messageId, status).then(() =>
      commit(types.UPDATE_MESSAGE_SUCCESS, {
        messageId,
        status,
      })
    )
  },
  markAsRead({ dispatch }, messageId) {
    dispatch('updateMessageStatus', {
      messageId,
      status: MESSAGE_STATUS.READ,
    })
  },
  archive({ dispatch }, messageId) {
    dispatch('updateMessageStatus', {
      messageId,
      status: MESSAGE_STATUS.ARCHIVE,
    })
  },
  unarchive({ dispatch }, messageId) {
    dispatch('markAsRead', messageId)
  },

  getEmailSubscriptions({ commit }) {
    commit(types.GET_SUBSCRIPTIONS_REQUEST)

    return new Promise((resolve, reject) => {
      inboxService
        .getEmailSubscriptions()
        .then((res) => {
          commit(types.GET_SUBSCRIPTIONS_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.GET_SUBSCRIPTIONS_FAILURE)
          reject(error)
        })
    })
  },

  createSubscriptionCommentWithToken({ commit }, { memberId, token, comment }) {
    commit(types.CREATE_SUBSCRIPTION_COMMENT_REQUEST)

    return new Promise((resolve, reject) => {
      inboxService
        .createSubscriptionCommentWithToken({ memberId, token, comment })
        .then((res) => {
          commit(types.CREATE_SUBSCRIPTION_COMMENT_SUCCESS)
          resolve(res)
        })
        .catch((error) => {
          commit(types.CREATE_SUBSCRIPTION_COMMENT_FAILURE)
          reject(error)
        })
    })
  },
}
