<template>
  <div class="text-4xl font-sans-bold" v-bind="attrs">
    <slot />
  </div>
</template>

<script>
import bindAll from '@platform-shared/mixins/bindAll'

export default {
  name: 'Title',
  mixins: [bindAll],
}
</script>
