import types from './app.types'
import {
  FLASH_MESSAGE_TIMEOUT,
  MESSAGE_TYPES,
} from '@platform-shared/constants'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  showErrorMessage({ commit }, message) {
    commit(types.CREATE_MESSAGE, { type: MESSAGE_TYPES.ERROR, message })
    setTimeout(() => {
      commit(types.REMOVE_MESSAGE)
    }, FLASH_MESSAGE_TIMEOUT)
  },
  setSecurityMessage({ commit }, message) {
    commit(types.CREATE_SECURITY_MESSAGE, message)
  },
  removeSecurityMessage({ commit }) {
    commit(types.REMOVE_SECURITY_MESSAGE)
  },
  savePostLoginPath({ commit }, path) {
    commit(types.SAVE_POST_LOGIN_PATH, path)
  },
  removePostLoginPath({ commit }) {
    commit(types.REMOVE_POST_LOGIN_PATH)
  },
}
