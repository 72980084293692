import * as rewardsService from '@platform-shared/services/rewards'
import types from './rewards.types'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import pick from 'lodash/fp/pick'
import _findLastIndex from 'lodash/findLastIndex'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  invalidateRewards({ commit }) {
    commit(types.INVALIDATE)
  },
  initRewards({ commit, getters, rootGetters }, force = false) {
    if (!getters.shouldLoad() && !force) return

    const memberInfo = rootGetters['member/memberInfo']
    const { address } = memberInfo

    commit(types.INIT_REQUEST)
    return new Promise((resolve, reject) => {
      rewardsService
        .initRewards()
        .then((res) => {
          commit(types.REWARDS_UPDATE_DELIVERY_ADDRESS, address)
          commit(types.INIT_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },
  selectRewardBox({ commit }, rewardBox) {
    commit(types.REWARD_BOX_ADD, rewardBox)
  },
  clearSelectedRewardBox({ commit }) {
    commit(types.REWARD_BOX_RESET_BASKET)
  },
  redeemRewardBox({ commit, rootGetters }) {
    commit(types.REWARD_BOX_REDEEM_REQUEST)
    return new Promise((resolve, reject) => {
      const selectedRewardBox = rootGetters['rewards/selectedRewardBox']
      const deliveryAddress = rootGetters['rewards/deliveryAddress']
      rewardsService
        .redeemRewardBox(selectedRewardBox, deliveryAddress)
        .then(() => {
          commit(types.REWARD_BOX_REDEEM_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REWARD_BOX_REDEEM_FAILURE)
          reject(error)
        })
    })
  },
  addReward({ commit, getters, state }, { reward, healthActionCompletionId }) {
    if (
      getters.remainingMoney(healthActionCompletionId) < reward.redemptionAmount
    )
      return

    const sourcedCoverageBalance = healthActionCompletionId
      ? state.directIncentiveBalances
      : getters.dependentForRedemption
      ? state.dependentCoverageBalances[getters.dependentForRedemption]
      : state.memberCoverageBalances
    const filteredBalances = sourcedCoverageBalance.filter(
      (b) =>
        b.availablePoints >= reward.redemptionAmount &&
        (!healthActionCompletionId ||
          healthActionCompletionId == b.healthActionCompletionId)
    )
    const smallestBalance = filteredBalances.reduce(
      (acc, currentBalance) =>
        currentBalance.availablePoints > acc.availablePoints
          ? acc
          : currentBalance,
      filteredBalances[0]
    )
    const remainingMoney =
      getters.remainingMoney(healthActionCompletionId) - reward.redemptionAmount
    let basket = getters.basket

    basket.push({
      rewardId: reward.id,
      quantity: 1,
      reward,
      memberCoverageId: smallestBalance.memberCoverageId,
      cohortIncentiveStrategyId: smallestBalance.cohortIncentiveStrategyId,
      cohortIncentiveStrategyType: smallestBalance.cohortIncentiveStrategyType,
    })

    const balanceIndex = sourcedCoverageBalance.findIndex(
      (balance) =>
        balance.memberCoverageId === smallestBalance.memberCoverageId &&
        balance.cohortIncentiveStrategyId ===
          smallestBalance.cohortIncentiveStrategyId &&
        balance.cohortIncentiveStrategyType ===
          smallestBalance.cohortIncentiveStrategyType
    )
    sourcedCoverageBalance[balanceIndex].availablePoints -=
      reward.redemptionAmount

    if (getters.dependentForRedemption) {
      let dependentCoverageBalances = state.dependentCoverageBalances
      dependentCoverageBalances[
        getters.dependentForRedemption
      ] = sourcedCoverageBalance

      commit(types.REWARDS_BASKET_ADD, {
        basket,
        remainingMoney,
        memberCoverageBalances: [],
        dependentCoverageBalances,
      })
    } else if (healthActionCompletionId) {
      commit(types.REWARDS_BASKET_ADD, {
        basket,
        remainingMoney,
        directIncentiveBalances: sourcedCoverageBalance,
        dependentCoverageBalances: {},
      })
    } else {
      commit(types.REWARDS_BASKET_ADD, {
        basket,
        remainingMoney,
        memberCoverageBalances: sourcedCoverageBalance,
        directIncentiveBalances: sourcedCoverageBalance,
        dependentCoverageBalances: {},
      })
    }
  },
  removeReward(
    { commit, getters, state },
    { reward, healthActionCompletionId }
  ) {
    let basket = getters.basket

    const indexToRemove = _findLastIndex(
      basket,
      (item) => item.rewardId == reward.id
    )
    const remainingMoney =
      getters.remainingMoney(healthActionCompletionId) + reward.redemptionAmount

    if (indexToRemove != -1) {
      let sourcedCoverageBalance = healthActionCompletionId
        ? state.directIncentiveBalances
        : getters.dependentForRedemption
        ? state.dependentCoverageBalances[getters.dependentForRedemption]
        : state.memberCoverageBalances

      const balanceIndex = sourcedCoverageBalance.findIndex(
        (balance) =>
          balance.memberCoverageId == basket[indexToRemove].memberCoverageId &&
          balance.cohortIncentiveStrategyId ==
            basket[indexToRemove].cohortIncentiveStrategyId &&
          balance.cohortIncentiveStrategyType ==
            basket[indexToRemove].cohortIncentiveStrategyType &&
          (!healthActionCompletionId ||
            healthActionCompletionId == balance.healthActionCompletionId)
      )
      sourcedCoverageBalance[balanceIndex].availablePoints +=
        reward.redemptionAmount

      basket.splice(indexToRemove, 1)

      if (getters.dependentForRedemption) {
        let dependentCoverageBalances = state.dependentCoverageBalances
        dependentCoverageBalances[
          getters.dependentForRedemption
        ] = sourcedCoverageBalance

        commit(types.REWARDS_BASKET_ADD, {
          basket,
          remainingMoney,
          memberCoverageBalances: [],
          dependentCoverageBalances,
        })
      } else if (healthActionCompletionId) {
        commit(types.REWARDS_BASKET_ADD, {
          basket,
          remainingMoney,
          directIncentiveBalances: sourcedCoverageBalance,
          dependentCoverageBalances: {},
        })
      } else {
        commit(types.REWARDS_BASKET_ADD, {
          basket,
          remainingMoney,
          memberCoverageBalances: sourcedCoverageBalance,
          dependentCoverageBalances: {},
        })
      }
    }
  },
  resetBasket({ commit }) {
    commit(types.REWARDS_RESET_BASKET)
  },
  redeemCart({ commit, getters, state }, healthActionCompletionId) {
    const dependentForRedemption = getters.dependentForRedemption
    const dependent = dependentForRedemption
      ? flow(
          find({ memberId: dependentForRedemption }),
          pick(['memberId', 'memberCoverageId'])
        )(state.dependents)
      : null

    commit(types.REWARDS_REDEEM_REQUEST)
    return new Promise((resolve, reject) => {
      const basket = getters.basket
      const deliveryAddress = getters.deliveryAddress
      rewardsService
        .redeemCart(
          basket,
          deliveryAddress,
          dependent,
          healthActionCompletionId
        )
        .then(() => {
          commit(types.REWARDS_REDEEM_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REWARDS_REDEEM_FAILURE)
          reject(error)
        })
    })
  },
  updateDeliveryAddress({ commit }, address) {
    commit(types.REWARDS_UPDATE_DELIVERY_ADDRESS, address)
  },
  updateAutoRewardDeliveryAddress({ commit }, address) {
    commit(types.SET_AUTO_REWARD_ADDRESS, address)
  },
  selectAutoReward({ commit }, { reward, type }) {
    commit(types.SELECT_AUTO_REWARD, { reward, type })
  },
  resetSelectedAutoReward({ commit }) {
    commit(types.RESET_SELECTED_AUTO_REWARD)
  },
  setupAutoReward({ commit, dispatch }, { productId, delivery, address }) {
    commit(types.SETUP_AUTO_REWARDS_REQUEST)
    return new Promise((resolve, reject) => {
      rewardsService
        .setupAutoRewards(productId, delivery, address)
        .then(() => {
          dispatch('initRewards', true)
          commit(types.SETUP_AUTO_REWARDS_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.SETUP_AUTO_REWARDS_FAILURE)
          reject(error)
        })
    })
  },
  turnOffAutoReward({ commit, dispatch }, preferenceId) {
    commit(types.TURN_OFF_AUTO_REWARD_REQUEST)
    return new Promise((resolve, reject) => {
      rewardsService
        .turnOffAutoRewards(preferenceId)
        .then(() => {
          dispatch('initRewards', true)
          commit(types.TURN_OFF_AUTO_REWARD_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.TURN_OFF_AUTO_REWARD_FAILURE)
          reject(error)
        })
    })
  },
  openConfirmAutoReward({ commit }) {
    commit(types.OPEN_CONFIRM_AUTO_REWARD)
  },
  closeConfirmAutoReward({ commit }) {
    commit(types.CLOSE_CONFIRM_AUTO_REWARD)
  },
  setDependentForRedemption({ commit }, dependentId) {
    commit(types.SET_DEPENDENT_FOR_REDEMPTION, dependentId)
  },
  getIncentiveCatalog({ commit }, incentiveCatalogCd) {
    commit(types.GET_INCENTIVE_CATALOG_REQUEST)

    return new Promise((resolve, reject) => {
      rewardsService
        .getIncentiveCatalog(incentiveCatalogCd)
        .then((res) => {
          commit(types.GET_INCENTIVE_CATALOG_SUCCESS, {
            incentiveCatalogCd,
            incentiveProducts: res.data,
          })
          resolve()
        })
        .catch((error) => {
          commit(types.GET_INCENTIVE_CATALOG_FAILURE)
          reject(error)
        })
    })
  },
  getIncentiveCatalogForHealthAction(
    { dispatch, rootGetters },
    healthActionId
  ) {
    const healthAction = rootGetters['healthActions/healthActionById'](
      healthActionId
    )
    if (healthAction) {
      const { incentiveCatalogCd } = healthAction
      return dispatch('getIncentiveCatalog', incentiveCatalogCd)
    }
  },
}
