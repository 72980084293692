import state from './tracking.state'
import mutations from './tracking.mutations'
import actions from './tracking.actions'
import getters from './tracking.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
