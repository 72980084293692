import isNull from 'lodash/isNull'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _filter from 'lodash/filter'
import filter from 'lodash/fp/filter'
import getOr from 'lodash/fp/getOr'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import some from 'lodash/fp/some'
import includes from 'lodash/fp/includes'
import map from 'lodash/fp/map'
import find from 'lodash/find'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import fpMap from 'lodash/fp/map'
import pick from 'lodash/fp/pick'
import {
  QUESTIONNAIRE_SCREEN_TYPES,
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_TYPES,
} from '@platform-shared/constants'

export default {
  questionnaireId: (state) => state.id,
  questionnaire: (state, getters, rootState, rootGetters) =>
    rootGetters['questionnaire/questionnaireById'](getters.questionnaireId),
  questionnaireType: (state) => state.type,
  groups: (state) => state.groups,
  currentGroupId: (state) => state.currentGroupId,
  runningAnswers: (state) => state.runningAnswers,
  currentGroup: (state, getters, rootState, rootGetters) => {
    const questionnaire = getters.questionnaire

    if (!questionnaire) {
      return null
    }

    const group = questionnaire.groups[getters.currentGroupId]

    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']

    const questions = group.questions
      .map((question) => {
        const requirements = flow(
          getOr('{}', 'otherSelectors'),
          JSON.parse,
          getOr([], 'requiredAnswers')
        )(questionnaire.questions[question])
        const questionnaireQuestion = _get(questionnaire, [
          'questions',
          question,
        ])
        return {
          ...questionnaireQuestion,
          answerOptions: _filter(
            _get(questionnaireQuestion, 'answerOptions', []),
            'active'
          ),
          requirements,
        }
      })
      .filter(({ minAge, maxAge, gender }) => {
        return (
          (minAge == null || minAge <= age) &&
          (maxAge == null || maxAge >= age) &&
          (gender == null ||
            memberGender ===
              gender
                .trim()
                .charAt(0)
                .toUpperCase())
        )
      })
    const answers = questions.map((question) => ({
      question: question.id,
      answers: questionnaire.answers[question.id],
    }))
    const skippable = questions.every((question) => question.optional)

    return {
      ...group,
      questions,
      answers,
      skippable,
    }
  },

  isFirstGroup: (state, getters) => {
    return getters.currentGroupId == 1
  },
  isLastGroup: (state, getters) => {
    const questionnaire = getters.questionnaire

    return getters.currentGroupId === _size(questionnaire.groups).toString()
  },

  ids: (state) => ({
    myId: state.myId,
    id: state.id,
  }),

  canGoBack: (state, getters) => {
    const groupKeys = Object.keys(getters.groups)
    const currentGroupIndex = groupKeys.findIndex(
      (key) => key === getters.currentGroupId
    )

    return !!currentGroupIndex
  },

  orderedFilteredQuestions: (state, getters, rootState, rootGetters) => {
    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']
    const orderedQuestions = _get(getters, 'questionnaire.orderedQuestions')
    const questionnaire = getters.questionnaire
    const numbersOnOptional = _get(
      questionnaire,
      'settings.meta.numbersOnOptional',
      true
    )

    return flow(
      fpMap((question) => questionnaire.questions[question]),
      filter(({ minAge, maxAge, gender, otherSelectors, optional }) => {
        const includeOptional = !numbersOnOptional && optional

        const showConditional =
          otherSelectors &&
          flow(
            JSON.parse,
            getOr([], 'requiredAnswers'),
            some(({ questionId, answer }) =>
              flow(
                get(questionId),
                map('answer'),
                includes(answer)
              )(getters.runningAnswers)
            )
          )(otherSelectors)

        return (
          !includeOptional &&
          (!otherSelectors || showConditional) &&
          (minAge == null || minAge <= age) &&
          (maxAge == null || maxAge >= age) &&
          (gender == null ||
            memberGender ===
              gender
                .trim()
                .charAt(0)
                .toUpperCase())
        )
      }),
      fpMap((question) => question.id)
    )(orderedQuestions)
  },

  questionNumberById: (state, getters) => (id) => {
    const orderedQuestions = _get(getters, 'orderedFilteredQuestions')
    return orderedQuestions.indexOf(id) + 1
  },

  totalGroups: (state, getters) => _size(_get(getters, 'questionnaire.groups')),
  totalQuestions: (state, getters) =>
    _get(getters, 'orderedFilteredQuestions.length', 0),

  isNumbered: (state, getters) =>
    _get(getters, 'questionnaire.settings.meta.numberedQuestions', false),

  inProgress: (state) => state.inProgress,
  processing: (state) => state.processing,
  started: (state, getters) => {
    return getters.questionnaire.status === QUESTIONNAIRE_STATUS.STARTED
  },

  exitScreen: (state, getters) => {
    const questionnaire = getters.questionnaire

    if (!questionnaire) {
      return false
    }

    const screens = questionnaire.settings.screens

    if (!screens.hasOwnProperty(QUESTIONNAIRE_SCREEN_TYPES.EXIT)) {
      return false
    }

    return screens[QUESTIONNAIRE_SCREEN_TYPES.EXIT]
  },

  lastCompleted: (state, getters) => {
    const completions = _get(getters, 'questionnaire.allCompletions')
    return find(completions, { status: QUESTIONNAIRE_STATUS.COMPLETED })
  },

  shouldPrefillAnswers: (state, getters) =>
    _get(getters, 'questionnaire.settings.meta.prefillOldAnswers', false),

  currentGroupPrefillAnswers: (state, getters) => {
    const currentGroupQuestions = _get(getters, 'currentGroup.questions', [])
    const previousAnswers = flow(
      getOr({}, 'lastCompleted.answers'),
      pick(_map(currentGroupQuestions, 'id'))
    )(getters)

    return getters.shouldPrefillAnswers ? previousAnswers : []
  },

  currentScreen: (state, getters) => {
    const questionnaire = getters.questionnaire

    if (!questionnaire) {
      return null
    }

    const { status, type } = questionnaire
    const processing = getters.processing
    const inProgress = getters.inProgress

    const supportsStartScreen = type === QUESTIONNAIRE_TYPES.FTE
    const startCondition =
      (isNull(status) && !inProgress) ||
      (status === QUESTIONNAIRE_STATUS.STARTED && !inProgress)

    const supportsWelcomeBackScreen = type === QUESTIONNAIRE_TYPES.FTE
    const welcomeBackCondition =
      status === QUESTIONNAIRE_STATUS.STARTED && !inProgress

    const supportsProcessScreen =
      type === QUESTIONNAIRE_TYPES.HRA || type === QUESTIONNAIRE_TYPES.FTE
    const processCondition =
      status === QUESTIONNAIRE_STATUS.COMPLETED && !inProgress && processing

    const successCondition =
      type === QUESTIONNAIRE_TYPES.HRA_SAT
        ? !inProgress && processing
        : (status === QUESTIONNAIRE_STATUS.COMPLETED &&
            !inProgress &&
            !processing) ||
          (status === QUESTIONNAIRE_STATUS.COMPLETED &&
            !supportsProcessScreen &&
            !inProgress &&
            processing)

    if (supportsStartScreen && startCondition) {
      return QUESTIONNAIRE_SCREEN_TYPES.START
    }
    if (supportsWelcomeBackScreen && welcomeBackCondition) {
      return QUESTIONNAIRE_SCREEN_TYPES.WELCOME_BACK
    }
    if (supportsProcessScreen && processCondition) {
      return QUESTIONNAIRE_SCREEN_TYPES.PROCESS
    }
    if (successCondition) {
      return QUESTIONNAIRE_SCREEN_TYPES.SUCCESS
    }
    return QUESTIONNAIRE_SCREEN_TYPES.GROUP
  },
  questionnaireLength: (state, getters) => {
    return _get(getters, 'questionnaire.length', 'LONG')
  },
  isVariableLength: (state, getters) => {
    return _get(getters.questionnaire, 'settings.meta.isVariableLength', false)
  },
  screenWidth: (state, getters) => {
    return _get(getters.questionnaire, 'settings.meta.screenWidth', 'normal')
  },
  questionnaireSources: (state, getters) => {
    const questionIds = _get(getters, 'questionnaire.orderedQuestions', [])
    const questions = questionIds.map((qid) =>
      _get(getters, ['questionnaire', 'questions', qid], {})
    )
    return questions.reduce(
      (acc, { sources = [] }) => _uniqBy([...acc, ...sources], 'id'),
      []
    )
  },
}
