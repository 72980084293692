<template>
  <div class="text-grey-500">
    <div v-if="supportLingo" class="max-w-md" v-html="supportLingo" />
    <div v-else>
      <h1 v-if="!forHelpCenter" class="text-black mb-25 text-center h3">
        {{ $t('contact_support.title') }}
      </h1>
      <p>
        {{ supportInstruction }}
      </p>
      <div v-if="showSupportNumbers && supportNumber" class="flex mt-10">
        <p class="mr-4">
          {{ $t('contact_support.call') }}
        </p>
        <a class="text-blue-500" :href="`tel:${supportNumber}`">
          {{ supportNumber }}
        </a>
        <p v-if="supportNumberDescription" class="ml-4">
          ({{ supportNumberDescription }})
        </p>
      </div>
      <div v-if="showSupportNumbers && supportNumberSecondary" class="flex">
        <p class="mr-4">
          {{ $t('contact_support.call') }}
        </p>
        <a class="text-blue-500" :href="`tel:${supportNumberSecondary}`">
          {{ supportNumberSecondary }}
        </a>
        <p v-if="supportNumberSecondaryDescription" class="ml-4">
          ({{ supportNumberSecondaryDescription }})
        </p>
      </div>
      <div v-if="supportEmail" class="flex">
        <p class="mr-4">
          {{ $t('contact_support.email') }}
        </p>
        <a
          class="text-blue-500"
          :href="
            `mailto:${supportEmail}?subject=${supportSubject}&body=${supportBody}`
          "
        >
          {{ supportEmail }}
        </a>
      </div>
      <div v-if="supportHours">
        <p class="mr-4 mt-10">
          {{ $t('contact_support.hours') }}
        </p>
        <p class="mr-4">
          {{ supportHours }}
        </p>
      </div>
      <HMButton
        v-if="supportEmail"
        class="w-full mt-25"
        :aria-label="$t('contact_support.email_support')"
        @click="emailSupport"
      >
        <Icon iconClass="fas fa-envelope text-white mr-8" />
        {{ $t('contact_support.email_support') }}
      </HMButton>
    </div>
    <slot />
  </div>
</template>

<script>
import supportMixin from '../mixins/support'

export default {
  mixins: [supportMixin],
  props: {
    forHelpCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emailSupport() {
      window.location.href = `mailto:${this.supportEmail}?subject=${this.supportSubject}&body=${this.supportBody}`
    },
  },
}
</script>
