import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('member', [
      'firstName',
      'lastName',
      'email',
      'primaryPhoneNumber',
    ]),
    ...mapGetters('client', ['supportByLanguageCd', 'supportLingo']),
    support() {
      return this.supportByLanguageCd(this.$root.$i18n.locale)
    },
    supportInstruction() {
      const altInstruction = this.support.altInstruction
      const instructionPrefix = this.$t('contact_support.description')

      return `${instructionPrefix}${
        altInstruction ? `, ${altInstruction}` : ':'
      }`
    },
    showSupportNumbers() {
      return !this.support.altInstruction
    },
    supportNumber() {
      return this.support.phone
    },
    supportNumberDescription() {
      return this.support.phoneDescription
    },
    supportNumberSecondary() {
      return this.support.phoneSecondary
    },
    supportNumberSecondaryDescription() {
      return this.support.phoneSecondaryDescription
    },
    supportClientNumber() {
      return this.support.clientPhone
    },
    supportEmail() {
      return this.support.email
    },
    supportHours() {
      return this.support.hours
    },
    supportBody() {
      return (
        this.$t('help_center.support.email_body_first_name') +
        (this.firstName ? this.firstName : '') +
        '%0D%0A' +
        this.$t('help_center.support.email_body_last_name') +
        (this.lastName ? this.lastName : '') +
        '%0D%0A' +
        this.$t('help_center.support.email_body_number') +
        (this.primaryPhoneNumber ? this.primaryPhoneNumber : '') +
        '%0D%0A' +
        this.$t('help_center.support.email_body_email') +
        (this.email ? this.email : '')
      )
    },
    supportSubject() {
      return this.support.emailSubject
    },
  },
}
