<template>
  <router-link :to="route" v-bind="attrs" class="flex">
    <Icon iconClass="fas fa-chevron-left mr-8" />
    <p>{{ $t(title) }}</p>
  </router-link>
</template>
<script>
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  name: 'Back',
  mixins: [bindAll],
  props: {
    route: {
      type: [Object, String],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style></style>
