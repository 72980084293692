import state from './currentQuestionnaire.state'
import mutations from './currentQuestionnaire.mutations'
import actions from './currentQuestionnaire.actions'
import getters from './currentQuestionnaire.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
