export default {
  RESET_STATE: 'inbox/reset_state',

  INIT_SUCCESS: 'inbox/init/success',

  UPDATE_MESSAGE_SUCCESS: 'inbox/update_message/success',

  GET_MESSAGES_REQUEST: 'inbox/get_messages/request',
  GET_MESSAGES_FAILURE: 'inbox/get_messages/fail',

  GET_SUBSCRIPTIONS_REQUEST: 'inbox/get_subscriptions/request',
  GET_SUBSCRIPTIONS_SUCCESS: 'inbox/get_subscriptions/success',
  GET_SUBSCRIPTIONS_FAILURE: 'inbox/get_subscriptions/fail',

  TOGGLE_SUBSCRIPTION_STATUS: 'inbox/toggle_subscription_status',

  UPDATE_SUBSCRIPTIONS_REQUEST: 'inbox/update_subscriptions/request',
  UPDATE_SUBSCRIPTIONS_SUCCESS: 'inbox/update_subscriptions/success',
  UPDATE_SUBSCRIPTIONS_FAILURE: 'inbox/update_subscriptions/fail',

  CREATE_SUBSCRIPTION_COMMENT_REQUEST:
    'inbox/create_subscription_comment/request',
  CREATE_SUBSCRIPTION_COMMENT_SUCCESS:
    'inbox/create_subscription_comment/success',
  CREATE_SUBSCRIPTION_COMMENT_FAILURE: 'inbox/create_subscription_comment/fail',
}
