import types from './surveys.types'
import initialState from './surveys.state'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },

  [types.GET_SURVEY_REQUEST]: (state) => {
    state.meta.error = false
    state.meta.isLoading = true
  },
  [types.GET_SURVEY_SUCCESS]: (state) => {
    state.meta.loadedOn = new Date().valueOf()
    state.meta.isLoading = false
  },
  [types.GET_SURVEY_FAILURE]: (state) => {
    state.meta.loadedOn = new Date().valueOf()
    state.meta.error = true
    state.meta.isLoading = false
  },
}
