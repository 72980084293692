import Vue from 'vue'
import types from './rewards.types'
import initialState from './rewards.state'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'
import _get from 'lodash/get'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.INVALIDATE]: (state) => {
    state.meta.loadedOn = null
  },
  [types.INIT_REQUEST]: (state) => {
    state.meta.isLoading.init = true
  },
  [types.INIT_SUCCESS]: (
    state,
    {
      preferences,
      incentivesHistory,
      summary,
      directIncentivePointSummary,
      rewardsAvailable,
      rewardBoxesAvailable,
      tokenSummary,
      autoReward,
      dependents,
    }
  ) => {
    state.meta.isLoading.init = false
    state.meta.loadedOn = new Date()
    state.preferences = preferences
    state.incentivesHistory = incentivesHistory
    state.rewardsAvailable = rewardsAvailable
    state.rewardBoxesAvailable = rewardBoxesAvailable
    state.tokenSummary = tokenSummary
    state.activeAutoReward = autoReward.selected
    state.pointSummary = summary
    state.directIncentivePointSummary = directIncentivePointSummary
    state.memberCoverageBalances = summary.map(
      ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
      }) => ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
      })
    )
    state.directIncentiveBalances = directIncentivePointSummary.map(
      ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
        healthActionId,
        healthActionCompletionId,
      }) => ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
        healthActionId,
        healthActionCompletionId,
      })
    )
    state.dependentCoverageBalances = dependents.reduce((acc, curr) => {
      acc[curr.memberId] = curr.summary.map(
        ({
          memberCoverageId,
          availablePoints,
          cohortIncentiveStrategyId,
          cohortIncentiveStrategyType,
        }) => ({
          memberCoverageId,
          availablePoints,
          cohortIncentiveStrategyId,
          cohortIncentiveStrategyType,
        })
      )
      return acc
    }, {})
    state.autoRewardDeliveryAddress = !_isEmpty(autoReward.address)
      ? autoReward.address
      : {
          ..._pick(state.deliveryAddress, [
            'streetAddr1',
            'streetAddr2',
            'city',
            'zip',
            'state',
          ]),
          addressId: _get(state.deliveryAddress, 'id', null),
        }
    state.dependents = dependents
  },
  [types.INIT_FAILURE]: (state) => {
    state.meta.isLoading.init = false
    state.meta.error = true
  },
  [types.REWARD_BOX_ADD]: (state, rewardBox) => {
    state.selectedRewardBox = rewardBox
  },
  [types.REWARD_BOX_RESET_BASKET]: (state) => {
    state.selectedRewardBox = null
  },
  [types.REWARD_BOX_REDEEM_REQUEST]: (state) => {
    state.meta.isLoading.redeem = true
  },
  [types.REWARD_BOX_REDEEM_SUCCESS]: (state) => {
    state.meta.isLoading.redeem = false
    state.selectedRewardBox = null
  },
  [types.REWARD_BOX_REDEEM_FAILURE]: (state) => {
    state.meta.isLoading.redeem = false
    state.meta.error.redeem = true
  },
  [types.REWARDS_BASKET_ADD]: (
    state,
    {
      basket,
      remainingMoney,
      memberCoverageBalances = [],
      dependentCoverageBalances = {},
      directIncentiveBalances = [],
    }
  ) => {
    state.remainingMoney = remainingMoney
    state.basket = basket
    state.memberCoverageBalances = memberCoverageBalances
    state.directIncentiveBalances = directIncentiveBalances
    state.dependentCoverageBalances = dependentCoverageBalances
  },
  [types.REWARDS_BASKET_REMOVE]: (
    state,
    {
      basket,
      remainingMoney,
      memberCoverageBalances = [],
      directIncentiveBalances = [],
      dependentCoverageBalances = {},
    }
  ) => {
    state.remainingMoney = remainingMoney
    state.basket = basket
    state.memberCoverageBalances = memberCoverageBalances
    state.directIncentiveBalances = directIncentiveBalances
    state.dependentCoverageBalances = dependentCoverageBalances
  },
  [types.REWARDS_RESET_BASKET]: (state) => {
    state.basket = []
    state.memberCoverageBalances = state.pointSummary.map(
      ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
      }) => ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
      })
    )
    state.directIncentiveBalances = state.directIncentivePointSummary.map(
      ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
        healthActionId,
        healthActionCompletionId,
      }) => ({
        memberCoverageId,
        availablePoints,
        cohortIncentiveStrategyId,
        cohortIncentiveStrategyType,
        healthActionId,
        healthActionCompletionId,
      })
    )
    state.dependentCoverageBalances = state.dependents.reduce((acc, curr) => {
      acc[curr.memberId] = curr.summary.map(
        ({
          memberCoverageId,
          availablePoints,
          cohortIncentiveStrategyId,
          cohortIncentiveStrategyType,
        }) => ({
          memberCoverageId,
          availablePoints,
          cohortIncentiveStrategyId,
          cohortIncentiveStrategyType,
        })
      )
      return acc
    }, {})
    state.dependentForRedemption = undefined
  },
  [types.REWARDS_REDEEM_REQUEST]: (state) => {
    state.meta.isLoading.redeem = true
  },
  [types.REWARDS_REDEEM_SUCCESS]: (state) => {
    state.meta.isLoading.redeem = false
    state.basket = []
  },
  [types.REWARDS_REDEEM_FAILURE]: (state) => {
    state.meta.isLoading.redeem = false
    state.meta.error.redeem = true
  },
  [types.REWARDS_UPDATE_DELIVERY_ADDRESS]: (state, address) => {
    state.deliveryAddress = address
  },
  [types.SET_AUTO_REWARD_ADDRESS]: (state, address) => {
    state.autoRewardDeliveryAddress = address
  },
  [types.SELECT_AUTO_REWARD]: (state, { reward, type }) => {
    state.selectedAutoReward = { ...reward }
    state.selectedAutoReward.type = type
  },
  [types.RESET_SELECTED_AUTO_REWARD]: (state) => {
    state.selectedAutoReward = {}
  },
  [types.SETUP_AUTO_REWARDS_REQUEST]: (state) => {
    state.meta.isLoading.setup = true
    state.meta.error.setup = false
  },
  [types.SETUP_AUTO_REWARDS_SUCCESS]: (state) => {
    state.meta.isLoading.setup = false
    state.meta.error.setup = false
  },
  [types.SETUP_AUTO_REWARDS_FAILURE]: (state) => {
    state.meta.isLoading.setup = false
    state.meta.error.setup = true
  },
  [types.TURN_OFF_AUTO_REWARD_REQUEST]: (state) => {
    state.meta.isLoading.turnOff = true
    state.meta.error.turnOff = false
  },
  [types.TURN_OFF_AUTO_REWARD_SUCCESS]: (state) => {
    state.meta.isLoading.turnOff = false
    state.meta.error.turnOff = false
  },
  [types.TURN_OFF_AUTO_REWARD_FAILURE]: (state) => {
    state.meta.isLoading.turnOff = false
    state.meta.error.turnOff = true
  },
  [types.OPEN_CONFIRM_AUTO_REWARD]: (state) => {
    state.confirmAutoReward = true
  },
  [types.CLOSE_CONFIRM_AUTO_REWARD]: (state) => {
    state.confirmAutoReward = false
  },
  [types.SET_DEPENDENT_FOR_REDEMPTION]: (state, dependentId) => {
    state.dependentForRedemption = dependentId
  },

  [types.GET_INCENTIVE_CATALOG_REQUEST]: (state) => {
    state.meta.isLoading.incentiveCatalog = true
    state.meta.error.incentiveCatalog = false
  },
  [types.GET_INCENTIVE_CATALOG_SUCCESS]: (
    state,
    { incentiveCatalogCd, incentiveProducts }
  ) => {
    state.meta.isLoading.incentiveCatalog = false
    state.meta.error.incentiveCatalog = false
    Vue.set(state.incentiveCatalog, incentiveCatalogCd, incentiveProducts)
  },
  [types.GET_INCENTIVE_CATALOG_FAILURE]: (state) => {
    state.meta.isLoading.incentiveCatalog = false
    state.meta.error.incentiveCatalog = true
  },
}
