import state from './registration.state'
import mutations from './registration.mutations'
import actions from './registration.actions'
import getters from './registration.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
