import types from './app.types'
import initialState from './app.state'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },

  [types.CREATE_MESSAGE]: (state, payload) => {
    state.message.type = payload.type
    state.message.text = payload.message
  },

  [types.REMOVE_MESSAGE]: (state) => {
    state.message = initialState().message
  },

  [types.CREATE_SECURITY_MESSAGE]: (state, message) => {
    state.securityMessage = message
  },

  [types.REMOVE_SECURITY_MESSAGE]: (state) => {
    state.securityMessage = ''
  },

  [types.SAVE_POST_LOGIN_PATH]: (state, path) => {
    state.postLoginPath = path
  },
  [types.REMOVE_POST_LOGIN_PATH]: (state) => {
    state.postLoginPath = null
  },
}
