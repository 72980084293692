import api from './api'

/*
 * Get messages
 */
export const getMessages = () => {
  const getMessagesUrl = `/v2/${process.env.VUE_APP_DEVICE}/messages`
  return api()
    .url(getMessagesUrl)
    .get()
}

/*
 * Update message status
 */
export const updateMessageStatus = (messageId, status) => {
  const updateMessageUrl = `/v1/${process.env.VUE_APP_DEVICE}/messages/${messageId}`
  return api()
    .url(updateMessageUrl)
    .patch({ status })
}

/*
 * Get email subscriptions
 */
export const getEmailSubscriptions = () => {
  const subscriptionsUrl = `/v1/${process.env.VUE_APP_DEVICE}/messages/subscriptions`
  return api()
    .url(subscriptionsUrl)
    .get()
}

/*
 * Post message subscription comment
 */
export const createSubscriptionCommentWithToken = ({
  memberId,
  token,
  comment,
}) => {
  const baseUrl = `/v1/${process.env.VUE_APP_DEVICE}`
  const subscriptionsCommentUrl = `${baseUrl}/messages/subscriptions/memberId/${memberId}/token/${token}/comments`
  return api()
    .url(subscriptionsCommentUrl)
    .post({ comment })
}
