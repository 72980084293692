import { camelizeKeys, decamelizeKeys } from 'humps'

export const convertApiKeys = (key, convert) =>
  /^[A-Z0-9_:\-.]+$/.test(key) ? key : convert(key)

export const camelCaseKeys = (value) => camelizeKeys(value, convertApiKeys)

export const snakeCaseKeys = (value) => decamelizeKeys(value, convertApiKeys)

export const camelizeApiDataKeys = ({ data }) =>
  camelizeKeys(data, convertApiKeys)
