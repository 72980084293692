import types from './healthProfile.types'
import initialState from './healthProfile.state'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.MY_HEALTH_RISKS_REQUEST]: (state) => {
    state.meta.isLoading.myHealthRisks = true
  },
  [types.MY_HEALTH_RISKS_SUCCESS]: (state, { overallRisk, healthSummary }) => {
    state.meta.loadedOn = new Date()
    state.meta.isLoading.myHealthRisks = false
    state.meta.error = false
    state.overallRisk = overallRisk
    state.healthSummary = healthSummary
  },
  [types.MY_HEALTH_RISKS_FAILURE]: (state) => {
    state.meta.isLoading.myHealthRisks = false
    state.meta.error = true
  },

  [types.MY_PRESCRIPTIONS_REQUEST]: (state) => {
    state.meta.isLoading.getPrescriptionRecord = true
  },
  [types.MY_PRESCRIPTIONS_SUCCESS]: (state, prescriptions) => {
    state.meta.isLoading.getPrescriptionRecord = false
    state.meta.error = false
    state.prescriptions = prescriptions
  },
  [types.MY_PRESCRIPTIONS_FAILURE]: (state) => {
    state.meta.isLoading.getPrescriptionRecord = false
    state.meta.error = true
  },
}
