import differenceInMinutes from 'date-fns/differenceInMinutes'
import {
  SHOULD_LOAD_THRESHOLD,
  TRACKER_TYPES,
} from '@platform-shared/constants'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _toString from 'lodash/toString'
import _size from 'lodash/size'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import getOr from 'lodash/fp/getOr'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import isToday from 'date-fns/isToday'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import subDays from 'date-fns/subDays'

const getTrackingDisplayValue = (trackerTypeCode, trackerSummary) => {
  switch (trackerTypeCode) {
    case TRACKER_TYPES.bloodPressure:
      return `${trackerSummary.latestSystolic} / ${trackerSummary.latestDiastolic}`
    case TRACKER_TYPES.sleep:
      return _toString(parseFloat((trackerSummary.latest / 60).toFixed(1)))
  }
  return _toString(trackerSummary.latest)
}

const getNonManualSources = (trackerSummary) =>
  flow(
    getOr([], 'sources'),
    filter((source) => source.name !== 'manual')
  )(trackerSummary)

export default {
  shouldLoad: (state) => (now = new Date()) => {
    return (
      !state.meta.loadedOn ||
      differenceInMinutes(now, state.meta.loadedOn) > SHOULD_LOAD_THRESHOLD
    )
  },
  hasLoaded: (state) => !!state.meta.loadedOn,
  trackingInitLoading: (state) => state.meta.isLoading.init,
  trackingSummaryLoading: (state) => state.meta.isLoading.summary,
  humanApiTokenLoading: (state) => state.meta.isLoading.humanApiToken,
  trackingInitError: (state) => state.meta.error.init,
  trackingUpdateLoading: (state) => state.meta.isLoading.update,
  trackingSummaryError: (state) => state.meta.error.summary,
  humanApiTokenError: (state) => state.meta.error.humanApiToken,
  trackers: (state) => {
    if (_isEmpty(state.summary)) {
      return []
    }
    return _map(TRACKER_TYPES, (trackerTypeCode, key) => {
      const trackerSummary = state.summary[key]
      const { latestDate, latest: latestValue } = trackerSummary
      const trackerStatus = _find(state.settings, { type: trackerTypeCode })
      const recordedToday =
        !_isUndefined(trackerSummary.latestDate) &&
        isToday(parseISO(trackerSummary.latestDate))
      const todaysDisplayValue = recordedToday
        ? getTrackingDisplayValue(trackerTypeCode, trackerSummary)
        : '--'
      const isConnected = _size(getNonManualSources(trackerSummary)) > 0
      const incentives = _get(trackerStatus, 'incentives', [])
      return {
        incentives,
        latestDate,
        latestValue,
        recordedToday,
        todaysDisplayValue,
        isConnected,
        type: key,
        typeCode: trackerTypeCode,
      }
    })
  },
  trackerByType: (state, getters) => (type) =>
    _find(getters.trackers, { type }),
  historyByTracker: (state) => (tracker) => {
    return flow(
      getOr([], ['history', tracker]),
      orderBy(['date'], ['desc']),
      filter((reading) => parseISO(reading.date) > subDays(new Date(), 30)),
      map((reading) => {
        const typeCode = _get(TRACKER_TYPES, [tracker])

        const value =
          typeCode === TRACKER_TYPES.bloodPressure
            ? `${reading.value.systolic}/${reading.value.diastolic}`
            : typeCode === TRACKER_TYPES.sleep
            ? parseFloat((reading.value / 60).toFixed(1))
            : reading.value

        return {
          ...reading,
          value,
          date: format(parseISO(reading.date), 'MMMM d'),
        }
      })
    )(state)
  },
  humanApiToken: (state) => state.humanApiToken,
  sourcesForTrackerType: (state) => (type) =>
    _get(state, `summary.${type}.sources`),
}
