export default () => ({
  meta: {
    loadedOn: null,
    error: {
      init: false,
      search: false,
      article: false,
      brainExercise: false,
    },
    isLoading: {
      init: false,
      search: false,
      article: false,
      brainExercise: false,
    },
  },
  articles: [],
  articlesById: [],
  suggestedArticles: {},
  brainExercises: [],
  searchKeywords: '',
  searchShowing: false,
  searchedKeywords: '',
  searchResults: null,
})
