<template>
  <div>
    <button
      class="flex items-center focus:outline-none"
      :class="{
        'bg-white px-30 py-10 rounded-full shadow': floating,
      }"
      @click="toggleMenu"
    >
      <span class="mx-10 text-sm tracking-wide font-semibold">{{
        selectedLanguage.name
      }}</span>
      <Caret class="w-12 mt-5" />
    </button>
    <div
      v-if="showMenu"
      v-click-outside="toggleMenu"
      class="absolute bg-white shadow top-max px-20 py-10 z-10 text-nowrap"
      :class="{ '-mx-20': appHeader }"
    >
      <div
        v-for="(language, idx) in languages"
        :key="language.code"
        class="border-grey-200 px-30 flex items-center justify-center py-10 cursor-pointer"
        :class="{ 'border-b': idx < languages.length - 1 }"
        @click="selectLanguage(language.code)"
      >
        <span class="text-sm tracking-wide font-semibold">{{
          language.name
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { Caret } from '@assets/svgs'
import _find from 'lodash/find'

export default {
  components: { Caret },
  props: {
    floating: {
      type: Boolean,
      default: false,
    },
    appHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters('client', ['languages']),
    ...mapGetters('member', [
      'isAuthenticated',
      'preferredLanguageCd',
      'preAuthPreferredLanguageCd',
    ]),
    selectedLanguage() {
      return _find(this.languages, {
        code: this.$root.$i18n.locale,
      })
    },
  },
  methods: {
    ...mapActions('member', [
      'setPreAuthPreferredLanguage',
      'setPreferredLanguage',
    ]),
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    selectLanguage(languageCd) {
      if (this.isAuthenticated && this.preferredLanguageCd !== languageCd) {
        this.setPreferredLanguage(languageCd).then(() => {
            window.location.reload()
        })
      } else if (
        !this.isAuthenticated &&
        this.preAuthPreferredLanguageCd !== languageCd
      ) {
        this.setPreAuthPreferredLanguage(languageCd)
        this.$root.$i18n.locale = languageCd
      }
      this.$root.$i18n.locale = languageCd
      this.toggleMenu()
      this.updateFontStyleForLanguage();
    },
    updateFontStyleForLanguage() {
      // Get the font-size for the current localization
      const newFontSize = this.$root['$t']('config.fontSize');
      const newTextDirection = this.$root['$t']('config.textDirection');
      document.documentElement.style.setProperty('--base-font-size', `${newFontSize}px`);
      document.documentElement.style.setProperty('--base-text-direction', newTextDirection);
    }
  },
}
</script>
