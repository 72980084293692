import api from './api'

/**
 * get learn articles from the BFF
 */
export const initLearn = () => {
  const learn = `/v1/${process.env.VUE_APP_DEVICE}/learn`
  const url = `${learn}/init-learn`
  return api()
    .url(url)
    .get()
}

export const searchArticles = (keyword) => {
  const learn = `/v1/${process.env.VUE_APP_DEVICE}/learn`
  const url = `${learn}/articles/search?keyword=${keyword}`
  return api()
    .url(url)
    .get()
}

export const markArticleAsRead = (contentId) => {
  const learn = `/v1/${process.env.VUE_APP_DEVICE}/learn`
  const url = `${learn}/articles/mark-as-read/${contentId}`
  return api()
    .url(url)
    .get()
}
