<template>
  <FocusTrap v-if="active" name="modal" :active="active" tabindex="-1">
    <div class="fixed top-0 left-0 w-full h-full table bg-black-50 z-10 p-20">
      <div
        id="outside"
        class="table-cell align-middle"
        @click="closeOnBackDrop ? close($event) : null"
      >
        <div
          ref="modalContents"
          class="modal-container rounded-lg m-auto bg-white"
          role="dialog"
          aria-modal="true"
          aria-label="Confirm"
          @keydown.esc="leaveModal"
        >
          <slot />
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    closeOnBackDrop: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    active(isActive, wasActive) {
      if (!wasActive && isActive) {
        setTimeout(this.focusFirstElement, 1)
      }
    },
  },
  methods: {
    focusFirstElement() {
      const focusable = this.$refs.modalContents.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      if (focusable.length > 0) focusable[0].focus()
    },
    close(event) {
      if (event.target.id === 'outside') this.$emit('close')
    },
    leaveModal() {
      this.$emit('close')
    },
  },
}
</script>
<style>
.modal-container {
  width: fit-content;
  box-shadow: 0 2px 80px rgba(0, 0, 0, 0.5);
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
