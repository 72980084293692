export default {
  RESET_STATE: 'learn/reset_state',
  INVALIDATE: 'learn/invalidate',

  INIT_FAILURE: 'learn/failure',
  INIT_REQUEST: 'learn/request',
  INIT_SUCCESS: 'learn/success',

  LEARN_ARTICLE_FAILURE: 'learn/article/failure',
  LEARN_ARTICLE_REQUEST: 'learn/article/request',
  LEARN_ARTICLE_SUCCESS: 'learn/article/success',
  LEARN_ARTICLE_MARK_AS_READ: 'learn/article/mark_as_read',

  LEARN_BRAIN_EXERCISE_FAILURE: 'learn/brain_exercise/failure',
  LEARN_BRAIN_EXERCISE_REQUEST: 'learn/brain_exercise/request',
  LEARN_BRAIN_EXERCISE_SUCCESS: 'learn/brain_exercise/success',
  LEARN_BRAIN_EXERCISE_MARK_AS_PLAYED: 'learn/brain_exercise/mark_as_played',

  LEARN_SEARCH_FAILURE: 'learn/search/failure',
  LEARN_SEARCH_REQUEST: 'learn/search/request',
  LEARN_SEARCH_SUCCESS: 'learn/search/success',
  LEARN_SEARCH_UPDATE: 'learn/search/update',
  LEARN_SEARCH_START: 'learn/search/start',
  LEARN_SEARCH_END: 'learn/search/end',
}
