import clientConfig from '@platform-shared/utils/clientConfig'
import * as appSettings from '@nativescript/core/application-settings'

const API_ROOT_KEY = 'API_ROOT'

const API_ROOTS = {
  QA: {
    label: 'QA',
    value: `https://api.qa.healthmineops.com/pha/${clientConfig.ID}`,
    key: 'qa',
  },
  STAGE: {
    label: 'Stage',
    value: `https://api.stage.healthmineops.com/pha/${clientConfig.ID}`,
    key: 'stage',
  },
  PRODUCTION: {
    label: 'Production',
    value: `https://api2.healthmine.com/pha/${clientConfig.ID}`,
    key: 'production',
  },
}

const apiEnvironment = {
  save(env) {
    appSettings.setString(API_ROOT_KEY, env)
  },

  delete() {
    appSettings.remove(API_ROOT_KEY)
  },

  isSet() {
    return appSettings.hasKey(API_ROOT_KEY)
  },

  current() {
    return appSettings.hasKey(API_ROOT_KEY)
      ? API_ROOTS[appSettings.getString(API_ROOT_KEY)]
      : process.env.VUE_APP_API_ROOT
      ? API_ROOTS['QA']
      : API_ROOTS['PRODUCTION']
  },

  default() {
    return API_ROOTS['PRODUCTION'].value
  },
}

export default apiEnvironment
