import differenceInMinutes from 'date-fns/differenceInMinutes'
import { SHOULD_LOAD_THRESHOLD } from '@platform-shared/constants'
import filter from 'lodash/filter'
import find from 'lodash/find'

export default {
  shouldLoad: (state) => (now = new Date()) => {
    return (
      !state.meta.loadedOn ||
      differenceInMinutes(now, state.meta.loadedOn) > SHOULD_LOAD_THRESHOLD
    )
  },

  hasLoaded: (state) => !!state.meta.loadedOn,

  faqLoading: (state) => state.meta.isLoading,

  faqError: (state) => state.meta.error,

  categories: (state) => state.categories,

  items: (state) => state.items,

  itemsByCategoryId: (state, getters) => (id) =>
    filter(getters.items, { categoryId: id }),

  itemById: (state, getters) => (id) => find(getters.items, { id }),

  popularItems: (state, getters) => filter(getters.items, 'popular'),
}
