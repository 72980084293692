export default () => ({
  meta: {
    loadedOn: null,
    isLoading: false,
    error: false,
  },
  messages: [],
  messagesById: {},
  subscriptions: [],
})
