<template>
  <div id="app">
    <FlashMessage />
    <Spinner v-if="isLoading" />
    <div v-else>
      <ImpersonationBanner v-if="impersonationEmail" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Bugsnag from '@bugsnag/js'
import { TOKEN_REFRESH_DIFFERENCE } from '@platform-shared/constants'
import jwtDecode from 'jwt-decode'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import FlashMessage from './components/common/FlashMessage'
import ImpersonationBanner from './components/common/ImpersonationBanner'
import Spinner from './components/common/Spinner'
import _get from 'lodash/get'
import { getEnvironment } from './utils/appConfig'
import heapAnalytics from './utils/heap'
import { supportedLocale } from '@platform-shared/utils/locale'

export default {
  components: { FlashMessage, ImpersonationBanner, Spinner },
  computed: {
    ...mapGetters('member', [
      'accessToken',
      'isGettingMemberInfo',
      'impersonationEmail',
      'memberInfo',
      'isLoggingIn',
      'preferredLanguageCd',
      'preAuthPreferredLanguageCd',
      'isAuthenticated',
      'isUpdatingLanguagePref',
      'showSSORegistration',
    ]),
    ...mapGetters('client', {
      clientConfigLoading: 'loading',
      languages: 'languages',
    }),
    isLoading() {
      return (
        this.clientConfigLoading ||
        this.isGettingMemberInfo ||
        this.isUpdatingLanguagePref
      )
    },
  },
  watch: {
    accessToken(newToken, oldToken) {
      if (!newToken && oldToken) {
        this.$router.push({ name: 'login' })
      }
    },
    isAppIdle(newValue, oldValue) {
      if (newValue && !oldValue && this.accessToken) {
        this.forceLogout()
      }
    },
    isLoggingIn(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.gtmDataLayerPushMemberInfo()
        this.syncHeapUserIdentity()
      }
    },
    isGettingMemberInfo(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.gtmDataLayerPushMemberInfo()
        this.syncHeapUserIdentity()
        this.enhanceBugsnagConfig()
        if (!this.preferredLanguageCd) {
          const languageCd = supportedLocale(
            this.preAuthPreferredLanguageCd || window.navigator.language,
            this.languages
          )
          this.setPreferredLanguage(languageCd)
          this.$root.$i18n.locale = languageCd
        } else {
          this.$root.$i18n.locale = this.preferredLanguageCd
        }
        this.updateFontStyleForLanguage();
        if (this.showSSORegistration) {
          this.$router.replace('/register-sso')
        }
      }
    },
    clientConfigLoading(newValue, oldValue) {
      if (!newValue && oldValue && !this.isAuthenticated) {
        const languageCd = supportedLocale(
          window.navigator.language,
          this.languages
        )
        this.setPreAuthPreferredLanguage(languageCd)
        this.$root.$i18n.locale = languageCd
      }
    }
  },
  created() {
    this.handleSavedToken()
  },
  methods: {
    ...mapActions(['logout', 'forceLogout']),
    ...mapActions('member', [
      'getMemberInfo',
      'setPreAuthPreferredLanguage',
      'setPreferredLanguage',
    ]),
    ...mapActions('client', ['getClientConfig']),
    enhanceBugsnagConfig() {
      const environment = getEnvironment()
      const memberId = _get(this.memberInfo, 'id')
      if (environment && memberId) {
        Bugsnag.addMetadata('user', {
          // eslint-disable-next-line camelcase
          hm_member_id: memberId,
        })
      }
    },
    gtmDataLayerPushMemberInfo() {
      const memberId = _get(this.memberInfo, 'id')
      const clientId = _get(this.memberInfo, 'client.id')
      const environment = getEnvironment()
      if (!!memberId && !!clientId) {
        window.dataLayer.push({
          userId: memberId,
          clientId: clientId,
          environment,
          applicationPlatform: 'PHA/WEB',
        })
      }
    },
    syncHeapUserIdentity() {
      heapAnalytics.setMemberIdentity(this.memberInfo)
    },
    handleSavedToken() {
      if (!this.accessToken) return

      if (
        differenceInMinutes(
          jwtDecode(this.accessToken).exp * 1000,
          Date.now()
        ) < TOKEN_REFRESH_DIFFERENCE
      ) {
        return this.logout()
      }

      this.getMemberInfo().then(() => {
        this.getClientConfig()
      })
    },
    updateFontStyleForLanguage() {
      // Get the font-size for the current localization
      const newFontSize = this.$root['$t']('config.fontSize');
      const newTextDirection = this.$root['$t']('config.textDirection');
      document.documentElement.style.setProperty('--base-font-size', `${newFontSize}px`);
      document.documentElement.style.setProperty('--base-text-direction', newTextDirection);
    }
  },
}
</script>

<style src="./styles/styles.css"></style>
