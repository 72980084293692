import types from './surveys.types'
import api from '@platform-shared/services/api'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  getMemberSurveyWithSignature(
    { commit },
    { memberId, surveyId, responseId, signature }
  ) {
    commit(types.GET_SURVEY_REQUEST)

    return new Promise((resolve, reject) => {
      const getMemberSurveyUrl = `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${memberId}/surveys/${surveyId}?responseId=${responseId}&signature=${signature}`
      api()
        .url(getMemberSurveyUrl)
        .get()
        .then((res) => {
          commit(types.GET_SURVEY_SUCCESS)
          resolve(res)
        })
        .catch((error) => {
          commit(types.GET_SURVEY_FAILURE)
          reject(error)
        })
    })
  },
}
