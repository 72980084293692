/* eslint-disable camelcase */
import types from './currentQuestionnaire.types'
import fp_flow from 'lodash/fp/flow'
import fp_get from 'lodash/fp/get'
import fp_map from 'lodash/fp/map'
import fp_includes from 'lodash/fp/includes'
import fp_getOr from 'lodash/fp/getOr'
import fp_filter from 'lodash/fp/filter'
import fp_reverse from 'lodash/fp/reverse'
import _get from 'lodash/get'

export default {
  resetState({ commit }) {
    commit(types.RESET)
  },

  activate({ commit, dispatch, rootGetters }, { id, type }) {
    dispatch('resetState')

    const { myId } = rootGetters['questionnaire/questionnaireById'](id)
    const groups = rootGetters['questionnaire/groupsForQuestionnaire'](id)
    const answers = rootGetters['questionnaire/answersForQuestionnaire'](id)
    const answerKeys = Object.keys(answers)
    const questions = rootGetters['questionnaire/questionsForQuestionnaire'](id)
    const age = rootGetters['member/age']
    const memberGender = rootGetters['member/gender']

    // get the displayOrder value of the next question that meets the conditions/requirements
    const currentGroupId = fp_flow(
      fp_map((question) => {
        const requirements = fp_flow(
          fp_getOr('{}', 'otherSelectors'),
          JSON.parse,
          fp_getOr([], 'requiredAnswers')
        )(question)
        return { ...question, requirements }
      }),
      fp_filter(
        ({ minAge, maxAge, gender, requirements, id }) =>
          !answerKeys.includes(id) &&
          (requirements.length > 0
            ? requirements.some((condition) => {
                const { questionId, answer } = condition
                return fp_flow(
                  fp_get(questionId),
                  fp_map('answer'),
                  fp_includes(answer)
                )(answers)
              })
            : true) &&
          (minAge == null || minAge <= age) &&
          (maxAge == null || maxAge >= age) &&
          (gender == null ||
            memberGender ===
              gender
                .trim()
                .charAt(0)
                .toUpperCase())
      ),
      fp_getOr('1', [0, 'displayOrder'])
    )(questions).toString()

    commit(types.ACTIVATE, { id, myId, groups, currentGroupId, type, answers })
  },

  start({ commit, getters, dispatch }) {
    const questionnaire = getters.questionnaire

    if (getters.started) {
      return commit(types.BEGIN)
    }

    dispatch('questionnaire/start', questionnaire, { root: true }).then(
      (questionnaire) => {
        commit(types.SET_ID, questionnaire.id)
        commit(types.BEGIN)
      }
    )
  },

  skip({ dispatch }) {
    dispatch('resetState')
  },

  updateRunningAnswers({ commit }, val) {
    commit(types.UPDATE_RUNNING_ANSWERS, val)
  },

  answerQuestions({ dispatch, getters }, answers) {
    const { id, myId } = getters.ids

    return dispatch(
      'questionnaire/answerQuestions',
      { answers, id, myId },
      { root: true }
    ).then(() => dispatch('goNext'))
  },

  goNext({ commit, getters, dispatch, rootGetters }) {
    const groupKeys = Object.keys(getters.groups)
    const currentGroupIndex = groupKeys.findIndex(
      (key) => key === getters.currentGroupId
    )

    const { id } = getters.ids
    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']
    const answers = rootGetters['questionnaire/answersForQuestionnaire'](id)
    const questions = rootGetters['questionnaire/questionsForQuestionnaire'](id)

    // get the displayOrder value of the next question that meets the conditions/requirements
    const nextDisplayOrder = fp_flow(
      fp_filter(
        (question) => _get(question, 'displayOrder') >= currentGroupIndex + 2
      ),
      fp_map((question) => {
        const requirements = fp_flow(
          fp_getOr('{}', 'otherSelectors'),
          JSON.parse,
          fp_getOr([], 'requiredAnswers')
        )(question)
        return { ...question, requirements }
      }),
      fp_filter(({ minAge, maxAge, gender, requirements }) =>
        requirements.length > 0
          ? requirements.some((condition) => {
              const { questionId, answer } = condition
              return fp_flow(
                fp_get(questionId),
                fp_map('answer'),
                fp_includes(answer)
              )(answers)
            })
          : true &&
            (minAge == null || minAge <= age) &&
            (maxAge == null || maxAge >= age) &&
            (gender == null ||
              memberGender ===
                gender
                  .trim()
                  .charAt(0)
                  .toUpperCase())
      ),
      fp_getOr(-1, [0, 'displayOrder'])
    )(questions)

    const nextGroupIndex =
      nextDisplayOrder !== -1 ? nextDisplayOrder - 1 : currentGroupIndex

    if (nextDisplayOrder === -1) {
      return dispatch('finish')
    }

    const nextGroup = groupKeys[nextGroupIndex]
    commit(types.SET_CURRENT_GROUP, nextGroup)
  },

  goBack({ commit, getters, rootGetters, dispatch }) {
    const groupKeys = Object.keys(getters.groups)
    const currentGroupIndex = groupKeys.findIndex(
      (key) => key === getters.currentGroupId
    )

    if (!currentGroupIndex) return

    const { id } = getters.ids
    const memberGender = rootGetters['member/gender']
    const age = rootGetters['member/age']
    const answers = rootGetters['questionnaire/answersForQuestionnaire'](id)
    const questions = rootGetters['questionnaire/questionsForQuestionnaire'](id)

    // get the displayOrder value of the previous question that meets the conditions/requirements
    const prevDisplayOrder = fp_flow(
      fp_filter(
        (question) => _get(question, 'displayOrder') < currentGroupIndex + 1
      ),
      fp_map((question) => {
        const requirements = fp_flow(
          fp_getOr('{}', 'otherSelectors'),
          JSON.parse,
          fp_getOr([], 'requiredAnswers')
        )(question)
        return { ...question, requirements }
      }),
      fp_filter(({ minAge, maxAge, gender, requirements }) =>
        requirements.length > 0
          ? requirements.some((condition) => {
              const { questionId, answer } = condition
              return fp_flow(
                fp_get(questionId),
                fp_map('answer'),
                fp_includes(answer)
              )(answers)
            })
          : true &&
            (minAge == null || minAge <= age) &&
            (maxAge == null || maxAge >= age) &&
            (gender == null ||
              memberGender ===
                gender
                  .trim()
                  .charAt(0)
                  .toUpperCase())
      ),
      fp_reverse,
      fp_getOr(-1, [0, 'displayOrder'])
    )(questions)

    const prevGroupIndex =
      prevDisplayOrder !== -1 ? prevDisplayOrder - 1 : currentGroupIndex

    const prevGroup = groupKeys[prevGroupIndex]
    dispatch('questionnaire/resetErrorState', null, { root: true })
    commit(types.SET_CURRENT_GROUP, prevGroup)
  },

  finish({ commit, getters, dispatch }) {
    const { myId, id } = getters.ids
    const questionnaireType = getters.questionnaireType

    return dispatch(
      'questionnaire/finish',
      { myId, id, questionnaireType },
      { root: true }
    ).then(() => commit(types.FINISH))
  },

  endProcessing({ commit }) {
    commit(types.END_PROCESSING)
  },

  complete({ dispatch }) {
    dispatch('resetState')
    dispatch('questionnaire/resetErrorState', null, { root: true })
  },
}
