import jwtDecode from 'jwt-decode'
import _get from 'lodash/get'
import {
  getRefreshToken,
  saveRefreshToken,
} from '@platform-shared/utils/refreshToken'
import * as memberService from '@platform-shared/services/member'
import tokenService from '@platform-shared/utils/tokenService'
import { isMobile } from '@platform-shared/utils/device'
import types from './member.types'
import rememberTokenService from '@platform-shared/utils/rememberToken'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },

  agreements({ commit }, agreements) {
    commit(types.INIT_AGREEMENTS_SUCCESS, agreements)
  },

  disclaimers({ commit }, disclaimers) {
    commit(types.GET_DISCLAIMERS_SUCCESS, disclaimers)
  },

  createAgreement({ commit }, agreementTag) {
    commit(types.CREATE_AGREEMENT_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .createAgreement(agreementTag)
        .then((res) => {
          commit(types.CREATE_AGREEMENT_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.CREATE_AGREEMENT_FAILURE)
          reject(error)
        })
    })
  },

  initialData({ commit, dispatch }, data) {
    const {
      questionnaires,
      messages,
      agreements,
      disclaimers,
      activity,
      ...rest
    } = data

    dispatch('saveMemberInfo', rest)
    dispatch('app/removeSecurityMessage', null, { root: true })
    dispatch('agreements', agreements)
    dispatch('disclaimers', disclaimers)
    commit(types.INIT_ACTIVITY_SUCCESS, activity)
    dispatch('questionnaire/saveQuestionnaires', questionnaires, {
      root: true,
    })
    dispatch('inbox/saveMessages', messages, { root: true })
  },

  login({ commit, dispatch }, creds) {
    commit(types.LOGIN_REQUEST)

    return new Promise((resolve, reject) => {
      const rememberToken = rememberTokenService.get()
      memberService
        .login(creds, rememberToken)
        .then((res) => {
          const { memberId, jwt, refreshToken, mfaType } = res.data
          dispatch('storeToken', jwt)
          if (isMobile && refreshToken) {
            saveRefreshToken(refreshToken)
          }

          dispatch('loginSuccess', { memberId, mfaType })
          resolve()
        })
        .catch((error) => {
          commit(types.LOGIN_FAILURE)
          reject(error)
        })
    })
  },

  loginSuccess({ commit }, { memberId, mfaType = '' }) {
    commit(types.LOGIN_SUCCESS, { memberId, mfaType })
  },

  loginFromAuthCode({ dispatch }, { jwt, refreshToken }) {
    dispatch('storeToken', jwt)
    dispatch('storeRefreshToken', refreshToken)
  },

  loginWithRefreshToken({ commit, dispatch }) {
    const refreshToken = getRefreshToken()

    commit(types.LOGIN_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .loginWithRefreshToken(refreshToken)
        .then((res) => {
          const { memberId, mfaType, jwt } = res.data
          dispatch('storeToken', jwt)
          dispatch('loginSuccess', { memberId, mfaType })
          resolve()
        })
        .catch((error) => {
          dispatch('secureLogout', 'common.error.refresh_token_failure', {
            root: true,
          })
          reject(error)
        })
    })
  },

  getMemberInfo({ commit, dispatch, getters }, args) {
    const fromLoginScreen = _get(args, 'fromLoginScreen', false)
    commit(types.MEMBER_INFO_REQUEST, fromLoginScreen)
    const impersonatedMemberId = getters.impersonatedMemberId

    return new Promise((resolve, reject) => {
      memberService
        .getMemberInfo(impersonatedMemberId)
        .then((res) => {
          dispatch('initialData', res.data)
          commit(types.MEMBER_INFO_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.MEMBER_INFO_FAILURE)
          reject(error)
        })
    })
  },

  saveMemberInfo({ commit }, data) {
    commit(types.SAVE_MEMBER_INFO, data)
  },

  renewJwt({ dispatch }) {
    memberService
      .renewJwt()
      .then((res) => {
        dispatch('storeToken', res.data.jwt)
      })
      .catch(() => dispatch('logout', null, { root: true }))
  },

  renewJwtFromRefreshToken({ dispatch }) {
    if (isMobile) {
      const refreshToken = getRefreshToken()
      refreshToken &&
        memberService
          .renewJwtFromRefreshToken(refreshToken)
          .then((res) => {
            dispatch('storeToken', res.data.jwt)
          })
          .catch(() => dispatch('forceLogout', null, { root: true }))
    }
  },

  storeToken({ commit, dispatch }, jwt) {
    tokenService.saveToken(jwt)

    const { exp, hm_login_type: loginType } = jwtDecode(jwt)
    commit(types.STORE_TOKEN, { jwt, exp, loginType })

    const timeoutDelay = tokenService.getTokenRefreshInterval(exp)

    if (timeoutDelay > 0) {
      setTimeout(() => {
        const jwtIsExpired = new Date(exp * 1000) <= new Date()
        if (jwtIsExpired) {
          dispatch('renewJwtFromRefreshToken')
        } else {
          dispatch('renewJwt')
        }
      }, timeoutDelay)
    } else {
      dispatch('renewJwtFromRefreshToken')
    }
  },

  storeRefreshToken(_, token) {
    saveRefreshToken(token)
  },
  updateContactInfo({ commit }, contactInfo) {
    commit(types.CONTACT_INFO_UPDATE_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .updateContactInfo(contactInfo)
        .then((res) => {
          commit(types.CONTACT_INFO_UPDATE_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.CONTACT_INFO_UPDATE_FAILURE)
          reject(error)
        })
    })
  },
  updateAddress({ commit }, address) {
    commit(types.ADDRESS_UPDATE_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .updateAddress(address)
        .then((res) => {
          commit(types.ADDRESS_UPDATE_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.ADDRESS_UPDATE_FAILURE)
          reject(error)
        })
    })
  },
  updateEthnography({ commit }, ethnography) {
    commit(types.ETHNOGRAPHY_UPDATE_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .updateEthnography(ethnography)
        .then((res) => {
          commit(types.ETHNOGRAPHY_UPDATE_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.ETHNOGRAPHY_UPDATE_FAILURE)
          reject(error)
        })
    })
  },
  updatePassword({ commit }, { oldPassword, newPassword }) {
    commit(types.PASSWORD_UPDATE_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .updatePassword({ oldPassword, newPassword })
        .then(() => {
          commit(types.PASSWORD_UPDATE_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.PASSWORD_UPDATE_FAILURE)
          reject(error)
        })
    })
  },
  requestMfaCode({ commit }) {
    commit(types.REQUEST_MFA_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .requestMfaCode()
        .then(() => {
          commit(types.REQUEST_MFA_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REQUEST_MFA_FAILURE)
          reject(error)
        })
    })
  },
  verifyMfaCode({ commit, dispatch }, totp) {
    commit(types.VERIFY_MFA_CODE_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .verifyMfaCode(totp)
        .then((res) => {
          const { jwt, refreshToken, rememberToken } = res.data
          dispatch('storeToken', jwt)
          if (isMobile && refreshToken) {
            saveRefreshToken(refreshToken)
          }
          rememberTokenService.save(rememberToken)
          commit(types.VERIFY_MFA_CODE_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.VERIFY_MFA_CODE_FAILURE)
          reject(error)
        })
    })
  },
  setPreAuthPreferredLanguage({ commit }, languageCd) {
    commit(types.SET_PREFERRED_LANGUAGE_PRE_AUTH, languageCd)
  },
  setPreferredLanguage({ commit }, languageCd) {
    /*
    optimistically setting preferred language state ahead of async to allow
    other page init calls to use correct language code before the member
    language preference is updated on the backend. Can re-eval promise chain if
    this becomes too much of a code smell
    */
    commit(types.SET_PREFERRED_LANGUAGE, languageCd)
    return new Promise((resolve, reject) => {
      commit(types.PREFERRED_LANGUAGE_REQUEST)
      memberService
        .preferredLanguage(languageCd)
        .then(() => {
          commit(types.PREFERRED_LANGUAGE_SUCCESS, languageCd)
          resolve()
        })
        .catch((error) => {
          commit(types.PREFERRED_LANGUAGE_FAILURE)
          reject(error)
        })
    })
  },
  createOrUpdateCommunicationPreferences({ commit }, communicationPreferences) {
    return new Promise((resolve, reject) => {
      commit(types.NOTIFICATION_SETTINGS_REQUEST)

      memberService
        .createOrUpdateCommunicationPreferences(communicationPreferences)
        .then((data) => {
          commit(types.NOTIFICATION_SETTINGS_SUCCESS, data.data)
          resolve()
        })
        .catch(() => {
          commit(types.NOTIFICATION_SETTINGS_FAILURE)
          reject()
        })
    })
  },
  unsubscribeFromEmailWithToken(
    { commit },
    { communicationPreferences, token, memberId, outreachType, outreachId }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.UNSUBSCRIBE_REQUEST)

      memberService
        .unsubscribeFromEmailPreferenceWithToken(
          communicationPreferences,
          token,
          memberId,
          outreachType,
          outreachId
        )
        .then((data) => {
          commit(types.UNSUBSCRIBE_SUCCESS, data.data)
          resolve()
        })
        .catch(() => {
          commit(types.UNSUBSCRIBE_FAILURE)
          reject()
        })
    })
  },
  unregisterMember({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit(types.UNREGISTER_MEMBER_REQUEST)
      memberService
        .unregisterMember()
        .then(() => {
          commit(types.UNREGISTER_MEMBER_SUCCESS)
          rememberTokenService.remove()
          dispatch('forceLogout', null, { root: true })
          resolve()
        })
        .catch(() => {
          commit(types.UNREGISTER_MEMBER_FAILURE)
          reject()
        })
    })
  },
  updateUtmParams({ commit }, params) {
    commit(types.SET_UTM_PARAMETERS, params)
  },
}
