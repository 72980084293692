import subMinutes from 'date-fns/subMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { TOKEN_REFRESH_DIFFERENCE } from '@platform-shared/constants'
import _get from 'lodash/get'

const SIGN_IN_RESULT = 'signInResult'
const SSO_JWT = 'sso_jwt'

const setSignInResult = (value) =>
  sessionStorage.setItem(SIGN_IN_RESULT, JSON.stringify(value))

const getSignInResult = () => {
  const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
    let [key, value] = cookie.split('=')
    cookies[key.trim()] = value
    return cookies
  }, {})

  const domain = location.hostname
    .split('.')
    .slice(0)
    .slice(-2)
    .join('.')

  const accessToken = _get(cookies, SSO_JWT)
  if (accessToken) {
    setSignInResult({ accessToken })
  }

  document.cookie = `${SSO_JWT}=;domain=.${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`
  return JSON.parse(sessionStorage.getItem(SIGN_IN_RESULT))
}

export default {
  getToken() {
    return getSignInResult()
  },

  saveToken(accessToken) {
    const currentSignInResult = getSignInResult()

    const updatedSignInResult = {
      ...currentSignInResult,
      accessToken,
    }

    return setSignInResult(updatedSignInResult)
  },

  removeToken() {
    return sessionStorage.removeItem(SIGN_IN_RESULT)
  },

  getTokenRefreshInterval(exp) {
    const expirationDate = new Date(exp * 1000)
    const triggerRefreshDate = subMinutes(
      expirationDate,
      TOKEN_REFRESH_DIFFERENCE
    )

    return differenceInMinutes(triggerRefreshDate, Date.now()) * 60 * 1000
  },
}
