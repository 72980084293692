import types from './currentQuestionnaire.types'
import initialState from './currentQuestionnaire.state'

export default {
  [types.RESET]: (state) => {
    Object.assign(state, initialState())
  },

  [types.ACTIVATE]: (
    state,
    { id, myId, groups, currentGroupId, type, answers }
  ) => {
    state.id = id
    state.myId = myId
    state.groups = groups
    state.currentGroupId = currentGroupId
    state.type = type
    state.runningAnswers = answers
  },

  [types.BEGIN]: (state) => {
    state.inProgress = true
  },

  [types.SET_ID]: (state, id) => {
    state.myId = id
  },

  [types.UPDATE_RUNNING_ANSWERS]: (state, { question, answer }) => {
    state.runningAnswers = {
      ...state.runningAnswers,
      [question]: answer.map((a) => ({ answer: a })),
    }
  },

  [types.FINISH]: (state) => {
    state.inProgress = false
    state.processing = true
  },

  [types.SET_CURRENT_GROUP]: (state, id) => {
    state.currentGroupId = id
  },

  [types.END_PROCESSING]: (state) => {
    state.processing = false
  },
}
