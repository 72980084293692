export default {
  RESET_STATE: 'health_profile/reset_state',

  MY_HEALTH_RISKS_REQUEST: 'health_profile/my_health_risks/request',
  MY_HEALTH_RISKS_SUCCESS: 'health_profile/my_health_risks/success',
  MY_HEALTH_RISKS_FAILURE: 'health_profile/my_health_risks/failure',

  MY_PRESCRIPTIONS_REQUEST: 'health_profile/my_prescriptions/request',
  MY_PRESCRIPTIONS_SUCCESS: 'health_profile/my_prescriptions/success',
  MY_PRESCRIPTIONS_FAILURE: 'health_profile/my_prescriptions/failure',
}
