import api from './api'

const endpoints = {
  questionnaires: `/v1/${process.env.VUE_APP_DEVICE}/questionnaire`,
  questionnairesByType: (type) =>
    `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${type}`,
  questionnaireFinish: (id) =>
    `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${id}/finish`,
  questionnaireAnswers: (id) =>
    `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${id}/answers`,
  questionnaireRefuse: (type) =>
    `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${type}/refuse`,
}

export const getQuestionnaires = () =>
  api()
    .url(endpoints.questionnaires)
    .get()

export const getQuestionnairesByType = (type) =>
  api()
    .url(endpoints.questionnairesByType(type))
    .get()

export const start = (type) =>
  api()
    .url(endpoints.questionnaires)
    .post({ type })

export const finish = (id) =>
  api()
    .url(endpoints.questionnaireFinish(id))
    .post()

export const answerQuestions = (questionnaire, answers) =>
  api()
    .url(endpoints.questionnaireAnswers(questionnaire))
    .post({ answers })

export const refuse = (type) =>
  api()
    .url(endpoints.questionnaireRefuse(type))
    .post({ type })
