import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import types from './learn.types'
import initialState from './learn.state'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.INVALIDATE]: (state) => {
    state.meta.loadedOn = null
  },
  [types.INIT_REQUEST]: (state) => {
    state.meta.isLoading.init = true
  },
  [types.INIT_SUCCESS]: (state, data) => {
    state.meta.isLoading.init = false
    state.meta.loadedOn = new Date()
    state.articlesById = data.userArticlesById
    state.articles = data.userArticleIds
    state.suggestedArticles = data.suggestedArticles
    state.brainExercises = data.brainExercises
  },
  [types.INIT_FAILURE]: (state) => {
    state.meta.isLoading.init = false
    state.meta.error = true
  },

  [types.LEARN_ARTICLE_REQUEST]: (state) => {
    state.meta.isLoading.article = true
  },
  [types.LEARN_ARTICLE_SUCCESS]: (state) => {
    state.meta.isLoading.article = false
  },
  [types.LEARN_ARTICLE_MARK_AS_READ]: (state, contentId) => {
    if (!isEmpty(state.articlesById[contentId])) {
      Vue.set(
        state.articlesById[contentId],
        'completionDate',
        new Date().toISOString().slice(0, 10)
      )
    }
  },
  [types.LEARN_ARTICLE_FAILURE]: (state) => {
    state.meta.isLoading.article = false
    state.meta.error.article = true
  },

  [types.LEARN_BRAIN_EXERCISE_REQUEST]: (state) => {
    state.meta.isLoading.brainExercise = true
  },
  [types.LEARN_BRAIN_EXERCISE_SUCCESS]: (state) => {
    state.meta.isLoading.brainExercise = false
  },
  [types.LEARN_BRAIN_EXERCISE_MARK_AS_PLAYED]: (state, exerciseCd) => {
    const exercise = flow(find({ exerciseCd: exerciseCd }))(
      state.brainExercises
    )
    if (exercise) {
      Vue.set(exercise, 'completionDate', new Date().toISOString().slice(0, 10))
    }
  },
  [types.LEARN_BRAIN_EXERCISE_FAILURE]: (state) => {
    state.meta.isLoading.brainExercise = false
    state.meta.error.brainExercise = true
  },

  [types.LEARN_SEARCH_REQUEST]: (state) => {
    state.meta.isLoading.search = true
  },
  [types.LEARN_SEARCH_SUCCESS]: (state, data) => {
    state.meta.isLoading.search = false
    state.meta.error.search = false
    state.searchedKeywords = state.searchKeywords
    state.searchResults = !isEmpty(data) ? data : []
  },
  [types.LEARN_SEARCH_FAILURE]: (state) => {
    state.meta.isLoading.search = false
    state.meta.error.search = true
  },
  [types.LEARN_SEARCH_UPDATE]: (state, value) => {
    state.searchKeywords = value
  },
  [types.LEARN_SEARCH_START]: (state) => {
    state.searchShowing = true
  },
  [types.LEARN_SEARCH_END]: (state) => {
    state.searchShowing = false
    state.searchKeywords = ''
    state.searchedKeywords = ''
    state.meta.isLoading.search = false
    state.meta.error.search = false
    state.searchResults = null
  },
}
