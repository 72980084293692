import * as healthProfileService from '@platform-shared/services/healthProfile'
import types from './healthProfile.types'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  getMyHealthRisks({ commit }) {
    commit(types.MY_HEALTH_RISKS_REQUEST)

    return new Promise((resolve, reject) => {
      healthProfileService
        .getMyHealthRisks()
        .then((res) => {
          commit(types.MY_HEALTH_RISKS_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.MY_HEALTH_RISKS_FAILURE)
          reject(error)
        })
    })
  },
  getMyPrescriptions({ commit }) {
    commit(types.MY_PRESCRIPTIONS_REQUEST)

    return new Promise((resolve, reject) => {
      healthProfileService
        .getMyPrescriptions()
        .then((res) => {
          commit(types.MY_PRESCRIPTIONS_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.MY_PRESCRIPTIONS_FAILURE)
          reject(error)
        })
    })
  },
}
