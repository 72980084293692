import differenceInMinutes from 'date-fns/differenceInMinutes'
import parseISO from 'date-fns/parseISO'
import { isWithinDateInterval } from '@platform-shared/utils/dateHelpers'
import {
  SHOULD_LOAD_THRESHOLD,
  QUESTIONNAIRE_STATUS,
  HEALTH_ACTION_CODES,
  QUESTIONNAIRE_TYPES,
} from '@platform-shared/constants'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import head from 'lodash/fp/head'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import _find from 'lodash/find'
import sumBy from 'lodash/fp/sumBy'
import _map from 'lodash/map'
import partition from 'lodash/fp/partition'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _size from 'lodash/size'
import _flatMap from 'lodash/flatMap'
import _some from 'lodash/some'
import _orderBy from 'lodash/orderBy'
import _sum from 'lodash/sum'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import isAfter from 'date-fns/isAfter'
import maxDate from 'date-fns/max'
import isValidDate from 'date-fns/isValid'

const enhanceSubscriberCompletions = (completions, code) =>
  code === HEALTH_ACTION_CODES.COMPLETE_HEALTH_QUESTIONNAIRE &&
  _isEmpty(completions)
    ? [{ completionDate: new Date().toISOString() }]
    : completions
export default {
  shouldLoad: (state) => (now = new Date()) => {
    return (
      !state.meta.loadedOn ||
      differenceInMinutes(now, state.meta.loadedOn) > SHOULD_LOAD_THRESHOLD
    )
  },
  hasLoaded: (state) => !!state.meta.loadedOn,
  healthActionsLoading: (state) => {
    return state.meta.isLoading
  },
  healthActionsError: (state) => {
    return state.meta.error
  },

  allHealthActions: (state, getters, rootState, rootGetters) => {
    const {
      memberId: subscriberMemberId,
      currentCompliancePeriod,
    } = rootGetters['member/memberCoverageInfo']
    if (_isUndefined(currentCompliancePeriod)) {
      return []
    }
    const { startDate, endDate } = currentCompliancePeriod
    const currentCompliancePeriodIds = currentCompliancePeriod.cohortIncentiveStrategies.map(
      (x) => x.compliancePeriodId
    )
    const getMemberNameById = rootGetters['member/getMemberNameById']
    const currentRewards = rootGetters['rewards/currentStrategyRewardsHistory']

    const healthActions = _map(state.items, ({ meta, members }) => {
      const subscriberHealthAction = { ..._get(members, subscriberMemberId) }

      const memberHealthActions = _map(members, (memberHealthAction) => ({
        ...memberHealthAction,
        name: getMemberNameById(memberHealthAction.memberId),
      }))

      const [completedMembers, toDoMembers] = flow(
        partition(({ memberId, compliancePeriods, completions = [] }) => {
          const isCompliantThisPeriod =
            _some(
              compliancePeriods,
              (x) =>
                x.isCompliant === 1 &&
                _some(currentCompliancePeriodIds, (y) => y === x.id)
            ) && !meta.nextDueDateOffsetDayCount

          // check questionnaire health actions tied to subscriber
          if (memberId === subscriberMemberId) {
            const isQuestionniareHAComplete = (type) => {
              const questionnaire = rootGetters[
                'questionnaire/questionnaireByType'
              ](type)
              const questionnaireStatus = get('status', questionnaire)
              const questionnaireLastCompletedDate = parseISO(
                get('lastCompleted', questionnaire)
              )

              const healthActionLastCompletedDate = flow(
                orderBy(['completionDate'], ['desc']),
                head,
                getOr(null, 'completionDate'),
                parseISO
              )(completions)

              const offsetNextDueDate = flow(
                filter(isValidDate),
                maxDate,
                (date) => add(date, { years: 1 }),
                (date) => sub(date, { days: meta.nextDueDateOffsetDayCount }),
                (date) => (isValidDate(date) ? date : null)
              )([healthActionLastCompletedDate, questionnaireLastCompletedDate])

              const isComplete = meta.nextDueDateOffsetDayCount
                ? isAfter(offsetNextDueDate, new Date())
                : questionnaireStatus === QUESTIONNAIRE_STATUS.COMPLETED &&
                  isWithinDateInterval(get('lastCompleted', questionnaire), {
                    start: startDate,
                    end: endDate,
                  })
              const isRefused =
                questionnaireStatus === QUESTIONNAIRE_STATUS.REFUSED

              return isComplete || isRefused
            }

            switch (meta.code) {
              case HEALTH_ACTION_CODES.COMPLETE_HEALTH_QUESTIONNAIRE:
                return (
                  isCompliantThisPeriod ||
                  isQuestionniareHAComplete(QUESTIONNAIRE_TYPES.HRA)
                )
              case HEALTH_ACTION_CODES.HEALTH_PLAN_FEEDBACK_SURVEY:
                return isQuestionniareHAComplete(QUESTIONNAIRE_TYPES.MOCK_CAHPS)
            }
          }

          return isCompliantThisPeriod
        })
      )(memberHealthActions)

      const subscriberWasRewarded = !!_find(currentRewards, {
        healthActionCd: meta.code,
      })

      const subscriberCompletions = enhanceSubscriberCompletions(
        _get(subscriberHealthAction, 'completions', []),
        meta.code
      )

      return {
        ...meta,
        memberHealthActions,
        toDoMembers,
        completedMembers,
        subscriberWasRewarded,
        subscriberCompletions,
      }
    })
    const [mainHealthActions, compoundHealthActions] = partition(
      ({ containerHealthActionId }) => !containerHealthActionId,
      healthActions
    )

    return _orderBy(
      mainHealthActions.map((healthAction) => {
        const relatedCompoundHealthActions = _orderBy(
          _filter(
            compoundHealthActions,
            ({ containerHealthActionId }) =>
              containerHealthActionId === healthAction.id
          ),
          ['displayOrder'],
          ['asc']
        )

        if (_size(relatedCompoundHealthActions)) {
          //  Up until this point, we only know if the subscriber was rewarded
          //  based on rewards earned for completing the main/container health
          //  action. For compound health actions, we also need to consider
          //  the component health actions since rewarding the container health
          //  action is optional.
          const subscriberWasRewarded = _some(
            relatedCompoundHealthActions,
            (healthAction) =>
              !!_find(currentRewards, {
                healthActionCd: healthAction.code,
              })
          )

          healthAction.compoundHealthActions = relatedCompoundHealthActions
          healthAction.subscriberWasRewarded =
            healthAction.subscriberWasRewarded || subscriberWasRewarded
        }

        return healthAction
      }),
      ['displayOrder'],
      ['asc']
    )
  },
  completedHealthActions: (
    state,
    { allHealthActions },
    rootState,
    rootGetters
  ) => {
    const { currentCompliancePeriod } = rootGetters['member/memberCoverageInfo']

    if (currentCompliancePeriod) {
      const { startDate, endDate } = currentCompliancePeriod
      return _filter(
        allHealthActions,
        ({ completedMembers, nextDueDateOffsetDayCount }) =>
          _some(completedMembers, ({ completions }) =>
            _some(
              completions,
              ({ completionDate }) =>
                isWithinDateInterval(parseISO(completionDate), {
                  start: startDate,
                  end: endDate,
                }) ||
                (nextDueDateOffsetDayCount && completionDate)
            )
          )
      )
    } else return []
  },
  incompleteHealthActions: (state, getters) =>
    _filter(
      getters.allHealthActions,
      ({ toDoMembers }) => toDoMembers.length > 0
    ),
  totalCompleted: (state, getters) =>
    _size(_flatMap(getters['completedHealthActions'], 'completedMembers')),
  totalIncomplete: (state, getters) =>
    _size(_flatMap(getters['incompleteHealthActions'], 'toDoMembers')),
  totalIncentiveAmountForHealthActions: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const allHealthActions = getters.allHealthActions

    const {
      memberId: subscriberMemberId,
      currentCompliancePeriod,
    } = rootGetters['member/memberCoverageInfo']

    if (
      currentCompliancePeriod &&
      subscriberMemberId &&
      allHealthActions.length > 0
    ) {
      const { startDate, endDate } = currentCompliancePeriod
      const subscriberHealthActions = _filter(
        allHealthActions,
        ({ toDoMembers, completedMembers }) =>
          _some(toDoMembers, { memberId: subscriberMemberId }) ||
          _some(
            completedMembers,
            ({ completions, memberId }) =>
              memberId === subscriberMemberId &&
              _some(completions, ({ completionDate }) =>
                isWithinDateInterval(new Date(completionDate), {
                  start: startDate,
                  end: endDate,
                })
              )
          )
      )

      //  For compound health actions (which are uniquely identified by having
      //  a non-empty "compoundHealthActions" property value), we need to
      //  consider the potential reward amount for the container health action
      //  as well as each component health action (all of which can be rewarded
      //  independently).
      return sumBy((subscriberHealthAction) => {
        const { compoundHealthActions = [] } = subscriberHealthAction
        const flatHealthActions = [
          subscriberHealthAction,
          ...compoundHealthActions,
        ]

        const maxEarnableSums = flatHealthActions.map((healthAction) =>
          healthAction.memberHealthActions
            .filter((memberHA) => memberHA.memberId === subscriberMemberId)
            .reduce((sum, curr) => sum + curr.maxEarnableIncentiveAmount, 0)
        )

        return _sum(maxEarnableSums)
      }, subscriberHealthActions)
    } else {
      return 0
    }
  },
  healthActionById: (state, getters) => (id) => {
    return find(
      { id: parseInt(id) },
      getters.allHealthActions.reduce(
        (acc, item) => [
          ...acc,
          item,
          ..._get(item, 'compoundHealthActions', []),
        ],
        []
      )
    )
  },
  healthActionByCode: (state, getters) => (code) => {
    return find({ code }, getters.allHealthActions)
  },
  lastCompletionById: (state, getters) => (id) => {
    return flow(
      getOr([], 'subscriberCompletions'),
      orderBy('completionDate', 'desc'),
      head,
      getOr('', 'completionDate')
    )(getters.healthActionById(id))
  },
  isEarnedByAction: (state, getters, rootState, rootGetters) => (action) => {
    const memberCoverage = rootGetters['member/memberCoverageInfo']
    const { startDate, endDate } = memberCoverage.currentCompliancePeriod
    const id = _get(action, 'id')
    const lastCompletion = getters.lastCompletionById(id)
    const currentRewards = rootGetters['rewards/currentFamilyRewardHistory']

    //  To determine if any part of the health action's reward potential has
    //  been earned, we need to also consider the component health actions of
    //  a compound health action.
    return (
      isWithinDateInterval(lastCompletion, {
        start: startDate,
        end: endDate,
      }) &&
      (!!find({ healthActionCd: _get(action, 'code') })(currentRewards) ||
        _some(
          action.compoundHealthActions,
          (healthAction) =>
            !!find({
              healthActionCd: healthAction.code,
            })(currentRewards)
        ))
    )
  },
  isSubmittingAttestation: (state) => state.isFetching.memberAttestation,
}
