import Vue from 'vue'
import keyBy from 'lodash/keyBy'
import each from 'lodash/each'
import types from './questionnaire.types'
import initialState from './questionnaire.state'
import { QUESTIONNAIRE_STATUS } from '@platform-shared/constants'
import _get from 'lodash/get'
import _concat from 'lodash/concat'

export default {
  [types.QUESTIONNAIRE_RESET]: (state) => {
    Object.assign(state, initialState())
  },
  [types.QUESTIONNAIRE_REQUEST]: (state) => {
    state.meta.questionnaire.isLoading = true
  },
  [types.QUESTIONNAIRE_SUCCESS]: (state, questionnaires) => {
    const keyedItems = keyBy(questionnaires, 'id')
    const items = Object.keys(keyedItems)

    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = false
    state.items = [...new Set([...state.items, ...items])]
    state.itemsById = { ...state.itemsById, ...keyedItems }
  },

  [types.QUESTIONNAIRE_FAILURE]: (state, error) => {
    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = error || true
  },

  [types.QUESTIONNAIRES_BY_TYPE_REQUEST]: (state) => {
    state.meta.questionnaire.isLoading = true
  },

  [types.QUESTIONNAIRES_BY_TYPE_SUCCESS]: (state, questionnaire) => {
    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = false
    state.items = [...new Set([...state.items, questionnaire.id])]
    state.itemsById = {
      ...state.itemsById,
      ...{ [questionnaire.id]: questionnaire },
    }
  },

  [types.QUESTIONNAIRES_BY_TYPE_FAILURE]: (state, error) => {
    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = error || true
  },

  [types.START_REQUEST]: (state) => {
    state.meta.questionnaire.isLoading = true
    state.meta.questionnaire.error = false
  },
  [types.START_SUCCESS]: (state, payload) => {
    state.itemsById[payload.questionnaireId].status =
      QUESTIONNAIRE_STATUS.STARTED
    state.itemsById[payload.questionnaireId].myId = payload.id
    state.itemsById[payload.questionnaireId].answers = {}
    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = false
  },
  [types.START_FAILURE]: (state) => {
    state.meta.questionnaire.isLoading = false
    state.meta.questionnaire.error = true
  },

  [types.FINISH]: (state, results) => {
    const { questionnaireId } = results
    // add newly completed questionnaire to the top of allCompletions list
    const allCompletions = _concat(
      [results],
      _get(state, ['itemsById', questionnaireId, 'allCompletions'], [])
    )
    Object.assign(state.itemsById[questionnaireId], {
      ...results,
      id: questionnaireId,
      allCompletions,
    })
  },

  [types.REFUSE]: (state, results) => {
    const { questionnaireId } = results
    // add newly completed questionnaire to the top of allCompletions list
    const allCompletions = _concat(
      [results],
      _get(state, ['itemsById', questionnaireId, 'allCompletions'], [])
    )
    Object.assign(state.itemsById[questionnaireId], {
      ...results,
      id: questionnaireId,
      allCompletions,
    })
  },

  //ANSWER SUBMISSIONS
  [types.ANSWER_REQUEST]: (state) => {
    state.meta.answer.isLoading = true
    state.meta.answer.error = false
  },
  [types.ANSWER_SUCCESS]: (state, { questionnaireId, answers }) => {
    state.meta.answer.isLoading = false
    state.meta.answer.error = false

    each(answers, (value, key) => {
      Vue.set(state.itemsById[questionnaireId].answers, key, value)
    })
  },

  [types.ANSWER_FAILURE]: (state) => {
    state.meta.answer.isLoading = false
    state.meta.answer.error = true
  },

  [types.RESET_ERROR_STATE]: (state) => {
    state.meta.answer.error = false
  },
}
