import state from './inbox.state'
import mutations from './inbox.mutations'
import actions from './inbox.actions'
import getters from './inbox.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
