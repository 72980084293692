export default () => ({
  meta: {
    questionnaire: {
      isLoading: false,
      error: null,
      loadedOn: null,
    },
    answer: {
      isLoading: false,
      error: null,
    },
  },
  items: [],
  itemsById: {},
})
