export default {
  RESET_STATE: 'app/reset_state',

  CREATE_MESSAGE: 'app/create_message',
  REMOVE_MESSAGE: 'app/remove_message',

  CREATE_SECURITY_MESSAGE: 'app/create_security_message',
  REMOVE_SECURITY_MESSAGE: 'app/remove_security_message',

  SAVE_POST_LOGIN_PATH: 'app/save_post_login_path',
  REMOVE_POST_LOGIN_PATH: 'app/remove_post_login_path',
}
