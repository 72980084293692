import { v4 as uuidv4 } from 'uuid'

const CORRELATION_ID = 'HM_CORRELATION_ID'

export default {
  getCorrelationId() {
    let correlationId = sessionStorage.getItem(CORRELATION_ID)

    if (!correlationId) {
      correlationId = uuidv4()
      sessionStorage.setItem(CORRELATION_ID, correlationId)
    }

    return correlationId
  },
}
