// NOTE - bp - This mixin controls the visibility of `contactSupportModal` rendered by `Footer`
// Any component can use this mixin to hide or show it, or check its state

export default {
  data() {
    return {
      supportModalOpen: false,
    }
  },
  methods: {
    closeSupportModal() {
      this.supportModalOpen = false
    },
    openSupportModal() {
      this.supportModalOpen = true
    },
  },
}
