import state from './healthActions.state'
import mutations from './healthActions.mutations'
import actions from './healthActions.actions'
import getters from './healthActions.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
