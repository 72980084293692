import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import get from 'lodash/fp/get'
import size from 'lodash/fp/size'
import { MESSAGE_STATUS } from '@platform-shared/constants'

export default {
  isLoading: (state) => state.meta.isLoading,
  allMessages: (state) =>
    flow(
      map((id) => state.messagesById[id]),
      orderBy(['sentAt'], ['desc'])
    )(state.messages),
  messages: (state, getters) =>
    getters.allMessages.filter(
      (message) => message.status !== MESSAGE_STATUS.ARCHIVE
    ),
  archivedMessages: (state, getters) =>
    getters.allMessages.filter(
      (message) => message.status === MESSAGE_STATUS.ARCHIVE
    ),
  unreadMessages: (state, getters) =>
    getters.allMessages.filter(
      (message) => message.status === MESSAGE_STATUS.UNREAD
    ),
  unreadMessagesCount: (state, getters) => size(getters.unreadMessages),
  messageById: (state) => (id) => get(id, state.messagesById),
  subscriptions: (state) =>
    orderBy(['displayOrder', 'label'], ['asc', 'asc'])(state.subscriptions),
}
