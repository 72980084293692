<template>
  <ContactSupport class="py-25 sm:px-50" @cancel="$emit('close')">
    <HMButton class="w-full mt-10 border" color="white" @click="$emit('close')">
      {{ $t('contact_support.cancel') }}
    </HMButton>
  </ContactSupport>
</template>

<script>
import ContactSupport from './ContactSupport'

export default {
  components: { ContactSupport },
}
</script>

<style></style>
