<template>
  <div class="inline-block">
    <i
      class="not-italic"
      :class="iconClass"
      :title="title"
      aria-hidden="true"
      v-on="listeners"
    ></i>
    <span class="sr-only">{{ screenReaderText }}</span>
  </div>
</template>
<script>
import bindAll from '@platform-shared/mixins/bindAll'

export default {
  name: 'Icon',
  mixins: [bindAll],
  props: {
    iconClass: {
      type: [String, Object],
      required: true,
    },
    srText: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    screenReaderText() {
      return this.srText || this.title
    },
  },
}
</script>
<style></style>
