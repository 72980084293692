import state from './client.state'
import mutations from './client.mutations'
import actions from './client.actions'
import getters from './client.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
