import state from './surveys.state'
import mutations from './surveys.mutations'
import actions from './surveys.actions'
import getters from './surveys.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
