import types from './questionnaire.types'
import * as questionnaireService from '@platform-shared/services/questionnaire'
import { QUESTIONNAIRE_TYPES, FEATURES } from '@platform-shared/constants'
import _get from 'lodash/get'
import _find from 'lodash/find'

export default {
  resetState({ commit }) {
    commit(types.QUESTIONNAIRE_RESET)
  },

  initQuestionnaires({ commit, getters, rootGetters, dispatch }) {
    if (!getters.shouldLoad()) return

    commit(types.QUESTIONNAIRE_REQUEST)

    const usesHraRisk = rootGetters['client/hasFeature'](FEATURES.HRA_RISK)

    return new Promise((resolve, reject) => {
      questionnaireService
        .getQuestionnaires()
        .then((res) => {
          commit(types.QUESTIONNAIRE_SUCCESS, res.data)
          const hra = _find(res.data, { type: 'HRA' })
          if (usesHraRisk && hra && _get(hra, 'allCompletions.length', 0)) {
            dispatch('healthProfile/getMyHealthRisks', null, { root: true })
          }
          resolve(res)
        })
        .catch((error) => {
          commit(types.QUESTIONNAIRE_FAILURE)
          reject(error)
        })
    })
  },

  getQuestionnairesByType({ commit, rootGetters, dispatch }, type) {
    commit(types.QUESTIONNAIRES_BY_TYPE_REQUEST)

    const usesHraRisk = rootGetters['client/hasFeature'](FEATURES.HRA_RISK)

    return new Promise((resolve, reject) => {
      questionnaireService
        .getQuestionnairesByType(type)
        .then((res) => {
          if (
            usesHraRisk &&
            type === QUESTIONNAIRE_TYPES.HRA &&
            _get(res, 'data.allCompletions.length', 0)
          ) {
            dispatch('healthProfile/getMyHealthRisks', null, { root: true })
          }
          commit(types.QUESTIONNAIRES_BY_TYPE_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.QUESTIONNAIRES_BY_TYPE_FAILURE)
          reject(error)
        })
    })
  },

  async saveQuestionnaires({ commit }, questionnaires) {
    commit(types.QUESTIONNAIRE_SUCCESS, questionnaires)
  },

  async start({ commit, getters }, questionnaire) {
    if (getters.isLoading) return Promise.reject()

    commit(types.START_REQUEST)
    try {
      const newQuestionnaire = await questionnaireService.start(
        questionnaire.type
      )
      commit(types.START_SUCCESS, newQuestionnaire.data)
      return Promise.resolve(newQuestionnaire.data)
    } catch (error) {
      commit(types.START_FAILURE)
      return Promise.reject(error)
    }
  },

  async finish({ commit, rootGetters, dispatch }, { myId, questionnaireType }) {
    const usesHraRisk = rootGetters['client/hasFeature'](FEATURES.HRA_RISK)
    try {
      const results = await questionnaireService.finish(myId)
      commit(types.FINISH, results.data)
      if (usesHraRisk && questionnaireType === QUESTIONNAIRE_TYPES.HRA) {
        dispatch('healthProfile/getMyHealthRisks', null, { root: true })
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async refuse({ commit }, { questionnaireType }) {
    try {
      const results = await questionnaireService.refuse(questionnaireType)
      commit(types.REFUSE, results.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async answerQuestions({ commit }, { answers, id, myId }) {
    commit(types.ANSWER_REQUEST)

    try {
      const savedAnswers = await questionnaireService.answerQuestions(
        myId,
        answers
      )

      commit(types.ANSWER_SUCCESS, {
        questionnaireId: id,
        answers: savedAnswers.data,
      })
      return Promise.resolve()
    } catch (error) {
      commit(types.ANSWER_FAILURE)
      return Promise.reject()
    }
  },

  resetErrorState({ commit }) {
    commit(types.RESET_ERROR_STATE)
  },
}
