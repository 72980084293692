<template>
  <div class="text-5xl font-sans-bold tracking-wide" v-bind="attrs">
    <slot />
  </div>
</template>

<script>
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  name: 'Super',
  mixins: [bindAll],
}
</script>
