export default {
  RESET_STATE: 'rewards/reset_state',

  INVALIDATE: 'rewards/invalidate',

  INIT_FAILURE: 'rewards/init/failure',
  INIT_REQUEST: 'rewards/init/request',
  INIT_SUCCESS: 'rewards/init/success',

  REWARDS_REDEEM_FAILURE: 'rewards/redeem/failure',
  REWARDS_REDEEM_REQUEST: 'rewards/redeem/request',
  REWARDS_REDEEM_SUCCESS: 'rewards/redeem/success',

  REWARD_BOX_REDEEM_FAILURE: 'rewards/reward-boxes/redeem/failure',
  REWARD_BOX_REDEEM_REQUEST: 'rewards/reward-boxes/redeem/request',
  REWARD_BOX_REDEEM_SUCCESS: 'rewards/reward-boxes/redeem/success',
  REWARD_BOX_ADD: 'rewards/reward-boxes/select',
  REWARD_BOX_REMOVE: 'rewards/reward-boxes/clear',
  REWARD_BOX_RESET_BASKET: 'rewards/reward-boxes/basket',

  REWARDS_BASKET_ADD: 'rewards/basket/add',
  REWARDS_BASKET_REMOVE: 'rewards/basket/remove',

  REWARDS_RESET_BASKET: 'rewards/reset/basket',

  REWARDS_UPDATE_DELIVERY_ADDRESS: 'rewards/update/address',

  SELECT_AUTO_REWARD: 'rewards/auto-rewards/select',
  RESET_SELECTED_AUTO_REWARD: 'rewards/auto-rewards/reset',
  SET_AUTO_REWARD_ADDRESS: 'rewards/auto-rewards/set-address',

  SETUP_AUTO_REWARDS_FAILURE: 'rewards/auto-rewards/setup/failure',
  SETUP_AUTO_REWARDS_REQUEST: 'rewards/auto-rewards/setup/request',
  SETUP_AUTO_REWARDS_SUCCESS: 'rewards/auto-rewards/setup/success',

  TURN_OFF_AUTO_REWARD_FAILURE: 'rewards/auto-rewards/delete/failure',
  TURN_OFF_AUTO_REWARD_REQUEST: 'rewards/auto-rewards/delete/request',
  TURN_OFF_AUTO_REWARD_SUCCESS: 'rewards/auto-rewards/delete/success',

  OPEN_CONFIRM_AUTO_REWARD: 'rewards/auto-rewards/confirm/open',
  CLOSE_CONFIRM_AUTO_REWARD: 'rewards/auto-rewards/confirm/close',

  SET_DEPENDENT_FOR_REDEMPTION: 'SET_DEPENDENT_FOR_REDEMPTION',

  GET_INCENTIVE_CATALOG_REQUEST: 'rewards/incentive-catalog/get/request',
  GET_INCENTIVE_CATALOG_SUCCESS: 'rewards/incentive-catalog/get/success',
  GET_INCENTIVE_CATALOG_FAILURE: 'rewards/incentive-catalog/get/failure',
}
