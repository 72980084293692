import * as clientService from '@platform-shared/services/client'
import types from './client.types'

export default {
  getClientConfig({ commit, getters }) {
    commit(types.GET_CONFIG_REQUEST)

    return new Promise((resolve, reject) => {
      if (getters.hasLoaded) {
        resolve()
      }

      clientService
        .getClientConfig()
        .then((res) => {
          commit(types.GET_CONFIG_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.GET_CONFIG_FAILURE)
          reject(error)
        })
    })
  },
}
