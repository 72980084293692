import * as memberService from '@platform-shared/services/member'
import types from './faq.types'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  initFaq({ commit, getters }) {
    if (!getters.shouldLoad()) return

    commit(types.INIT_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .faq()
        .then((res) => {
          commit(types.INIT_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },
}
