const bindAll = {
  computed: {
    listeners() {
      const { ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { ...attrs } = this.$attrs
      return attrs
    },
  },
}

export default bindAll

//This should be used when you want to bind all listeners and attributes to a child component
//
// <Label v-on="listeners" v-bind="attrs" />
//
