const REMEMBER_TOKEN = 'RememberToken'

export default {
  get: () => {
    return localStorage.getItem(REMEMBER_TOKEN)
  },
  save: (token) => {
    return localStorage.setItem(REMEMBER_TOKEN, token)
  },
  remove: () => {
    return localStorage.removeItem(REMEMBER_TOKEN)
  },
}
