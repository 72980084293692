<template>
  <div class="p-40 text-center w-435">
    <h5>{{ $t('agreements.updated_agreements') }}</h5>
    <p class="mt-20">{{ $t('agreements.about') }}</p>
    <p>
      <router-link
        v-if="showPrivacy"
        :to="{
          name: 'public-privacy-document',
          params: { title: $t('common.privacy_link') },
        }"
        target="_blank"
        class="text-blue-500"
        @click.native="agree"
      >
        {{ $t('common.privacy') }}
      </router-link>
      <span v-if="showPrivacy && showTerms">
        {{ $t('agreements.and') }}
      </span>
      <router-link
        v-if="showTerms"
        :to="{
          name: 'public-privacy-document',
          params: { title: $t('common.terms_link') },
        }"
        target="_blank"
        class="text-blue-500"
        @click.native="agree"
      >
        {{ $t('common.terms') }}
      </router-link>
    </p>
    <p class="mt-20">{{ $t('agreements.click_links') }}</p>
    <p>{{ $t('agreements.click_understand') }}</p>
    <HMButton rounded="full" class="w-full mt-20" @click="agree">
      {{ $t('agreements.understand') }}
    </HMButton>
  </div>
</template>
<script>
import privacyMixin from '@platform-shared/mixins/agreements/privacy'

export default {
  mixins: [privacyMixin],
}
</script>
