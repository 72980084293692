<template>
  <header class="bg-white border-b border-grey-100 relative">
    <nav class="container py-12 md:py-0 md:pt-0" role="navigation">
      <div class="flex justify-between">
        <div class="flex items-center">
          <router-link to="/" class="block h-30" @click.native="closeNav">
            <img
              class="h-full"
              :src="headerLogo"
              :alt="`${this.$settings.NAME} Logo`"
            />
          </router-link>
          <div
            id="main-nav"
            class="nav-menu overflow-hidden"
            :class="{ 'is-open': navIsOpen }"
            role="navigation"
            :aria-expanded="navIsOpen.toString()"
          >
            <ul class="nav-list">
              <li v-if="showHome">
                <router-link
                  class="nav-item"
                  to="/"
                  exact
                  @click.native="closeNav"
                  >{{ $t('common.header.home') }}</router-link
                >
              </li>
              <li v-if="showRewards">
                <router-link
                  class="nav-item"
                  :to="{ name: 'rewards' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.rewards') }}</router-link
                >
              </li>
              <li v-if="showEducation">
                <router-link
                  class="nav-item"
                  :to="{ name: 'learn' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.learn') }}</router-link
                >
              </li>
              <li class="md:hidden">
                <router-link
                  class="nav-item"
                  :to="{ name: 'help-center' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.help_center') }}</router-link
                >
              </li>
              <li v-if="showPrescriptionRecord" class="md:hidden">
                <router-link
                  class="nav-item"
                  :to="{ name: 'prescription-record' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.prescriptions') }}</router-link
                >
              </li>
              <li v-if="showTracking" class="md:hidden">
                <router-link
                  class="nav-item"
                  :to="{ name: 'tracking' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.trackers') }}</router-link
                >
              </li>
              <li class="md:hidden">
                <router-link
                  :to="{ name: 'inbox' }"
                  class="nav-item"
                  @click.native="closeNav"
                >
                  <div class="flex justify-between">
                    <span>{{ $t('common.header.inbox') }}</span>
                    <Icon
                      v-if="!!unreadMessagesCount"
                      iconClass="fas fa-circle text-red-500 text-xs"
                      :srText="$t('inbox.unread')"
                    />
                  </div>
                </router-link>
              </li>
              <li class="md:hidden">
                <router-link
                  class="nav-item"
                  :to="{ name: 'account' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.profile') }}</router-link
                >
              </li>
              <li v-if="usesLegacyPage" class="md:hidden">
                <router-link
                  class="nav-item"
                  :to="{ name: 'notification-settings' }"
                  @click.native="closeNav"
                  >{{ $t('common.header.notification_settings') }}</router-link
                >
              </li>
              <li class="md:hidden">
                <button class="nav-item" type="button" @click="logout">
                  {{ $t('common.header.logout') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <button
          class="toggle-btn"
          :class="{ 'is-open': navIsOpen }"
          aria-controls="main-nav"
          :aria-expanded="navIsOpen.toString()"
          aria-label="Toggle Navigation"
          @click="toggleNav"
        >
          <span class="toggle-burger" aria-hidden="true" />
        </button>
        <div class="items-center relative hidden md:flex">
          <ul class="flex mr-20">
            <li>
              <router-link
                :to="{ name: 'help-center' }"
                :title="$t('common.header.help_center')"
                class="icon-link w-35 h-35 rounded-full flex justify-center items-center bg-grey-100 p-8"
                :class="{ 'is-active': $route.name === 'help-center' }"
              >
                <Question class="h-full" aria-hidden="true" />
                <span class="sr-only">
                  {{ $t('common.header.help_center') }}
                </span>
              </router-link>
            </li>
            <li v-if="showPrescriptionRecord">
              <router-link
                :to="{ name: 'prescription-record' }"
                :title="$t('common.header.prescriptions')"
                class="icon-link w-35 h-35 rounded-full flex justify-center items-center bg-grey-100 p-8"
                :class="{
                  'is-active': $route.name === 'prescription-record',
                }"
              >
                <Prescriptions class="h-full" aria-hidden="true" />
                <span class="sr-only">{{
                  $t('common.header.prescriptions')
                }}</span>
              </router-link>
            </li>
            <li v-if="showTracking">
              <router-link
                :to="{ name: 'tracking' }"
                :title="$t('common.header.trackers')"
                class="icon-link w-35 h-35 rounded-full flex justify-center items-center bg-grey-100 p-8"
                :class="{ 'is-active': $route.name === 'tracking' }"
              >
                <HeartPulse class="h-full" aria-hidden="true" />
                <span class="sr-only">{{ $t('common.header.trackers') }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'inbox' }"
                exact
                :title="$t('common.header.inbox')"
                class="icon-link w-35 h-35 rounded-full flex justify-center items-center bg-grey-100 p-8"
                :class="{ 'is-active': $route.name === 'inbox' }"
              >
                <div class="relative">
                  <Inbox class="h-full" aria-hidden="true" />
                  <i
                    v-if="!!unreadMessagesCount"
                    class="not-italic absolute top-0 right-0 -mr-4 fas fa-circle text-red-500 text-xs"
                    :aria-label="$t('inbox.unread')"
                  />
                </div>
                <span class="sr-only">{{ $t('common.header.inbox') }}</span>
              </router-link>
            </li>
          </ul>
          <div
            v-if="showLanguage"
            class="relative h-full flex items-center px-20 border-l border-grey-100"
          >
            <LanguageSelect appHeader />
          </div>
          <div
            class="profile-dropdown-container"
          >
            <button
              class="flex items-center focus:outline-none"
              aria-controls="profile-dropdown"
              :aria-expanded="profileIsOpen.toString()"
              @click="toggleProfile"
            >
              <span class="text-sm tracking-wide mt-1 font-semibold">{{
                fullName
              }}</span>
              <Caret class="profile-dropdown-caret" />
            </button>
            <div
              id="profile-dropdown"
              v-click-outside="clickOutsideHandler"
              class="profile-dropdown"
              :class="{ 'is-open': profileIsOpen }"
            >
              <ul>
                <li>
                  <router-link
                    :to="{ name: 'account' }"
                    class="profile-item"
                    @click.native="closeProfile"
                    >{{ $t('common.header.account_details') }}</router-link
                  >
                </li>
                <li v-if="usesLegacyPage">
                  <router-link
                    :to="{ name: 'notification-settings' }"
                    class="profile-item"
                    @click.native="closeProfile"
                  >
                    {{ $t('common.header.notification_settings') }}
                  </router-link>
                </li>
                <li>
                  <button class="profile-item" type="button" @click="logout">
                    {{ $t('common.header.logout') }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Caret, HeartPulse, Inbox, Prescriptions, Question } from '@assets/svgs'
import { FEATURES } from '@platform-shared/constants'
import LanguageSelect from '../common/LanguageSelect.vue'

export default {
  components: {
    Question,
    Inbox,
    Caret,
    Prescriptions,
    HeartPulse,
    LanguageSelect,
  },
  data() {
    return {
      navIsOpen: false,
      profileIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('member', ['fullName']),
    ...mapGetters('inbox', ['unreadMessagesCount']),
    ...mapGetters('client', ['hasFeature', 'showLanguage']),
    ...mapGetters('member', ['contractLogo']),
    headerLogo() {
      return `${this.$settings.S3_ROOT}/${this.contractLogo}`
    },
    clickOutsideHandler() {
      return {
        handler: this.closeProfile,
        isActive: this.profileIsOpen,
      }
    },
    showHome() {
      return this.hasFeature(FEATURES.HOME)
    },
    showRewards() {
      return this.hasFeature(FEATURES.REWARDS)
    },
    showPrescriptionRecord() {
      return this.hasFeature(FEATURES.PRESCRIPTION_RECORD)
    },
    showTracking() {
      return this.hasFeature(FEATURES.TRACKING)
    },
    showEducation() {
      return this.hasFeature(FEATURES.EDUCATION)
    },
    usesLegacyPage() {
      return this.hasFeature(FEATURES.LEGACY_ACCOUNT_PAGE)
    },
  },
  methods: {
    ...mapActions(['logout']),
    toggleNav() {
      this.navIsOpen = !this.navIsOpen
    },
    closeNav() {
      this.navIsOpen = false
    },
    toggleProfile() {
      this.profileIsOpen = !this.profileIsOpen
    },
    closeProfile() {
      this.profileIsOpen = false
    },
  },
}
</script>

<style lang="postcss" scoped>
.nav-menu {
  @apply absolute hidden mt-1 pt-2 top-max inset-x-0 bg-white shadow-md z-10;

  @screen md {
    @apply block static mt-0 shadow-none pb-0 pt-0 ml-16 z-0;
  }

  &.is-open {
    @apply block;
  }
}

.nav-list {
  @apply block pb-10;

  @screen md {
    @apply flex justify-start pb-0;
  }
}

.nav-item {
  @apply block border-b-2 border-grey-200 py-16 px-5 mx-16 transition-all duration-300 text-sm font-semibold;

  @screen max-md {
    li:last-child & {
      @apply border-none;
    }
  }

  @screen md {
    @apply pb-0 pt-10 h-60 mx-10 flex items-center border-white;

    &.is-active {
      @apply border-blue-500;
    }
  }

  @screen lg {
    @apply mx-15;
  }
}

.icon-link {
  @apply transition-all duration-300 ml-16;

  &:hover {
    @apply text-blue-500 bg-grey-200;
  }

  &.is-active {
    @apply text-blue-500 bg-grey-200;
  }
}

.toggle-btn {
  @apply relative inline-block py-10 px-5 select-none cursor-pointer outline-none;

  @screen md {
    @apply hidden;
  }
}

.toggle-burger {
  @apply relative block bg-black h-2 w-20;

  transition: background 0.2s ease-out;

  &::before,
  &::after {
    @apply absolute block bg-black h-full w-full transition-all duration-300;

    content: '';
  }

  &::before {
    top: 5px;

    .toggle-btn.is-open & {
      transform: rotate(-45deg);
      top: 0;
    }
  }

  &::after {
    top: -5px;

    .toggle-btn.is-open & {
      transform: rotate(45deg);
      top: 0;
    }
  }

  .toggle-btn.is-open & {
    background: transparent;
  }
}

.profile-dropdown {
  @apply absolute top-max right-0 mt-1 w-200 bg-white px-20 py-10 shadow-md hidden z-20;
  margin-inline-end: 10px;
  &.is-open {
    @apply block;
  }
}

.profile-item {
  @apply block border-b border-grey-200 py-16;

  li:last-child & {
    @apply border-none;
  }
}

.profile-dropdown-caret {
  @apply w-12 mt-5;
  margin-inline-start: 8px;
}

.profile-dropdown-container {
  @apply relative h-full flex items-center border-l border-grey-100;
  padding-inline-start: 20px;
}

.overlay {
  @apply fixed inset-0 z-10;

  &.is-open {
    @apply block;
  }
}
</style>
