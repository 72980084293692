import api from './api'

const trackingEndpoint = `/v1/${process.env.VUE_APP_DEVICE}/tracking`

export const initTracking = () => {
  const url = `${trackingEndpoint}/init-tracking`
  return api()
    .url(url)
    .get()
}

/**
 * Update value of tracker
 */
export const updateTracker = ({ tracker, value, date, source }) => {
  const updateTrackerUrl = `${trackingEndpoint}/${tracker}`
  return api()
    .url(updateTrackerUrl)
    .put({ value, date, source })
}

/**
 * Get tracker history
 */
export const trackerHistory = (tracker) => {
  const trackerHistoryUrl = `${trackingEndpoint}/history/${tracker}`
  return api()
    .url(trackerHistoryUrl)
    .get()
}

export const getTrackingSummary = () => {
  const url = `${trackingEndpoint}/summary`
  return api()
    .url(url)
    .get()
}

export const setHumanApiToken = (humanApiToken) => {
  const url = `${trackingEndpoint}/human-api-token`
  return api()
    .url(url)
    .put(humanApiToken)
}

export const updateTrackerHistoryBulk = (tracker, history) => {
  return api()
    .url(`${trackingEndpoint}/bulk-history/${tracker}`)
    .put(history)
}
