import * as trackingService from '@platform-shared/services/tracking'
import types from './tracking.types'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },

  initTracking({ commit, getters }, force = false) {
    if (!getters.shouldLoad() && !force) return

    commit(types.INIT_REQUEST)
    return new Promise((resolve, reject) => {
      trackingService
        .initTracking()
        .then((res) => {
          commit(types.INIT_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },

  updateTracker({ commit }, payload) {
    commit(types.UPDATE_TRACKER_REQUEST)

    return new Promise((resolve, reject) => {
      trackingService
        .updateTracker(payload)
        .then((res) => {
          commit(types.UPDATE_TRACKER_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.UPDATE_TRACKER_FAILURE)
          reject(error)
        })
    })
  },

  getTrackerHistory({ commit }, { trackerKey, trackerType }) {
    commit(types.TRACKER_HISTORY_REQUEST)

    return new Promise((resolve, reject) => {
      trackingService
        .trackerHistory(trackerKey)
        .then((res) => {
          commit(types.TRACKER_HISTORY_SUCCESS, {
            tracker: trackerType,
            history: res.data,
          })
          resolve(res)
        })
        .catch((error) => {
          commit(types.TRACKER_HISTORY_FAILURE)
          reject(error)
        })
    })
  },

  setHumanApiToken({ commit, dispatch }, humanApiToken) {
    commit(types.SET_HUMAN_API_PUBLIC_TOKEN_REQUEST)
    return new Promise((resolve, reject) => {
      trackingService
        .setHumanApiToken(humanApiToken)
        .then((res) => {
          const { publicToken } = res.data
          commit(types.SET_HUMAN_API_PUBLIC_TOKEN_SUCCESS, publicToken)
          dispatch('getTrackingSummary')
          resolve(res)
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },

  getTrackingSummary({ commit }) {
    commit(types.SUMMARY_REQUEST)
    return new Promise((resolve, reject) => {
      trackingService
        .getTrackingSummary()
        .then((res) => {
          commit(types.SUMMARY_SUCCESS, res.data)
          resolve(res)
        })
        .catch((error) => {
          commit(types.SUMMARY_FAILURE)
          reject(error)
        })
    })
  },

  updateTrackerHistoryBulk({ commit }, { trackerType, trackerHistory }) {
    commit(types.UPDATE_TRACKER_HISTORY_BULK_REQUEST)
    return new Promise((resolve, reject) => {
      trackingService
        .updateTrackerHistoryBulk(trackerType, trackerHistory)
        .then(() => {
          commit(types.UPDATE_TRACKER_HISTORY_BULK_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.UPDATE_TRACKER_HISTORY_BULK_FAILURE)
          reject(error)
        })
    })
  },
}
