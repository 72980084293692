import { SecureStorage } from '@nativescript/secure-storage'

const secureStorage = new SecureStorage()
export const REFRESH_TOKEN = 'refreshToken'

export const getRefreshToken = () => {
  return secureStorage.getSync({
    key: REFRESH_TOKEN,
  })
}

export const removeRefreshToken = () => {
  return secureStorage.remove({
    key: REFRESH_TOKEN,
  })
}

export const saveRefreshToken = (token) => {
  return secureStorage.setSync({
    key: REFRESH_TOKEN,
    value: token,
  })
}
