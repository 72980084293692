export default {
  QUESTIONNAIRE_RESET: 'questionnaire/reset',
  QUESTIONNAIRE_FAILURE: 'questionnaire/failure',
  QUESTIONNAIRE_REQUEST: 'questionnaire/request',
  QUESTIONNAIRE_SUCCESS: 'questionnaire/success',

  QUESTIONNAIRES_BY_TYPE_FAILURE: 'questionnaires/type/failure',
  QUESTIONNAIRES_BY_TYPE_REQUEST: 'questionnaires/type/request',
  QUESTIONNAIRES_BY_TYPE_SUCCESS: 'questionnaires/type/success',

  ANSWER_FAILURE: 'questionnaire/answer/failure',
  ANSWER_REQUEST: 'questionnaire/answer/request',
  ANSWER_SUCCESS: 'questionnaire/answer/success',

  RESET_ERROR_STATE: 'questionnaire/errors/reset',

  START_FAILURE: 'questionnaire/start/failure',
  START_REQUEST: 'questionnaire/start/request',
  START_SUCCESS: 'questionnaire/start/success',
  FINISH: 'questionnaire/finish',
  REFUSE: 'questionnaire/refuse',
}
