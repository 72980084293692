import state from './healthProfile.state'
import mutations from './healthProfile.mutations'
import actions from './healthProfile.actions'
import getters from './healthProfile.getters'

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters,
}
