import types from './inbox.types'
import initialState from './inbox.state'
import _keyBy from 'lodash/keyBy'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import Vue from 'vue'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },

  [types.INIT_SUCCESS]: (state, messages) => {
    const keyedMessages = _keyBy(messages, 'id')

    state.meta.isLoading = false
    state.meta.loadedOn = new Date()
    state.messages = Object.keys(keyedMessages)
    state.messagesById = keyedMessages
  },

  [types.GET_MESSAGES_REQUEST]: (state) => {
    state.meta.isLoading = true
  },
  [types.GET_MESSAGES_FAILURE]: (state) => {
    state.meta.isLoading = false
  },

  [types.UPDATE_MESSAGE_SUCCESS]: (state, { messageId, status }) => {
    Vue.set(state.messagesById[messageId], 'status', status)
  },

  [types.GET_SUBSCRIPTIONS_REQUEST]: (state) => {
    state.meta.error = false
    state.meta.isLoading = true
  },
  [types.GET_SUBSCRIPTIONS_SUCCESS]: (state, subscriptions) => {
    state.meta.isLoading = false
    state.subscriptions = subscriptions
  },
  [types.GET_SUBSCRIPTIONS_FAILURE]: (state) => {
    state.meta.error = true
    state.meta.isLoading = false
  },

  [types.UPDATE_SUBSCRIPTIONS_REQUEST]: (state) => {
    state.meta.error = false
    state.meta.isLoading = true
  },
  [types.UPDATE_SUBSCRIPTIONS_SUCCESS]: (state) => {
    state.meta.isLoading = false
  },
  [types.UPDATE_SUBSCRIPTIONS_FAILURE]: (state) => {
    state.meta.error = true
    state.meta.isLoading = false
  },

  [types.CREATE_SUBSCRIPTION_COMMENT_REQUEST]: (state) => {
    state.meta.error = false
    state.meta.isLoading = true
  },
  [types.CREATE_SUBSCRIPTION_COMMENT_SUCCESS]: (state) => {
    state.meta.isLoading = false
  },
  [types.CREATE_SUBSCRIPTION_COMMENT_FAILURE]: (state) => {
    state.meta.error = true
    state.meta.isLoading = false
  },

  [types.TOGGLE_SUBSCRIPTION_STATUS]: (state, category) => {
    const subIdx = _findIndex(state.subscriptions, {
      category,
    })
    if (subIdx > -1) {
      const status =
        _get(state, ['subscriptions', subIdx, 'status']) === 'SUBSCRIBED'
          ? 'UNSUBSCRIBED'
          : 'SUBSCRIBED'
      Vue.set(state.subscriptions[subIdx], 'status', status)
    }
  },
}
