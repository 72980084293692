export default {
  RESET: 'current-questionnaire/reset',

  ACTIVATE: 'current-questionnaire/activate',
  BEGIN: 'current-questionnaire/begin',
  SET_CURRENT_GROUP: 'current-questionnaire/set-current-group',
  UPDATE_RUNNING_ANSWERS: 'current-questionnaire/set-running-answers',
  FINISH: 'current-questionnaire/finish',
  END_PROCESSING: 'current-questionnaire/end-processing',
  SET_ID: 'current-questionnaire/set-id',
}
