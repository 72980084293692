import * as memberService from '@platform-shared/services/member'
import types from './registration.types'
import { isMobile, isWeb } from '@platform-shared/utils/device'
import { saveRefreshToken } from '@platform-shared/utils/refreshToken'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import _get from 'lodash/fp/get'
import Vue from 'vue'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  saveAccountInfo({ commit }, accountInfo) {
    commit(types.REGISTER_ACCOUNT_INFO, accountInfo)
  },
  saveGuardianInfo({ commit }, guardianInfo) {
    commit(types.REGISTER_GUARDIAN_INFO, guardianInfo)
  },
  saveMemberInfo({ commit }, memberInfo) {
    commit(types.REGISTER_MEMBER_INFO, memberInfo)
  },
  saveContactInfo({ commit }, contactInfo) {
    commit(types.REGISTER_CONTACT, contactInfo)
  },
  saveAddressInfo({ commit }, addressInfo) {
    commit(types.REGISTER_ADDRESS, addressInfo)
  },
  saveCommunicationPreferences({ commit }, communicationPreferences) {
    commit(types.REGISTER_COMMUNICATION_PREFERENCES, communicationPreferences)
  },
  saveMfaPreference({ commit }, mfaPreference) {
    commit(types.REGISTER_MFA_PREFERENCE, { mfaPreference })
  },
  savePasswordInfo({ commit }, passwordInfo) {
    commit(types.REGISTER_PASSWORD_INFO, passwordInfo)
  },

  submitMfaPreference({ commit }, mfaPreference) {
    commit(types.REGISTER_MFA_PREFERENCE, { mfaPreference })
    commit(types.REGISTER_MFA_PREFERENCE_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .updateMfaPreference(mfaPreference)
        .then(() => {
          commit(types.REGISTER_MFA_PREFERENCE_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REGISTER_MFA_PREFERENCE_FAILURE)
          reject(error)
        })
    })
  },

  altSubmitRegistration({ commit, getters, dispatch }) {
    commit(types.REGISTER_REQUEST)

    const registrationInfo = {
      ...getters.registrationInfo,
    }

    return new Promise((resolve, reject) => {
      memberService
        .register(registrationInfo)
        .then((res) => {
          const { refreshToken, memberId, jwt, mfaType } = res.data
          dispatch('member/storeToken', jwt, { root: true })
          if (isMobile && refreshToken) {
            saveRefreshToken(refreshToken)
          }

          if (mfaType) {
            commit(types.POST_REGISTER_WITH_MFA)
          }

          dispatch('member/loginSuccess', { memberId, mfaType }, { root: true })
          commit(types.REGISTER_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REGISTER_FAILURE)
          reject(error)
        })
    })
  },

  submitRegistration(
    { commit, getters, dispatch },
    { username, password, preferredLanguageCd = 'EN' }
  ) {
    commit(types.REGISTER_REQUEST)

    const registrationInfo = {
      ...getters.registrationInfo,
      preferredLanguageCd,
      username,
      password,
    }

    return new Promise((resolve, reject) => {
      memberService
        .register(registrationInfo)
        .then((res) => {
          const { refreshToken, memberId, jwt, mfaType } = res.data
          dispatch('member/storeToken', jwt, { root: true })
          if (isMobile && refreshToken) {
            saveRefreshToken(refreshToken)
          }

          dispatch('member/loginSuccess', { memberId, mfaType }, { root: true })
          commit(types.REGISTER_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.REGISTER_FAILURE)
          reject(error)
        })
    })
  },
  submitSSORegistrationInfo({ commit, getters, dispatch }) {
    commit(types.SSO_REGISTRATION_INFO_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .registerSSO(getters.registrationSSOInfo)
        .then(() => {
          dispatch('rewards/initRewards', true)
          commit(types.SSO_REGISTRATION_INFO_SUCCESS)
          resolve()
        })
        .catch(() => {
          commit(types.SSO_REGISTRATION_INFO_FAILURE)
          reject()
        })
    })
  },
  postRegistrationMemberInfo({ commit, dispatch }) {
    commit(types.POST_REGISTRATION_MEMBER_INFO_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .postRegistrationMemberInfo()
        .then((res) => {
          dispatch('member/initialData', res.data, { root: true })
          commit(types.POST_REGISTRATION_MEMBER_INFO_SUCCESS)
          resolve()
        })
        .catch((error) => {
          commit(types.POST_REGISTRATION_MEMBER_INFO_FAILURE)
          reject(error)
        })
    })
  },

  checkEligibility({ commit }, memberInfo) {
    commit(types.REGISTER_MEMBER_EXISTENCE_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .checkEligibility(memberInfo)
        .then(async (data) => {
          commit(types.REGISTER_MEMBER_EXISTENCE_SUCCESS, _get('data', data))
          resolve()
        })
        .catch((error) => {
          const errorData = _get('json.data', error)
          const failureReasonCode = _get('reasonCode', errorData)
          let errorMessageId = 'registration.member_info.error.not_found'
          let errorMessageParams = []
          let errorMessage = ''

          if (
            failureReasonCode ===
            'registration.member_info.error.age_ineligible'
          ) {
            errorMessageId = failureReasonCode
          } else if (
            failureReasonCode ===
            'registration.member_info.error.registration_not_open'
          ) {
            const registrationOpenDate = _get('registrationOpenDate', errorData)

            const formattedRegistrationOpenDate =
              registrationOpenDate &&
              format(parseISO(registrationOpenDate), 'EEEE, LLLL d, yyyy')

            errorMessageId = failureReasonCode

            errorMessageParams = formattedRegistrationOpenDate
              ? [formattedRegistrationOpenDate]
              : []
          } else if (error.status === 422) {
            errorMessageId = 'registration.member_info.error.registered'
          }

          if (isWeb) {
            const i18n = require('~/main').i18n

            errorMessage = i18n.t(errorMessageId, errorMessageParams)
          } else if (isMobile) {
            errorMessage = Vue.options.filters.LR(
              errorMessageId,
              errorMessageParams
            )
          }

          commit(types.REGISTER_MEMBER_EXISTENCE_FAILURE)
          reject(errorMessage)
        })
    })
  },

  usernameCheck({ commit }, username) {
    commit(types.REGISTER_USERNAME_VALID_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .usernameCheck(username)
        .then(() => {
          commit(types.REGISTER_USERNAME_VALID_SUCCESS)
          resolve()
        })
        .catch(() => {
          commit(types.REGISTER_USERNAME_VALID_FAILURE)
          reject('registration.member_info.error.username_taken')
        })
    })
  },

  lookupPhoneNumbers({ commit }, numbers) {
    commit(types.LOOK_UP_NUMBERS_REQUEST)
    return new Promise((resolve, reject) => {
      memberService
        .lookupPhoneNumbers(numbers)
        .then((result) => {
          commit(types.LOOK_UP_NUMBERS_SUCCESS, {
            lookedUpNumbers: result.data,
          })
          resolve(result.data)
        })
        .catch(() => {
          commit(types.LOOK_UP_NUMBERS_FAILURE)
          reject()
        })
    })
  },

  async resetPassword(
    { commit },
    { firstName, lastName, clientMemberId, dob }
  ) {
    commit(types.RESET_PASSWORD_REQUEST)
    try {
      const result = memberService.resetPassword({
        firstName,
        lastName,
        clientMemberId,
        dob,
      })
      commit(types.RESET_PASSWORD_SUCCESS)
      return result
    } catch (error) {
      commit(types.RESET_PASSWORD_FAILURE)
      throw error
    }
  },

  async changePasswordFromReset(
    { commit },
    { username, oldPassword, newPassword }
  ) {
    commit(types.CHANGE_PASSWORD_FROM_RESET_REQUEST)
    try {
      const result = await memberService.changePasswordFromReset({
        username,
        oldPassword,
        newPassword,
      })
      commit(types.CHANGE_PASSWORD_FROM_RESET_SUCCESS)
      return result
    } catch (error) {
      commit(types.CHANGE_PASSWORD_FROM_RESET_FAILURE)
      throw error
    }
  },

  async checkMemberInformation(
    { commit },
    { lastName, firstName, birthday: dob, memberNumber: clientMemberId }
  ) {
    commit(types.CHECK_MEMBER_INFORMATION_REQUEST)
    try {
      const result = await memberService.getUserName({
        lastName,
        firstName,
        birthday: dob,
        memberNumber: clientMemberId,
      })
      commit(types.CHECK_MEMBER_INFORMATION_SUCCESS)
      return result
    } catch (error) {
      commit(types.CHANGE_PASSWORD_FROM_RESET_FAILURE)
      throw error
    }
  },
}
