export default () => ({
  meta: {
    loadedOn: null,
    error: false,
    isLoading: false,
  },
  isFetching: {
    memberAttestation: false,
    profileUpdate: false,
    articleMarkedRead: false,
  },
  items: {},
})
