<template>
  <div class="text-lg font-sans-normal" v-bind="attrs">
    <slot />
  </div>
</template>

<script>
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  name: 'H4',
  mixins: [bindAll],
}
</script>
