export const getEnvironment = () => {
  return /^(https?):\/\/api\d?\.(qa|stage|demo)?/.exec(
    window.HM_CONFIG.API_ROOT
  )
    ? RegExp.$1 === 'https'
      ? RegExp.$2 || 'prod'
      : ''
    : ''
}

export const getNormalizedEnvironment = () => {
  const environment = getEnvironment().toLowerCase()

  return environment === 'prod' ? 'production' : environment
}

export const replaceS3 = (url) =>
  url &&
  url.replace(/s3:\/\/|__HM__S3__\//gi, `${window.HM_CONFIG.S3_ROOT}/` || '')
