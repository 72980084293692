import types from './healthActions.types'
import initialState from './healthActions.state'
import { HEALTH_ACTION_CODES } from '../../constants.js'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },
  [types.INIT_REQUEST]: (state) => {
    state.meta.isLoading = true
  },
  [types.INIT_SUCCESS]: (state, { healthActions }) => {
    state.meta.loadedOn = new Date()
    state.meta.isLoading = false
    state.meta.error = false
    state.items = { ...healthActions }
  },
  [types.INIT_FAILURE]: (state) => {
    state.meta.isLoading = false
    state.meta.error = true
  },

  [types.CREATE_ATTESTATION_REQUEST]: (state) => {
    state.isFetching.memberAttestation = true
  },

  [types.CREATE_ATTESTATION_SUCCESS]: (
    state,
    { healthActionCode, attestation }
  ) => {
    state.items[healthActionCode].members[
      attestation.memberId
    ].memberAttestations.push(attestation)
    state.isFetching.memberAttestation = false
  },

  [types.CREATE_ATTESTATION_FAILURE]: (state) => {
    state.isFetching.memberAttestation = false
  },

  [types.SUBMIT_PROFILE_UPDATE_REQUEST]: (state) => {
    state.isFetching.profileUpdate = true
  },

  [types.SUBMIT_PROFILE_UPDATE_SUCCESS]: (state, { memberId }) => {
    state.items[HEALTH_ACTION_CODES.PROFILE_UPDATE].members[memberId] = {
      ...state.items[HEALTH_ACTION_CODES.PROFILE_UPDATE].members[memberId],
      profileUpdated: true,
    }
    state.isFetching.profileUpdate = false
  },

  [types.SUBMIT_PROFILE_UPDATE_FAILURE]: (state) => {
    state.isFetching.profileUpdate = false
  },

  [types.MARK_ARTICLE_AS_READ_REQUEST]: (state) => {
    state.isFetching.articleMarkedRead = true
  },

  [types.MARK_ARTICLE_AS_READ_SUCCESS]: (
    state,
    { healthActionCode, memberId, contentId }
  ) => {
    state.items[healthActionCode].members[memberId] = {
      ...state.items[healthActionCode].members[memberId],
      articlesMarkedAsRead: [
        ...state.items[healthActionCode].members[memberId].articlesMarkedAsRead,
        contentId,
      ],
    }
    state.isFetching.articleMarkedRead = false
  },

  [types.MARK_ARTICLE_AS_READ_FAILURE]: (state) => {
    state.isFetching.articleMarkedRead = false
  },
}
