import Vue from 'vue'
import Vuex from 'vuex'
import { isMobile } from '@platform-shared/utils/device'
import tokenService from '@platform-shared/utils/tokenService'
import app from './app'
import client from './client'
import currentQuestionnaire from './current-questionnaire'
import learn from './learn'
import healthActions from './health-actions'
import inbox from './inbox'
import faq from './faq'
import member from './member'
import questionnaire from './questionnaire'
import registration from './registration'
import rewards from './rewards'
import healthProfile from './health-profile'
import tracking from './tracking'

Vue.use(Vuex)

const modules = {
  app,
  client,
  currentQuestionnaire,
  learn,
  healthActions,
  inbox,
  faq,
  member,
  questionnaire,
  registration,
  rewards,
  healthProfile,
  tracking,
}

//TODO: default logout message screens need to be i18n strings
export default new Vuex.Store({
  modules,
  actions: {
    secureLogout(
      { dispatch },
      logoutMessage = 'Session expired do to inactivity.'
    ) {
      dispatch('logout')
      dispatch('app/setSecurityMessage', logoutMessage, { root: true })
    },
    forceLogout({ dispatch }, logoutMessage = 'Session Expired!') {
      dispatch('logout')
      dispatch('app/showErrorMessage', logoutMessage, { root: true })
    },

    logout({ dispatch, rootGetters }) {
      tokenService.removeToken()
      const memberLoginUrl = rootGetters['member/memberLoginUrl']

      if (isMobile) {
        dispatch('tabs/resetState', null, { root: true })
      }

      Object.keys(modules).map((module) => {
        if (module !== 'client') {
          dispatch(`${module}/resetState`, null, { root: true })
        }
      })

      dispatch('client/getClientConfig', null, { root: true })

      if (!isMobile && rootGetters['member/isSsoSamlIdpInit'] && memberLoginUrl)
        window.location.href = memberLoginUrl
    },
  },
})
