<template>
  <div class="form-group" :class="{ error: hasErrors }">
    <label
      v-if="label"
      class="text-base font-semibold block text-grey-500 ml-1 mb-4"
      :for="name"
      >{{ label }}</label
    >
    <slot :hasError="activeErrorMessages.length > 0" />
    <div v-if="hasErrors" class="errors">
      <span
        v-for="error in activeErrorMessages"
        :key="error"
        class="text-red-500 text-sm ml-1 block"
        >{{ error }}</span
      >
    </div>
    <div
      v-if="helperText"
      class="text-sm font-semibold block text-grey-500 ml-1 mt-4 mb-4"
    >
      {{ helperText }}
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'
export default {
  mixins: [singleErrorExtractorMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
