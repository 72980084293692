import * as healthActionsService from '@platform-shared/services/healthActions'
import * as memberService from '@platform-shared/services/member'
import * as learnService from '@platform-shared/services/learn'
import types from './healthActions.types'
import { ACTIVITY_TYPES } from '../../constants.js'

export default {
  resetState({ commit }) {
    commit(types.RESET_STATE)
  },
  initHealthActions({ commit, getters }) {
    if (!getters.shouldLoad()) return

    commit(types.INIT_REQUEST)

    return new Promise((resolve, reject) => {
      healthActionsService
        .initHealthActions()
        .then((res) => {
          commit(types.INIT_SUCCESS, res.data)
          resolve()
        })
        .catch((error) => {
          commit(types.INIT_FAILURE)
          reject(error)
        })
    })
  },

  submitMemberAttestation(
    { commit, getters },
    { memberAttestation, healthActionId }
  ) {
    commit(types.CREATE_ATTESTATION_REQUEST)

    return new Promise((resolve, reject) => {
      healthActionsService
        .submitMemberAttestation(memberAttestation, healthActionId)
        .then((res) => {
          commit(types.CREATE_ATTESTATION_SUCCESS, {
            healthActionCode: getters.healthActionById(healthActionId).code,
            attestation: res.data,
          })
          resolve(res)
        })
        .catch((error) => {
          commit(types.CREATE_ATTESTATION_FAILURE)
          reject(error)
        })
    })
  },

  submitProfileUpdate({ commit }, { memberId }) {
    commit(types.SUBMIT_PROFILE_UPDATE_REQUEST)

    return new Promise((resolve, reject) => {
      memberService
        .postMemberActivity({
          activityType: ACTIVITY_TYPES.PROFILE_UPDATED,
          activityPayload: {},
        })
        .then((res) => {
          commit(types.SUBMIT_PROFILE_UPDATE_SUCCESS, {
            memberId,
          })
          resolve(res)
        })
        .catch((error) => {
          commit(types.SUBMIT_PROFILE_UPDATE_FAILURE)
          reject(error)
        })
    })
  },

  markArticleAsRead({ commit }, { healthActionCode, memberId, contentId }) {
    commit(types.MARK_ARTICLE_AS_READ_REQUEST)

    //mark the article as read.
    return new Promise((resolve, reject) => {
      learnService
        .markArticleAsRead(contentId)
        .then((res) => {
          commit(types.MARK_ARTICLE_AS_READ_SUCCESS, {
            healthActionCode,
            memberId,
            contentId,
          })
          resolve(res)
        })
        .catch((error) => {
          commit(types.MARK_ARTICLE_AS_READ_FAILURE)
          reject(error)
        })
    })
  },
}
