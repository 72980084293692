<template>
  <component
    :is="to && !disabled ? 'router-link' : 'Button'"
    ref="button"
    class="button"
    :class="buttonClass"
    v-bind="$attrs"
    :disabled="disabled || busy"
    :to="to"
    @click="!disabled && !to && !busy && $emit('click')"
  >
    <slot />
    <div
      v-if="busy"
      :class="{
        'mr-10': padding === 'normal',
        'mr-20': padding === 'wide',
      }"
      class="absolute right-0 top-0 h-full flex flex-col justify-center"
    >
      <Icon iconClass="fas fa-spinner fa-spin" class="self-center text-xl" />
    </div>
  </component>
</template>

<script>
export default {
  name: 'HMButton',
  props: {
    color: {
      validator: (value) =>
        [
          'green',
          'grey',
          'white',
          'light-grey',
          'black',
          'blue',
          'red',
          'transparent',
        ].includes(value),
      default: 'blue',
    },
    size: {
      validator: (value) =>
        ['large', 'small', 'x-small', 'inline'].includes(value),
      default: 'small',
    },
    textSize: {
      validator: (value) =>
        ['xs', 'small', 'base', 'large', 'xl'].includes(value),
      default: 'base',
    },
    padding: {
      validator: (value) =>
        ['circle', 'x-0', 'tight', 'normal', 'wide'].includes(value),
      default: 'normal',
    },
    fontWeight: {
      validator: (value) =>
        ['light', 'normal', 'semibold', 'bold', 'black'].includes(value),
      default: 'bold',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: 'full',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    buttonClass: function() {
      const roundingClass = {
        'rounded-none': this.rounded === 'none',
        'rounded-sm': this.rounded === 'sm',
        rounded: this.rounded === 'rounded',
        'rounded-md': this.rounded === 'md',
        'rounded-lg': this.rounded === 'lg',
        'rounded-full': this.rounded === 'full',
      }

      const sizeClass = {
        'button--large': this.size === 'large',
        'button--small': this.size === 'small',
        'button--x-small': this.size === 'x-small',
        'button--inline': this.size === 'inline',
        'button--link': this.to !== null,
      }

      const textSizeClass = {
        'text-xs': this.textSize === 'xs',
        'text-sm': this.textSize === 'small',
        'text-base': this.textSize === 'base',
        'text-lg': this.textSize === 'large',
        'text-xl': this.textSize === 'xl',
      }

      const paddingClass = {
        'p-8': this.padding === 'circle',
        'px-0': this.padding === 'x-0',
        'px-20': this.padding === 'tight',
        'px-40': this.padding === 'normal',
        'px-60': this.padding === 'wide',
      }

      const fontWeightClass = {
        'font-light': this.fontWeight === 'light',
        'font-normal': this.fontWeight === 'normal',
        'font-semibold': this.fontWeight === 'semibold',
        'font-bold': this.fontWeight === 'bold',
        'font-black': this.fontWeight === 'black',
      }

      const colorClass = {
        'button--green': this.color === 'green' && !this.outlined,
        'button--blue': this.color === 'blue' && !this.outlined,
        'button--red': this.color === 'red' && !this.outlined,
        'button--grey': this.color === 'grey' && !this.outlined,
        'button--white': this.color === 'white' && !this.outlined,
        'button--black': this.color === 'black' && !this.outlined,
        'button--light-grey': this.color === 'light-grey' && !this.outlined,
        'button--white-outlined': this.color === 'white' && this.outlined,
        'button--transparent': this.color === 'transparent' && !this.outlined,
      }
      return this.disabled
        ? {
            ...sizeClass,
            ...roundingClass,
            ...textSizeClass,
            ...paddingClass,
            ...fontWeightClass,
            'button--disabled': this.disabled,
          }
        : {
            ...sizeClass,
            ...roundingClass,
            ...textSizeClass,
            ...paddingClass,
            ...fontWeightClass,
            ...colorClass,
          }
    },
  },
}
</script>

<style>
.icon-container {
  top: calc(50% - 20px);
}
.button {
  @apply leading-none text-center transition transition-all duration-300 relative;
  &:focus {
    @apply outline-none shadow-outline;
  }
  &:disabled {
    opacity: 0.35;
    &:hover {
      cursor: not-allowed;
    }
  }
  &--blue {
    @apply bg-blue-500 text-white;
    &:hover {
      @apply bg-blue-600;
    }
  }
  &--red {
    @apply bg-red-600 text-white;
    &:hover {
      @apply bg-red-900;
    }
  }
  &--green {
    @apply bg-green-500 text-white;
    &:hover {
      @apply bg-green-600;
    }
  }
  &--grey {
    @apply text-white bg-grey-500;
    &:hover {
      @apply bg-black;
    }
  }
  &--light-grey {
    @apply text-grey-500 bg-grey-200;
  }
  &--black {
    @apply bg-black text-white;
    &:hover {
      @apply bg-grey-500;
    }
  }
  &--white {
    @apply text-black bg-white;
    &:hover {
      @apply bg-grey-100;
    }
  }
  &--white-outlined {
    @apply text-black bg-white border border-grey-300;
    &:hover {
      @apply bg-grey-100;
    }
  }
  &--transparent {
    @apply text-black bg-transparent;
    &:hover {
      @apply bg-grey-100;
    }
  }
  &--large {
    @apply h-60;
  }
  &--small {
    @apply h-50;
  }
  &--x-small {
    @apply h-45;
  }
  &--inline {
    @apply h-20;
  }
  &--link {
    @apply flex justify-center items-center;
  }
  &--disabled {
    @apply bg-grey-200 text-grey-300 cursor-default;
  }
}
</style>
