import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import _map from 'lodash/map'

export default {
  isError: (state) => state.meta.error,
  isLoadingMyHealthRisk: (state) => state.meta.isLoading.myHealthRisk,
  isLoadingPrescriptionRecord: (state) =>
    state.meta.isLoading.getPrescriptionsRecord,
  overallRisk: (state) => state.overallRisk,
  healthSummary: (state) => state.healthSummary,
  prescriptions: (state) =>
    _map(state.prescriptions, (med) => {
      const filledOn = parseISO(med.startDate)
      return {
        ...med,
        filledOn: format(filledOn, 'MM/dd/yyyy'),
      }
    }),
}
