import api from './api'

export const initHealthActions = () => {
  const initHealthActionsUrl = `/v2/${process.env.VUE_APP_DEVICE}/health-actions/init`
  return api()
    .url(initHealthActionsUrl)
    .get()
}

export const submitMemberAttestation = (fields, healthAction) => {
  const submitMemberAttestationUrl = `/v2/${process.env.VUE_APP_DEVICE}/health-actions/${healthAction}/member-attestation`
  return api()
    .url(submitMemberAttestationUrl)
    .post(fields)
}
